$icon-size: 36px;
$excess-icon-height: 4px;
$missing-spinner-height: 20px;
$horizontal-padding: 5px;
$spinner-width: 14px;

$icon-vertical-margin: -$excess-icon-height / 2;
$spinner-vertical-margin: $missing-spinner-height / 2;

$input-btn-icon-size: 25px;

.nx-password-input {
  position: relative;
  padding: 0;
  width: auto;

  &.nx-password-input-has-loading .nx-password-input-control {
    padding-right: $icon-size;
  }

  &.nx-password-input-has-toggle .nx-password-input-control {
    padding-right: $icon-size;
  }

  &.nx-password-input-has-loading.nx-password-input-has-toggle .nx-password-input-control {
    padding-right: $icon-size + $spinner-width + $horizontal-padding;
  }

  .nx-password-input-spinner {
    position: absolute;
    top: 0;
    right: ($icon-size - $spinner-width) / 2;
    width: $spinner-width;
    margin: $spinner-vertical-margin 5px $spinner-vertical-margin 0;
  }

  &.nx-password-input-has-toggle .nx-password-input-spinner {
    right: $icon-size;
  }

  &.nx-password-input-has-error {
    .nx-password-input-control {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-color: $color-monza-red; 
    }
    .nx-error-message {
      width: 100%;
      display: inline-block;
      padding: 5px 13px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      color: $color-white;
      background-color: $color-monza-red;; 
    }
  }

  .nx-btn-toggle {
    position: absolute;
    top: 0;
    right: 0;
    margin: $icon-vertical-margin 0;
    background: transparent;
    cursor: pointer;
  }
}
