.nx-dropdown-menu {
  background-color: $color-white;
  @include nx-box-shadow();
  width: 100%;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 11000;
  border: 1px solid $color-keyline;
  border-radius: $border-radius-base;
  padding: 3px 0;

  &.nx-bottom {
    // Display below .dropdown-input
    margin-top: 10px;

    &::before {
      content: ' ';
      position: absolute;
      top: -7px;
      right: 5px;
      width: 0;
      height: 0;
      border-width: 0 8px 7px 8px;
      border-style: solid;
      border-color: transparent transparent rgba(32, 32, 32, 0.25) transparent;
    }

    &::after {
      content: ' ';
      position: absolute;
      top: -6px;
      right: 5px;
      width: 0;
      height: 0;
      border-width: 0 8px 7px 8px;
      border-style: solid;
      border-color: transparent transparent #FFF transparent;
    }

    &.nx-has-select-all::after {
      border-color: transparent transparent $color-silverstone transparent;
    }
  }

  &.nx-top {
    // Display above .dropdown-input
    margin-bottom: 10px;

    &::before {
      content: ' ';
      position: absolute;
      bottom: -7px;
      right: 5px;
      width: 0;
      height: 0;
      border-width: 7px 8px 0 8px;
      border-style: solid;
      border-color: rgba(32, 32, 32, 0.25) transparent transparent transparent;
    }

    &::after {
      content: ' ';
      position: absolute;
      bottom: -6px;
      right: 5px;
      width: 0;
      height: 0;
      border-width: 7px  8px 0 8px;
      border-style: solid;
      border-color: #FFF transparent transparent transparent;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    display: block;
    line-height: 100%;
    color: $color-gray-medium;
    width: 100%;

    &.nx-with-divider {
      border-top: 1px solid $color-silverstone;
    }

    &.nx-selected {
      background-color: $color-peach-sorbet;
    }

    &.nx-error-message {
      padding: 10px 12px;
    }

    &.nx-dropdown-item {
      > a {
        cursor: pointer;
        width: 100%;
        padding: 10px 12px;
        display: block;
        color: $color-taupe-gray;

        &:hover,
        &:focus,
        &.focus {
          background-color: $color-peach-sorbet;
          color: $color-taupe-gray;
          text-decoration: none;
        }
      }

      &.nx-has-focus {
        > a {
          background-color: $color-peach-sorbet;
          color: $color-taupe-gray;
          text-decoration: none;
        }
      }
    }

    &.nx-dropdown-reset {
      border-bottom: 1px solid $color-silverstone;
    }

    &.nx-dropdown-action {
      > a {
        position: relative;
        padding: 10px 28px 10px 12px;
        display: block;
        width: 100%;
        border-bottom: 1px solid $color-silverstone;
        color: $color-nintex-orange;
        cursor: pointer;

        &:hover {
          background-color: $color-peach-sorbet;
        }
      }

      .nx-icon-action {
        position: absolute;
        right: -2px;
        top: 2px;
        @include svg-utility-orange-add-alt;
        @include foundation-icon-block(30px);
      }
    }

    &.nx-empty-message {
      width: 100%;
      padding: 10px 12px;
      display: block;
      color: $color-taupe-gray;
    }
  }
}

.nx-dropdown-menu-inner {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
