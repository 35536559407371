@import "../theme/theme";

.nx-sortable-cards {
  border-radius: $border-radius-base;
  background-color: transparent;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
}

.nx-sortable-cards-list {
  padding: 0;
  background-color: transparent;
  transition: background 0.2s ease-in-out;

  & .nx-sortable-card:last-of-type {
    margin-bottom: 0;
  }
}

.nx-sortable-card {
  margin-bottom: 0px;

  &:last-child {
    margin-bottom: 0;
  }
}

.nx-sortable-card-content {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: $border-radius-base;
  background: transparent;
  transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color-brand-orange, 0.5);
  }

  &:hover {
    background-color: rgba($color-white, 0.6);
  }
}

.nx-sortable-card-dragging {
  .nx-sortable-card-content {
    transform: scale(1.05) rotate(1deg);
    background-color: $color-dark-honeycomb;
    border: 1px solid $color-brand-orange;
    box-shadow: 0 0 0 $btn-focus-width rgba($color-brand-orange, 0.5);
  }
}

.nx-sortable-cards-list-dragging {
  background-color: $color-gray-lighter;
  border: 1px solid $color-gray-light;
  box-shadow: 0 0 0 $btn-focus-width $color-gray-light;
  border-radius: $border-radius-base;
}
