@import "../theme/theme";

.nx-card-layout {
  display: flex;
  flex-direction: column;
  position: relative;

  .nx-card-layout--header {
    padding: 10px;
  }

  .nx-close-x-button {
    position: absolute;
    top: 10px;
    right: 10px;

    .nx-btn-icon-wrapper {
      margin: 0;
    }
  }

  .nx-card-layout--body {
    flex-shrink: 1;
    padding: 10px;
    overflow-y: auto;
  }

  .nx-card-layout--footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding: 10px;

    > * {
      margin-left: 10px;
    }
  }
}

.nx-dark {
  .nx-card-layout--header {
    background-color: $color-gray-darker;
  }

  .nx-card-layout--body {
    background-color: $color-charcoal;
  }

  .nx-card-layout--footer {
    background-color: $color-gray-darker;
  }
}