@import "../theme/theme";

$padding-y: 5px;
$padding-x: 10px;

.nx-field-state {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-radius: $border-radius-base;
  line-height: 1;
  transition: 200ms ease-in-out;
  transition-property: all;

  &-is-type-default {
    border-color: $color-gray-light;
    
    &.nx-field-state-is-focused {
      border-color: $color-brand-primary;
    }
    
    &.nx-field-state-is-highlighted .nx-field-state-content {
      box-shadow: 0 0 0 1px $color-gray-light inset;
    }
    
    .nx-field-state-message {
      background-color: $color-gray-light;
      color: $color-gray-dark;
    }
  }

  &-is-type-info {
    border-color: $color-brand-blue;
    
    &.nx-field-state-is-highlighted .nx-field-state-content {
      box-shadow: 0 0 0 1px $color-brand-blue inset;
    }
    
    .nx-field-state-message {
      background-color: $color-brand-blue;
      color: $color-white;
    }
  }

  &-is-type-success {
    border-color: $color-brand-green;
    
    &.nx-field-state-is-highlighted .nx-field-state-content {
      box-shadow: 0 0 0 1px $color-brand-green inset;
    }
    
    .nx-field-state-message { 
      background-color: $color-brand-green;
      color: $color-white;
    }
  }

  &-is-type-warning {
    border-color: $color-brand-yellow;
    
    &.nx-field-state-is-highlighted .nx-field-state-content {
      box-shadow: 0 0 0 1px $color-brand-yellow inset;
    }
    
    .nx-field-state-message { 
      background-color: $color-brand-yellow;
      color: $color-gray-dark;
    }
  }

  &-is-type-error {
    border-color: $color-brand-red;
    
    &.nx-field-state-is-highlighted .nx-field-state-content {
      box-shadow: 0 0 0 1px $color-brand-red inset;
    }
    
    .nx-field-state-message { 
      background-color: $color-brand-red;
      color: $color-white;
    }
  }

  .nx-field-state-content {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: ($border-radius-base - 1);
    background-color: $color-white;
    transition: 200ms ease-in-out;
    transition-property: all;
  }
  
  .nx-field-state-message {
    position: relative;
    width: 100%;
    padding: $padding-y $padding-x;
  }

  &-has-message {
    .nx-field-state-content {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}