@import "../theme/theme";

$breadcrumb-menu-svg-height: 24px !default;
$breadcrumb-menu-svg-width: 30px !default;

.nx-breadcrumbs {
  display: flex;
  color: $breadcrumbs-text-color;
  align-items: center;

  ul {
    min-width: 100px;
  }

  &.nx-breadcrumbs-inverse {
    &, .nx-breadcrumb-text {
      color: $breadcrumbs-text-color-inverse;
    }
    
    button > .nx-breadcrumb-text {
      color: $breadcrumbs-link-color-inverse;
    }
  }

  .nx-breadcrumbs-title {
    margin-right: 8px;
  }

  .nx-action-menu-container > .nx-icon-wrapper {
    width: $breadcrumb-menu-svg-width;
    height: $breadcrumb-menu-svg-height;
    color: $color-taupe-gray;
    border: 1px solid $color-gray-light;
    border-radius: $border-radius-small;
    position: relative;
    cursor: pointer;
    background: $color-white;
    svg {
      color: $color-black;
      height: $breadcrumb-menu-svg-height;
      width: $breadcrumb-menu-svg-height;
      transform: scale(1.55);
    }
  }

  .btn-link {
    .nx-tooltip-container:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }

  &.nx-breadcrumbs-theme-light {
    svg {
      color: $breadcrumb-icon-light-color;
    }
    .nx-action-menu-container > .nx-icon-wrapper {
      svg {
        color: $breadcrumb-icon-light-color;
      }
    }
  }


  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 100;

    li {
      display: flex;
      align-items: center;
      .nx-icon {
        border: 0px solid $color-brand-red;
        font-weight: normal;
      }
      .nx-left-icon {
        margin-left: -5px;
      }
      .nx-start-icon {
        margin-right: -5px;
        margin-bottom: -1px;
      }
      a {
        &:hover {
          text-decoration: $breadcrumb-link-hover-decoration;
        }
      }
    }
  }
}
