@import "./checkbox";
@import "./radio";
@import "./checkbox-toggle";
@import "./radio-toggle";
@import "./character-counter";

input,
select,
textarea {
    &.form-control {
        font-family: $font-family-base;
        color: $color-text;

        // TWBS sets this with value "padding-box", but it produces an ugly gray inner border on IE11 and Edge
        background-clip: border-box;

        &:read-only{
            background: $color-gray-lighter;
            &:focus {
                border-color: $color-gray-light;
            }
        }
    }
}

// Remove the "clear" button from text fields that appear in IE11 and Edge
input,
select {
    &::-ms-clear {
    	display: none;
    }
}

// We need to be careful that font descenders are not completely cut-off by the built-in browser dropdown.
select.form-control {
    padding-top: 0.375rem; // 6px
    padding-bottom: 0.375rem; // 6px

    // Height of the browser default drop-down needs to be specified for browsers on OSX
    &:not([multiple]) {
        &:not([size]),
        &[size="0"] {
            height: calc(2rem + 1px); // 34px
        }
    }

}

textarea.form-control {
    height: auto;
	resize: none;
}

.form-control {
    height: auto;
}
