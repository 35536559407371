.nx-card-deck {
  @extend .card-deck;
}

.nx-card-deck > .row {
  min-width: 100%;
}

.nx-card-deck > .row > div,
.nx-card-deck > .row > .nx-card-wrapper {
  margin: 0 0 2rem;
}

.nx-card {
  height: 100%;
  @extend .card;
}

.nx-card header {
  position: relative;
  padding: 0;
  height: 125px;
  background-repeat: no-repeat;
  background-position: center;

  @extend .card-header;

  &.nx-card-header-without-border {
      margin: -1px;
  }
}

.nx-card .title {
  position: absolute;
  bottom: -1px;
  width: 100%;
  margin: 0;
  color: $color-white;
  padding: 10px 20px;
  background-color: rgba($color-gray-darker, 0.7);
}

.nx-card-block-flush {
  padding: 0;

  .nx-table, .table {
      margin-bottom: 0;
  }
}
