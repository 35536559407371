@import "../theme/theme";
.nx-user-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-empty {
    margin: 0.75rem;
  }
  .nx-spinner-container {
    display: flex;
    height: 70%;
    margin-bottom: 0.75rem;
  }
  .nx-user-label {
    padding: $input-padding-y $input-padding-x;
  }
  .nx-user-list-header{
    margin-top: 0.75rem;
    margin-left: 0.75rem;
  }
  .nx-user-email {
    display: inline;
    font-size: 12px;
    color: $color-gray-medium;
    &::before {
      content: " (";
    }
    &::after {
      content: ")";
    }
  }
}
