@import "../theme/theme";
@import "../mixins/select";


// Our SVG icons are 36px^2
// We use negative margin to bring them down to a more managable size
$icon-margin: -8px;

// The default input padding of 7px feels a bit narrow
$input-padding-y: 8px;
$pill-padding-y: 5px;
$icon-position: -7px;
$control-min-height: 39px;
$add-button-height: 24px;

.nx-select {
    position: relative;
    box-sizing: border-box;
    transition: none !important;

    &.nx-select--is-disabled {
      pointer-events: auto; // Prevent default behaviour of react-select which prevents deletion of Creatable selected items

      @include select-input-and-values-disabled-colors($select-disabled-text-color, $select-disabled-bg-color);
    }

    .nx-select__control {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      min-height: $input-height;
      padding: 1px;
      outline: 0 !important;
      position: relative;
      background-color: $color-white;
      border-color: $select-border-color;
      border-radius: $border-radius-base;
      border-style: solid;
      border-width: 1px;
      box-sizing: border-box;
      box-shadow: none;
      cursor: default;
      transition: border-color 200ms ease-in-out;
    
      &--is-focused {
        border-color: $select-focus-border-color;

        &.nx-select__control:hover {
          border-color: $select-focus-border-color;
        }
      }
    }

  &.nx-select-has-message {
    .nx-select__control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.nx-select-has-highlight {
    .nx-select__control {
      padding: 0;
      border-width: 2px;
    }

    .nx-select__menu {
      border-width: 2px;
    }

    .nx-select__option {
      padding: 8px 11px;
    }

    &.nx-select--is-info {
      .nx-select__control {
        border-color: $color-mariner-blue;

        &--is-focused {
          border-color: $color-mariner-blue;

          &.nx-select__control:hover {
            border-color: $color-mariner-blue;
          }
        }
      }

      .nx-select__menu {
        border-color: $color-mariner-blue;
      }
    }

    &.nx-select--is-warning {
      .nx-select__control {
        border-color: $select-warning-border-color;

        &--is-focused {
          border-color: $select-warning-border-color;

          &.nx-select__control:hover {
            border-color: $select-warning-border-color;
          }
        }
      }

      .nx-select__menu {
        border-color: $select-warning-border-color;
      }
    }

    &.nx-select-has-error {
      .nx-select__control {
        border-color: $select-error-border-color;
  
        &--is-focused {
          border-color: $select-error-border-color;
  
          &.nx-select__control:hover {
            border-color: $select-error-border-color;
          }
        }
      }

      .nx-select__menu {
        border-color: $select-error-border-color;
      }
    }
  }

  &.nx-select-has-error {
    .nx-select__control {
      border-color: $select-error-border-color;

      &--is-focused {
        border-color: $select-error-border-color;

        &.nx-select__control:hover {
          border-color: $select-error-border-color;
        }
      }
    }

    .nx-select__menu {
      border-color: $select-error-border-color;
    }
  }

  &.nx-select-has-warning {
    .nx-select__control {
      border-color: $select-warning-border-color;

      &--is-focused {
        border-color: $select-warning-border-color;

        &.nx-select__control:hover {
          border-color: $select-warning-border-color;
        }
      }
    }

    .nx-select__menu {
      border-color: $select-warning-border-color;
    }
  }

  &.nx-select-has-error {
    .nx-select__control {
      border-color: $select-error-border-color;
    }
  }

  &.nx-select-has-warning {
    .nx-select__control {
      border-color: $select-warning-border-color;
    }

    .nx-select__multi-value--invalid {
      .nx-select__multi-value {
        border-color: $select-warning-border-color;
      }
    }
  }

  .nx-select__value-container {
    -webkit-overflow-scrolling: touch;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    overflow: hidden;
    padding: ($input-padding-y / 2) $input-padding-x;
    position: relative;
    line-height: 1.25;
  }

  .nx-select__value-container--is-multi.nx-select__value-container--has-value {
    .nx-pill:not(:first-child) {
      margin: 0 3px;
    }
  }

  .nx-select__single-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: $select-input-text-color;
    max-width: calc(100% -8px);
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .nx-select__single-value__label {
    flex: 1 1 auto;
  }

  .nx-select__single-value__prefix {
    flex: 0 0 auto;
    margin: $icon-margin;
    padding-right: $input-padding-x;
  }

  .nx-select__multi-value {
    border-radius: $border-radius-base / 2;
    background: $color-white;
    color: $select-multi-value-text-color;
    border: 1px solid $select-multi-value-border-color;
    display: flex;
    min-width: 0;
    margin: 0 2px 0 0;

    &:hover {
      background-color: $select-multi-value-hover-bg-color;
    }
  }

  .nx-select__multi-value--invalid .nx-select__multi-value {
    border: 1px solid $color-brand-red;
    background-color: $color-peach-sorbet;
  }

  .nx-select__multi-value--is-active .nx-select__multi-value {
    border-color: $color-mariner-blue;
    box-shadow: 0 0 0 1px $color-mariner-blue;
  }

  .nx-select__multi-value--for-pill {
    .nx-select__multi-value {
      border: none;
      margin: 0;
    }

    .nx-select__multi-value__label {
      padding: 0;
    }

    .nx-pill:hover {
      .nx-pill-content,
      .nx-pill-close {
        background-color: $color-peach-sorbet;
      }

      .nx-pill-close:hover {
        background-color: $color-dark-honeycomb;
      }
    }

    .nx-disabled {
      .nx-pill:hover {
        .nx-pill-content,
        .nx-pill-close {
          background-color: $color-white;
        }

        .nx-pill-close:hover {
          background-color: $color-gray-lighter;
        }
      }
    }
  }

  .nx-select__multi-value__label {
    border-radius: $border-radius-base / 2;
    overflow: hidden;
    padding-left: 6px;
    text-overflow: ellipsis;
    white-space: normal;
    user-select: none;
    font-size: 14px;
    line-height: 1.2;
    word-break: break-word;
    color: inherit;

    &__child {
      color: $color-charcoal;
      padding: 3px 0px 3px 6px;
    }
  }

  .nx-select__multi-value__remove {
    align-items: center;
    border-radius: $border-radius-base / 2;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
    display: flex;

    &:hover {
      cursor: pointer;
      color: inherit;
      background-color: $select-multi-value-remove-hover-bg-color;
    }

    .nx-icon__close {
      color: $color-charcoal;
      display: inline-block;
      width: 23px;
      height: 23px;
    }
  }

  .nx-select__input {
    box-sizing: border-box;
    color: $select-input-text-color;
  }

  .nx-select__value-container--has-value.nx-select__value-container--is-multi .nx-select__input {
    margin-left: 4px;
  }

  .nx-select__placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    color: $input-placeholder-color;
  }

  &.nx-select--is-disabled {
    .nx-select__placeholder {
      // Without these, in the disabled state the placeholder is cut off
      // at the top and bottom quarter horizontally, leaving it only partially visible
      position: static;
      transform: translate(0);
    }
  }

  .nx-select-bottom-menu {
    .nx-select__menu {
      border-bottom-left-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;
      border-top: 0;
      margin-top: -4px;
      .nx-select__menu-list {
        border-top: 1px solid $select-menu-top-bottom-border-color;
      }
    }
  }

  .nx-select-top-menu {
    .nx-select__menu {
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
      border-bottom: 0;
      margin-bottom: -4px;
      .nx-select__menu-list {
        border-bottom: 1px solid $select-menu-top-bottom-border-color;
      }
    }

  }

  .nx-select__menu {
    background-color: $color-white;
    border-radius: 0;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    z-index: 4;
    border: 1px solid $select-menu-border-color;
    color: $select-menu-text-color;
    box-shadow: none;
    transition: border-color 200ms ease-in-out;

    // Moving the menu up 4px to cover the border-radius of the input
    .nx-option__side-container{
      display: flex;
    }
  }

  .nx-select__menu-list {
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    max-height: 300;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
    padding: 0;

    // Offset the top menu change
    margin-top: 2px;
  }
  
  .nx-select__group--nested-nogroup {
    padding: 0px !important;
    .nx-select__group-heading {
      display:none;
    }
  }

  .nx-select__group-heading {
    padding: $input-padding-y $input-padding-x;
    font-size: 12px;
    color: $select-group-heading-text-color;
  }

  .nx-select__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    background-color: $color-white;
    user-select: none;
    cursor: default;
    overflow: hidden;

    &--is-focused {
      background-color: $select-option-hover-bg-color;
    }

    &--is-selected {
      background-color: $select-option-selected-bg-color;
      color: $select-option-selected-text-color;
    }

    &:active {
      background-color: $select-option-selected-bg-color;
      color: $select-option-selected-text-color;
    }

    &.nx-select__option--is-selected.nx-select__option--is-focused {
      background-color: $select-option-selected-bg-color;
      color: $select-option-selected-text-color;
    }

    &__footer {
      font-size: 12px;
      font-style: italic;
      background-color: $color-gray-lighter;
    }

    &__child {
      padding-left: $input-padding-x * 2;
      padding-right: $input-padding-x * 2;
    }
  }

  .nx-select__option__label {
    flex: 1 1 auto;
    word-break: break-word;
  }

  .nx-select__option__prefix {
    flex: 0 0 auto;
    margin: $icon-margin;
    padding-right: $input-padding-x;
  }

  .nx-select__option__suffix {
    flex: 0 0 auto;
    margin: $icon-margin;
    padding-left: $input-padding-x;
    word-break: break-word;
  }

  .nx-select__option__suffix__icon-wrapper {
    width: 40px; // To align icons of different sizes in one line. Big enough for most regular icons. Make it too big and there will be much white space around the icon.
    text-align: center;
  }

  .nx-select__option--is-action {
    color: $select-option-action-text-color;
    border-bottom: 1px solid $select-option-action-bottom-border-color;

    &--is-selected, &:active {
      background-color: $select-option-selected-bg-color;
      color: $select-option-action-active-selected-text-color;
    }
  }

  .nx-select__group .nx-select__option {
    padding: $input-padding-y ($input-padding-x * 2);
  }

  .nx-select__group .nx-select__option--nested-nogroup {
    padding-left: $input-padding-x;
    padding-right: $input-padding-x;
  }

  .nx-select__option__label__subtext {
    font-size: 12px;
    color: $select-option-subtext-text-color;
  }

  .nx-select__option--is-selected.nx-select__option--is-focused {
    background-color: $select-option-selected-bg-color;
    color: $select-option-selected-text-color;
  }

  .nx-select__option--is-selected .nx-select__option__label__subtext {
    background-color: $select-option-selected-bg-color;
    color: $select-option-selected-subtext-text-color;
  }

  .nx-select__option--is-selected.nx-select__option--is-focused .nx-select__option__label__subtext {
    background-color: $select-option-selected-bg-color;
    color: $select-option-selected-subtext-text-color;
  }

  .nx-select__menu-notice--no-options,
  .nx-select__menu-notice--loading {
    box-sizing: border-box;
    background: $color-white;
    text-align: center;
    padding: $input-padding-y $input-padding-x;
    color: $select-no-option-text-color;
  }

  .nx-select__indicators {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    align-self: stretch;
    color: $select-indicators-color;

    .nx-select__indicator-separator {
      width: 0;
      display: none;
    }
  }

  .nx-select__indicator + .nx-select__indicator-separator + .nx-select__indicator {
    padding-left: 0;
  }

  .nx-select__clear-indicator {
    display: flex;
    padding: 4px;
    color: $select-indicators-color;
  }

  .nx-select__control--menu-is-open {
    .nx-select__dropdown-indicator .nx-icon__dropdown-arrow {
      transform-origin: (50%, 50%);
      transform: rotate(180deg);
    }
  }

  .nx-select__dropdown-indicator {
    position: relative;
    top: -1px;
    box-sizing: border-box;
    display: flex;
    padding: 4px;
    color: $select-indicators-color;
  }

  .nx-select__with-left-icon {
    position: relative;
    padding: 0;
    width: auto;

    .nx-select__left-icon {
      position: absolute;
      margin: -2px 0;
      z-index: 1;
    }
    .nx-select__control {
      padding-left: 20;
    }
  }

  .nx-select__tab-action {
    position: relative;
    padding: 0;
    width: auto;
  }

  .nx-select__with-tab-action {
    &:focus {
      outline: none;
    }
    & + .nx-select__menu {
      border-color: $color-brand-blue;
    }

    .nx-select__value-container {
      padding: ($input-padding-y / 2) $input-padding-x;
    }

    .nx-select__tab-action-button {
      height: $add-button-height;
      position: absolute;
      top: -$add-button-height;
      right: 0;
      display: flex;
      align-items: center;
      overflow: hidden;
      cursor: pointer;
      svg {
        height: $add-button-height;
      }

      .nx-add-variable-button-text {
          background-color: $color-brand-blue;
          border-radius: 0;
          border-top-right-radius: 4px;
          color: $color-white;
          line-height: $add-button-height;
          padding: 0 $input-padding-x 0 0;
      }
    }

    .nx-select__control,
    .nx-select__control:focus {
      outline: none;
      min-height: $control-min-height;
      .nx-select__value-container:focus {
        outline: none;
      }
    }
    .nx-select__control--is-focused {
      border-color: $color-brand-blue;
    }

    .nx-select__value-container--is-multi.nx-select__value-container--has-value {
      .nx-pill:not(:first-child) {
        margin: 3px 5px;
      }
    }

    & + .nx-select__menu {
      .nx-select__option {
        padding: $pill-padding-y $input-padding-x;
        &:first-child{
          padding-top: ($pill-padding-y * 2);
        }
        &:last-child {
          padding-bottom: ($pill-padding-y * 2);
        }
      }
    }
  }

  .nx-select__custom-indicators-container {
    display: flex;
  }

  .nx-select__custom-indicators-indicator {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }

  .nx-select-create-new-option {
    color: $color-brand-orange;

    &:active {
      color: white;
    }

    .nx-btn-icon-wrapper {
      top: -1px;
    }
  }
}

.nx-select-message {
  width: 100%;
  display: inline-block;
  padding: 5px 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: $color-white;
  background-color: $select-error-bg-color;

  &--is-warning {
    color: $select-warning-text-color;
    background-color: $select-warning-bg-color;
  }
}
