@import "../mixins/token-pill";

$border-radius: 2px;
$v-padding: 3px;

// left side icon width
$x-width: 27px;

$curved-arrow: polygon(0 0, 1px 0, 2px 3%, 100% 48%, 100% 52%, 2px 97%, 1px 100%, 0 100%);

.nx-token-pill {
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  color: $color-charcoal;
  border-right: none;
  user-select: none;
  box-sizing: border-box;

  .nx-token-pill-start {
    box-sizing: border-box;
    padding-right: 5px;
    display: flex;
    background-color: $color-white;
    border-color: inherit;
    border-style: solid;
    border-width: 1px 0 1px 1px;
    border-radius: $border-radius 0 0 $border-radius;
  }
  
  .nx-token-pill-icon-wrapper {
    width: $x-width;
    display: flex;
    position: relative;
  }

  .nx-token-pill-icon-36x36 {
    @include nx-token-pill-icon(36px);
  }

  .nx-token-pill-content {
    flex: 1;
    padding-top: $v-padding;
    padding-bottom: $v-padding;
    word-break: break-word;
    hyphens: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: $color-white;
    border-color: inherit;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-top-right-radius: 5px 1px;
    border-bottom-right-radius: 5px 1px;
    padding-right: 2px;
  }

  &:not(.nx-token-pill-parent) {
    .nx-token-pill-content {
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: inherit;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}

.nx-token-pill-child-arrow {
  margin-left: -1px;
  margin-top: 1px;
  margin-bottom: 1px;
  position: relative;
  
  &::before {
    content: '';
    clip-path: $curved-arrow;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  &::after {
    content: '';
    clip-path: $curved-arrow;
    background-color: $color-white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1px;
    left: -1px;
  }
}

.nx-token-pill-parent-arrow {
  position: relative;
  &::before {
    content: '';
    clip-path: $curved-arrow;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin-left: -0.1px; /* white line appears in chrome at the beginning of the arrow */
  }
}

.nx-token-pill-multiline {  
  .nx-token-pill-content {
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.nx-token-pill-inline {
  display: inline;
  
  .nx-token-pill-start {
    display: inline;
    vertical-align: baseline;
    white-space: nowrap;
    padding-top: $v-padding;
    padding-bottom: $v-padding;

    .nx-token-pill-icon-wrapper {
      padding-top: $v-padding;
      padding-bottom: $v-padding;
      padding-left: $x-width;
      display: inline;
    }
    .nx-token-pill-parent-arrow {
      display: inline;
      vertical-align: baseline;
      padding-top: $v-padding;
      padding-bottom: $v-padding;
    }
  }
  .nx-token-pill-child-arrow {
    display: inline;
    vertical-align: baseline;
    padding-top: $v-padding;
    padding-bottom: $v-padding;
  }
  .nx-token-pill-content {
    vertical-align: baseline;
    display: inline;
    white-space: normal;
    padding-top: $v-padding;
    padding-bottom: $v-padding;
  }
}

.nx-token-pill-disabled {
  filter: grayscale(1) opacity(0.4);
}

.nx-token-pill-light {
  border-color: $color-brand-blue;
  .nx-token-pill-icon-wrapper {
    fill: $color-brand-blue;
  }
  .nx-token-pill-parent-arrow {
    &::before {
      background-color: $color-brand-blue;
    }
    &::after {
      content: '';
      clip-path: $curved-arrow;
      background-color: $color-white;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 1px;
      left: -1px;
    }
  }
  .nx-token-pill-child-arrow {
    &::before {
      background-color: $color-brand-blue;
    }
  }
}

.nx-token-pill-dark {
  @include nx-token-pill-style($color-brand-blue);
}
.nx-token-pill-fx {
  @include nx-token-pill-style($color-brand-green);
}
