.nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper {
  border-color: #CBCBCB;
  background-color: #FFF;
  color: #E06E18; }
  .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper .hover, .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper:hover {
    background-color: #FFF2E6;
    color: #c96316;
    cursor: pointer;
    text-decoration: none;
    border-color: #CBCBCB; }
  .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper[disabled], .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .disabled.nx-icon-wrapper {
    cursor: not-allowed;
    background-color: #CBCBCB;
    color: #F1F1F1;
    border-color: #CBCBCB; }
    .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper[disabled] .hover, .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper[disabled]:hover, .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .disabled.nx-icon-wrapper .hover, .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .disabled.nx-icon-wrapper:hover {
      background-color: #CBCBCB;
      color: #F1F1F1;
      cursor: pointer;
      text-decoration: none; }
    .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper[disabled]:hover, .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .disabled.nx-icon-wrapper:hover {
      cursor: not-allowed; }
  .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper:focus, .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .focus.nx-icon-wrapper {
    border-color: #eb8a40;
    box-shadow: 0 0 0 0.2rem rgba(224, 110, 24, 0.5); }
  .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper:not(:disabled):not(.disabled):active, .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper:not(:disabled):not(.disabled).active {
    border-color: #eb8a40;
    background-color: #eb8a40;
    color: #FFF; }
    .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper:not(:disabled):not(.disabled):active:focus, .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(224, 110, 24, 0.5); }
  .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper:focus, .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .focus.nx-icon-wrapper {
    background-color: #FFF2E6;
    border-color: #eb8a40; }

.nx-global-nav {
  float: right; }

.nx-global-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .nx-global-header .nx-logo-large {
    height: 45px;
    width: 122px;
    flex-shrink: 0;
    padding: 1px 6px; }
    .nx-global-header .nx-logo-large .nx-icon {
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      left: 0; }
  .nx-global-header .nx-logo-default {
    color: #E06E18;
    fill: #03161e; }
  .nx-global-header .nx-breadcrumbs {
    margin-left: 30px; }
    .nx-global-header .nx-breadcrumbs.nx-breadcrumbs-inverse, .nx-global-header .nx-breadcrumbs.nx-breadcrumbs-inverse .nx-breadcrumb-text {
      color: #5B5B5B; }
    .nx-global-header .nx-breadcrumbs.nx-breadcrumbs-inverse button > .nx-breadcrumb-text {
      color: #E06E18; }
  .nx-global-header .nx-global-nav {
    margin-left: auto; }
    .nx-global-header .nx-global-nav ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style-type: none; }
      .nx-global-header .nx-global-nav ul li {
        display: flex;
        padding: 0 15px;
        margin-top: 1px;
        height: 46px;
        align-items: center; }
        .nx-global-header .nx-global-nav ul li.nx-global-nav-menu {
          padding: 0; }
        .nx-global-header .nx-global-nav ul li > a, .nx-global-header .nx-global-nav ul li > .btn-link, .nx-global-header .nx-global-nav ul li .nx-action-menu-container button.btn-text {
          text-decoration: none;
          border-bottom: 0;
          color: #5B5B5B;
          transition: color 0.3s ease;
          font-size: 14px;
          position: relative;
          height: 100%;
          /* position it's content in the center with flex */
          display: flex;
          align-items: center;
          height: 100%; }
          .nx-global-header .nx-global-nav ul li > a:hover, .nx-global-header .nx-global-nav ul li > .btn-link:hover, .nx-global-header .nx-global-nav ul li .nx-action-menu-container button.btn-text:hover {
            background-color: inherit;
            color: #E06E18; }
        .nx-global-header .nx-global-nav ul li.nx-selected, .nx-global-header .nx-global-nav ul li.selected {
          background-color: inherit; }
          .nx-global-header .nx-global-nav ul li.nx-selected > a, .nx-global-header .nx-global-nav ul li.nx-selected > .btn, .nx-global-header .nx-global-nav ul li.nx-selected > .nx-action-menu-container button, .nx-global-header .nx-global-nav ul li.selected > a, .nx-global-header .nx-global-nav ul li.selected > .btn, .nx-global-header .nx-global-nav ul li.selected > .nx-action-menu-container button {
            color: #E06E18; }
            .nx-global-header .nx-global-nav ul li.nx-selected > a::after, .nx-global-header .nx-global-nav ul li.nx-selected > .btn::after, .nx-global-header .nx-global-nav ul li.nx-selected > .nx-action-menu-container button::after, .nx-global-header .nx-global-nav ul li.selected > a::after, .nx-global-header .nx-global-nav ul li.selected > .btn::after, .nx-global-header .nx-global-nav ul li.selected > .nx-action-menu-container button::after {
              content: " ";
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              display: block;
              height: 2px;
              background: #E06E18; }
        .nx-global-header .nx-global-nav ul li .badge.badge-pill {
          position: absolute;
          padding: 3px;
          min-width: 17px;
          top: 2px;
          right: 32px; }
        .nx-global-header .nx-global-nav ul li .nx-btn-icon-wrapper {
          pointer-events: none; }
    .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper {
      width: 36px;
      height: 32px;
      color: #5B5B5B !important;
      border-radius: 2px; }
      .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper:hover {
        color: #E06E18; }
      .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper:active {
        color: #FFF; }
      .nx-global-header .nx-global-nav .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper .nx-icon {
        width: 36px;
        height: 36px;
        position: absolute; }
    .nx-global-header .nx-global-nav .nx-meatball-menu .nx-icon-wrapper .nx-icon {
      width: 100%;
      height: 100%;
      left: 0;
      top: -1px; }

.nx-action-menu-item .badge.badge-pill {
  position: absolute;
  padding: 3px;
  min-width: 17px;
  right: 10px; }

[data-whatintent='mouse'] .nx-action-menu-list li button:focus {
  background: none;
  color: inherit;
  text-decoration: inherit; }
