@import "../mixins/forms";
@import "../mixins/utils";

.nx-radio {
    padding: $input-padding-y 0;

    input[type=radio] {
        @include minimize-checkbox;
        @include element-style-focus-shadow($btn-primary-border, '+ .nx-radio-container .nx-radio-faux');

        + .nx-radio-container {
            .nx-radio-faux {
                display: inline-block;
                position: absolute;
                z-index: 1;
                width: 18px;
                height: 18px;
                top: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid $radio-border-color;
                background-color: $radio-bg-color;
            }
        }

        &:not([disabled]):checked {
            + .nx-radio-container {
                .nx-radio-faux {
                    background-color: $radio-selected-bg-color;
                    border: 6px solid $radio-selected-border-color;
                }
            }
        }

        &[disabled] {
            cursor: $cursor-disabled;

            &:checked {
                + .nx-radio-container {
                    .nx-radio-faux {
                        background-color: $radio-disabled-bg-color;
                        border: 6px solid $radio-disabled-border-color;
                    }
                }
            }

            &:not(:checked) {
               + .nx-radio-container {
                     .nx-radio-faux {
                        background-color: $radio-disabled-bg-color;
                        border: 1px solid $radio-disabled-border-color;
                    }
                }
            }

            + .nx-radio-container {
                cursor: $cursor-disabled;

                .nx-radio-faux {
                    cursor: $cursor-disabled;
                }

                .nx-radio-label {
                    color: $radio-disabled-label-color;
                    cursor: $cursor-disabled;
                }
            }
        }
    }

    > label {
        display: inline-block;
        width: auto;
        // Override a TWBS property for all <label>s
        margin-bottom: 0;
    }

    .nx-radio-container {
        cursor: pointer;
        padding-left: 24px;
        position: relative;
        user-select: none;
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        min-height: 18px;
    }

    .nx-radio-faux {
        cursor: pointer;
        position: absolute;
        display: inline-block;
        border: 1px solid $radio-border-color;
        border-radius: 2px;
        background-color: $radio-border-color;
        z-index: 1;
        top: 1px;
        left: 0;
        width: 16px;
        height: 16px;
    }

    .nx-radio-label {
        font-size: 14px;
        cursor: pointer;
    }

    &.nx-radio-inline {
        display: inline-block;
        padding-right: $input-padding-x;
    }
}

// Turn off accessibility features when use not tabbing
[data-whatintent='mouse'] .nx-radio input[type=radio]:focus + .nx-radio-container .nx-radio-faux {
    box-shadow: none;
}
