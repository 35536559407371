@mixin dark-theme-background {
  background-color: $dark-theme-bg-color;
}

@mixin dark-theme-button-style-default {
  // @include button-style-base;
  border: 1px solid $color-white;
  background-color: transparent;
  color: $color-white;
  opacity: 0.8;
  @include tab-focus(0);
  transition: opacity 0.3s ease, background 0.3s ease;

  &:hover {
    opacity: 1;
    background-color: transparentize($color-white, 0.8);
    text-decoration: none;
  }
}