.nx-has-flag {
  position: relative;
}

.nx-flag {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  z-index: 2;
  .nx-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 45px;
    width: 45px;
  }

  .nx-icon__cu-unconfigured {
    fill: $flag-unconfigured-bg-color;
    color: $flag-unconfigured-icon-color;
  }

  .nx-icon__cu-alert-tick {
    fill: $flag-alert-bg-color;
    color: $flag-alert-icon-color;
  }

  .nx-icon__cu-error {
    fill: $flag-error-bg-color;
    color: $flag-error-icon-color;
  }

  .nx-icon__cu-success {
    fill: $flag-success-bg-color;
    color: $flag-success-icon-color;
  }
}

.nx-fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 4s, opacity 4s linear;
}
