.nx-query-builder-conditions {
  list-style: none;
  margin: 0 0 10px;
  padding: 0; }
  .nx-query-builder-conditions li {
    margin-bottom: 5px; }
  .nx-query-builder-conditions p {
    margin-bottom: 0;
    min-height: 22px; }
  .nx-query-builder-conditions .nx-config-panel-card {
    margin: 5px 0 0; }

.nx-query-builder-condition {
  display: flex;
  justify-content: baseline; }
  .nx-query-builder-condition > .btn {
    padding: 0;
    height: 22px;
    width: 22px;
    margin-left: 5px;
    flex-shrink: 0; }
    .nx-query-builder-condition > .btn .nx-icon {
      position: absolute;
      width: 32px;
      height: 32px; }

.nx-query-builder-condition-summary {
  flex-grow: 1;
  word-break: break-word; }

.nx-query-builder-condition-relationship {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .nx-query-builder-condition-relationship p {
    margin: 0; }
  .nx-query-builder-condition-relationship .nx-select {
    width: 70px;
    margin: 0 10px;
    display: inline-block; }
