@import "../theme/theme";
.nx-modal-dialog.nx-dialog-info.nx-dialog-medium {
    .nx-modal-dialog-body.nx-connection-permissions {
        overflow: visible;

        .nx-btn-info {
         position: relative;
         color: $color-brand-blue;
         margin-left: $padding-small / 2;
        }
    }
}
   