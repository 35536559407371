@import "../theme/theme";
.nx-canvas {
    position: relative;

    @media screen and (min-width: 1280px) {
        margin: $canvas-lg-margin;
        min-width: calc(#{$canvas-lg-min-width} - #{30px * 2});
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        margin: $canvas-md-margin;
        min-width: calc(#{$canvas-md-min-width} - #{30px * 2});
    }

    @media screen and (max-width: 1024px) {
        margin: $canvas-sm-margin;
        width: calc(#{$canvas-sm-width} - #{30px * 2});
    }
}

.nx-canvas-header {
    h1 {
        padding-bottom: $canvas-header-bottom-padding;
        border-bottom: 1px solid $color-keyline;
    }

    margin-bottom: $canvas-header-bottom-margin;
}
