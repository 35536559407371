.nx-token-pill {
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  color: #323232;
  border-right: none;
  user-select: none;
  box-sizing: border-box; }
  .nx-token-pill .nx-token-pill-start {
    box-sizing: border-box;
    padding-right: 5px;
    display: flex;
    background-color: #FFF;
    border-color: inherit;
    border-style: solid;
    border-width: 1px 0 1px 1px;
    border-radius: 2px 0 0 2px; }
  .nx-token-pill .nx-token-pill-icon-wrapper {
    width: 27px;
    display: flex;
    position: relative; }
  .nx-token-pill .nx-token-pill-icon-36x36 {
    display: flex;
    position: absolute;
    width: 36px;
    height: 36px;
    top: 50%;
    left: 50%;
    margin-left: -18px;
    margin-top: -18px;
    align-items: center; }
  .nx-token-pill .nx-token-pill-content {
    flex: 1;
    padding-top: 3px;
    padding-bottom: 3px;
    word-break: break-word;
    hyphens: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: #FFF;
    border-color: inherit;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-top-right-radius: 5px 1px;
    border-bottom-right-radius: 5px 1px;
    padding-right: 2px; }
  .nx-token-pill:not(.nx-token-pill-parent) .nx-token-pill-content {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: inherit;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }

.nx-token-pill-child-arrow {
  margin-left: -1px;
  margin-top: 1px;
  margin-bottom: 1px;
  position: relative; }
  .nx-token-pill-child-arrow::before {
    content: '';
    clip-path: polygon(0 0, 1px 0, 2px 3%, 100% 48%, 100% 52%, 2px 97%, 1px 100%, 0 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; }
  .nx-token-pill-child-arrow::after {
    content: '';
    clip-path: polygon(0 0, 1px 0, 2px 3%, 100% 48%, 100% 52%, 2px 97%, 1px 100%, 0 100%);
    background-color: #FFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1px;
    left: -1px; }

.nx-token-pill-parent-arrow {
  position: relative; }
  .nx-token-pill-parent-arrow::before {
    content: '';
    clip-path: polygon(0 0, 1px 0, 2px 3%, 100% 48%, 100% 52%, 2px 97%, 1px 100%, 0 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin-left: -0.1px;
    /* white line appears in chrome at the beginning of the arrow */ }

.nx-token-pill-multiline .nx-token-pill-content {
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.nx-token-pill-inline {
  display: inline; }
  .nx-token-pill-inline .nx-token-pill-start {
    display: inline;
    vertical-align: baseline;
    white-space: nowrap;
    padding-top: 3px;
    padding-bottom: 3px; }
    .nx-token-pill-inline .nx-token-pill-start .nx-token-pill-icon-wrapper {
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 27px;
      display: inline; }
    .nx-token-pill-inline .nx-token-pill-start .nx-token-pill-parent-arrow {
      display: inline;
      vertical-align: baseline;
      padding-top: 3px;
      padding-bottom: 3px; }
  .nx-token-pill-inline .nx-token-pill-child-arrow {
    display: inline;
    vertical-align: baseline;
    padding-top: 3px;
    padding-bottom: 3px; }
  .nx-token-pill-inline .nx-token-pill-content {
    vertical-align: baseline;
    display: inline;
    white-space: normal;
    padding-top: 3px;
    padding-bottom: 3px; }

.nx-token-pill-disabled {
  filter: grayscale(1) opacity(0.4); }

.nx-token-pill-light {
  border-color: #2070D0; }
  .nx-token-pill-light .nx-token-pill-icon-wrapper {
    fill: #2070D0; }
  .nx-token-pill-light .nx-token-pill-parent-arrow::before {
    background-color: #2070D0; }
  .nx-token-pill-light .nx-token-pill-parent-arrow::after {
    content: '';
    clip-path: polygon(0 0, 1px 0, 2px 3%, 100% 48%, 100% 52%, 2px 97%, 1px 100%, 0 100%);
    background-color: #FFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1px;
    left: -1px; }
  .nx-token-pill-light .nx-token-pill-child-arrow::before {
    background-color: #2070D0; }

.nx-token-pill-dark {
  border-color: #2070D0; }
  .nx-token-pill-dark .nx-token-pill-icon-wrapper {
    background-color: #2070D0;
    fill: #FFF; }
  .nx-token-pill-dark .nx-token-pill-parent-arrow::before {
    background-color: #2070D0; }
  .nx-token-pill-dark .nx-token-pill-child-arrow::before {
    background-color: #2070D0; }

.nx-token-pill-fx {
  border-color: #09894E; }
  .nx-token-pill-fx .nx-token-pill-icon-wrapper {
    background-color: #09894E;
    fill: #FFF; }
  .nx-token-pill-fx .nx-token-pill-parent-arrow::before {
    background-color: #09894E; }
  .nx-token-pill-fx .nx-token-pill-child-arrow::before {
    background-color: #09894E; }
