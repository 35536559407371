.nx-tabs {
 .nav-tabs {
    border-color: $tab-nav-border-color;
  }

  .nav-item a {
    position: relative;
    padding: $tab-nav-link-padding-y $tab-nav-link-padding-x;
    background-color: $tab-nav-link-bg;
    border-color: $tab-nav-link-bg;
    margin-right: $tab-nav-spacing;
    color: $tab-nav-link-color;

    &::after {
      content: " ";
      position: absolute;
      height: 1px;
      width: calc(100% + 2px);
      background: $tab-nav-border-color;
      bottom: -1px;
      left: -1px;
    }

    &:hover {
      background-color: $tab-nav-link-hover-bg;
      border-color: $tab-nav-link-hover-bg;
    }

    &.active {
      border-color: $tab-nav-link-active-border-color;
      border-bottom-color: $tab-nav-link-active-bottom-border-color;
      background-color: $tab-nav-link-active-bg;
      color: $tab-nav-link-active-color;

      &::after {
        display: none;
      }
    }
  }

  &.nx-tabs-dark {
    .nav-tabs {
      border-bottom-color: $tab-nav-dark-border-color;
    }

    .nav-item a {
      border-bottom-color: $tab-nav-dark-border-color;

      &::after {
        background: $tab-nav-dark-border-color;
      }

      &.active {
        border-color: $tab-nav-dark-link-active-border-color;
        border-bottom-color: $tab-nav-dark-border-color;
        background-color: $tab-nav-dark-link-active-bg;
        color: $tab-nav-dark-link-active-color;
      }
    }
  }
}
