@import '../../mixins/icons/dropdown';
@import "./dropdown-menu";

.nx-dropdown {
  &.form-control {
    padding: 0;
  }
}

.nx-dropdown-error-panel {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba($red, 0.2);
}

.nx-dropdown:not([dropdown]) {
  position: relative;
  display: block;
  border-radius: $dropdown-border-radius;
  background-color: $color-white;

  &.nx-disabled {
    .nx-dropdown-toggle {
      opacity: 0.8;
    }

    .nx-dropdown-text {
      color: $color-gray-medium;
    }
  }

  &.nx-has-focus, &.nx-focus {
    border: 1px solid $color-brand-primary;
    outline: 0;
  }

  &.nx-has-error {
    border: 1px solid $color-monza-red;
    .nx-dropdown-text {
      color: $color-monza-red;
    }
  }

  .nx-variable-wrapper {
    flex-grow: 1;
  }

  .nx-empty .nx-dropdown-text {
    color: $color-gray-medium;
  }

  .nx-is-placeholder .nx-dropdown-text  {
    color: $color-gray-medium;
  }

  input {
    border: none;
    background: none;
  }

  &.nx-is-open,
  &.nx-open {
    .nx-dropdown-input-button {
      border-color: $color-nintex-orange;
    }

    .nx-dropdown-menu {
      display: block;
    }
  }

  .nx-dropdown-hidden-input {
    &:focus {
      outline: 0;
    }
  }

  &.nx-has-query {
    .dropdown-toggle {
      display: none;
    }

    .nx-dropdown-clear {
      display: inline;
    }
  }
}

.nx-dropdown-input {
  position: relative;
  display: block;
  padding: 0 $dropdown-toggle-size 0 0;
  background: none;
  border: none;
  outline: 0;
  width: 100%;
  height: $dropdown-height;

  &[disabled] {
    cursor: not-allowed;
  }
}

.nx-dropdown-text {
  display: inline-block;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
  padding: 10px 0 10px 12px;
  line-height: 1em;
  cursor: default;
}

.nx-dropdown-toggle {
  position: absolute;
  height: 100%;
  @include svg-dropdown-caret;
  width: $dropdown-toggle-size;
  background-position: center;
  border: none;
  outline: 0;
  top: 0;
  right: 0;
}

.nx-dropdown-clear {
  position: absolute;
  width: $dropdown-toggle-size;
  height: 100%;
  @include svg-dropdown-clear;
  background-size: 10px 10px;
  background-position: center;
  border: none;
  outline: 0;
  display: none;
}


// Dropdown Spinner
.nx-dropdown-spinner {
  .nx-spinner-small {
    float: left;
    cursor: default;
  }

  .nx-spinner-text {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.nx-dropdown:not([dropdown]) {
  .nx-dropdown-spinner {
    .nx-dropdown-text,
    .nx-dropdown-toggle {
      cursor: default;
    }
  }
}
