@import "../theme/theme";

.nx-placeholder {
  opacity: 0.75;
  padding: $padding-regular;
}

.nx-placeholder-item, .nx-placeholder-label {
  background: $placeholder-color;
  width: 50%;
  height: $padding-regular;
  margin-bottom: $padding-regular;
}

.nx-placeholder-item {
  width: 100%;
  height: $padding-large;
  margin-bottom: $padding-large;
}

.nx-shimmer {
  animation : shimmer 2s infinite;
  background: $placeholder-shimmer-bg;
  background-size: 1000px 100%;
}

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
