@import "../theme/theme";
.nx-logo {
    position: relative;
    display: inline-block;
    margin: $global-header-logo-margin;
    height: $global-header-logo-height;
    width: $global-header-logo-width;

    .nx-icon {
        position: absolute;
        top: -40px;
        left: -10px;
        height: 320%;
        width: 320%;
    }
}

a.nx-logo,
a.nx-logo:hover {
    text-decoration: none;
    cursor: pointer;
}

.nx-logo-image {
    background: url("#{$global-header-logo-src}") no-repeat center;
}
