.nx-config-panel {
  position: absolute;
  width: $config-panel-width;
  min-height: $config-panel-min-header-height;
  left: 100%;
  bottom: 0;
  top: 0;
  z-index: 900;
  transition: all 0.3s ease;
  will-change: transform;
  display: flex;
  flex-flow: column;
  background-color: $config-panel-bg-color;
  box-shadow: $config-panel-box-shadow;

  h1, h2, h3, h4, h5, h6, label, p {
    color: $config-panel-color;
  }

  &.nx-open {
    transform: translateX(-100%);
  }

  > header {
    border-left: $config-panel-border-width solid $config-panel-header-border-left-color;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: $config-panel-width;
    padding: $config-panel-heading-padding;
    min-height: $config-panel-min-header-height;
    max-height: $config-panel-max-header-height;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 100%;
      height: 1px;
      background-color: $config-panel-header-bottom-divider-color;
    }

    .nx-config-panel-heading, .nx-config-panel-subheading {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .nx-config-panel-heading {
      font-size: $config-panel-heading-font-size;
	    padding-right: $config-panel-heading-padding-right; // Stops text going under close button
    }

    .nx-config-panel-subheading {
      font-size: $config-panel-sub-heading-font-size;
      color: $config-panel-sub-heading-color;
    }



    &.nx-header-has-icon {
      .nx-config-panel-heading, .nx-config-panel-subheading {
        padding-left: $brand-icon-width + $config-panel-heading-padding;
      }
    }

    .nx-brand-logo {
      display: inline-block;
      position: absolute;
      top: 20px;
      left: $config-panel-heading-padding;
      border: 2px solid $color-white;
      border-radius: $border-radius-base;
      overflow: hidden;
    }

    p {
      color: #cbcbcb;
      font-size:14px;
      width: 100%;
      padding: 20px 0 0 0;
    }
  }

  > section {
    border-left: $config-panel-border-width solid $config-panel-section-border-left-color;

    h1, h2, h3, h4, h5, h6, label, p {
      color: $config-panel-section-color;
    }

    > h3 {
      border-bottom: $config-panel-section-h3-border-bottom;
    }
  }

  section {
    width: $config-panel-width - $config-panel-border-width;
    padding: 20px 15px 15px 15px;
    overflow-y: auto;
    height: 100%;

    p {
      color: #cbcbcb;
    }
  }

  .nx-brand-icon {
    background-color: $color-kashmir-blue;
    color: $color-white;
    height: $brand-icon-width;
    width: $brand-icon-width;

    svg {
        height: $brand-icon-width;
        width: $brand-icon-width;
    }
  }

  .nx-config-panel-close {
      position: absolute;
      right: 6px;
      top: 9px;
      min-width: 36px;
  }

  @if ($config-panel-has-footer) {
    // TODO: Remove all footer styling after Color Unification is complete
    footer {
      border-top: 1px solid $color-white;
      position: absolute;
      bottom: 0;
      min-height: 50px;
      width: $config-panel-width;
      padding: 15px;
      display: flex;
  
      & > * {
        order: 1;
        flex-grow: 1;
        margin: 5px;
  
        &:first-child {
          margin-left: 0;
        }
  
        &:last-child {
          margin-right: 0;
        }
  
      }
    }
  }

  &.nx-with-navbar {
    top: $global-header-height + 1px;

    section {
      bottom: $config-panel-footer-height;
      width: $config-panel-width;
    }
  }
}
