/* stylelint-disable */

$fp-width: 308px;
$fp-month-row-height: 28px;
$fp-weekdays-height: 28px;
$fp-calendar-top-height: $fp-month-row-height+$fp-weekdays-height+5px+8px;
$fp-year-nav-width: 28px;
$excess-icon-height: 4px;
$icon-vertical-margin: -$excess-icon-height / 2;

.flatpickr-calendar {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    padding: 1px 0 0;
    line-height: 24px;
    border-radius: 4px;
    position: absolute;
    width: $fp-width;
    touch-action: manipulation;
    background: $input-bg;
    border: 1px solid $input-border-color;
    &.open,
    &.inline {
        opacity: 1;
        visibility: visible;
        overflow: visible;
        max-height: 640px;
    }
    &.open {
        display: inline-block;
        z-index: 99999;
    }
    &.inline {
        display: block;
        position: relative;
        top: 2px;
    }
    &.static {
        position: absolute;
        top: calc(100% + 2px);
        &.open {
            z-index: 999;
            display: block;
        }
    }
    &.hasWeeks {
        width: auto;
    }
    .hasWeeks,
    .hasTime {
        .dayContainer {
            border-bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .hasWeeks {
        .dayContainer {
            border-left: 0;
        }
    }
    &.showTimeInput.hasTime {
        .flatpickr-time {
            height: 40px;
            border-top: 1px solid $datetime-time-section-border-top-color;
        }
    }
    &.noCalendar.hasTime {
        .flatpickr-time {
            height: auto;
        }
    }
    &::before,
    &::after {
        position: absolute;
        display: block;
        pointer-events: none;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        right: 11px;
    }
    &.rightMost {
        &::before,
        &::after {
            left: auto;
            right: 16px;
        }
    }
    &::before {
        border-width: 10px;
        margin: 0 -5px;
    }
    &::after {
        border-width: 9px;
        margin: 0 -4px;
    }
    &.arrowTop {
        &::before,
        &::after {
            bottom: 100%;
        }
        &::before {
            border-bottom-color: $input-border-color;
        }
        &::after {
            border-bottom-color: $input-bg;
        }
    }
    &.arrowBottom {
        &::before,
        &::after {
            top: 100%;
        }
        &::before {
            border-top-color: $input-border-color;
        }
        &::after {
            border-top-color: $input-bg;
        }
    }
    &:focus {
        outline: 0;
    }
    &.isAppended {
        right: 0;
        &.arrowTop {
            margin-top: 9px;
        }
        &.arrowBottom {
            margin-top: -9px;
        }
    }
    &.showMonthSelector {
        .flatpickr-weekdays {
            display: none;
        }
        .flatpickr-month {
            height: $fp-month-row-height + $fp-weekdays-height;
            padding-bottom: $fp-weekdays-height;
        }
        .flatpickr-month-selector {
            display: flex;
        }
    }
    &.showYearSelector {
        .flatpickr-weekdays {
            display: none;
        }
        .flatpickr-month {
            height: $fp-month-row-height + $fp-weekdays-height;
            padding-bottom: $fp-weekdays-height;
        }
        .flatpickr-year-selector {
            display: block;
        }
        .numInputWrapper {
            span.arrowDown,
            span.arrowUp {
                display: none;
            }
        }
    }
    &.highlightToday {
        .flatpickr-day.today {
            border: 1px solid currentColor;
        }
    }
}

.flatpickr-wrapper {
    position: relative;
    display: inline-block;
}

.flatpickr-months {
    > .flatpickr-prev-month, .flatpickr-month, .flatpickr-next-month {
        color: $datetime-months-text-color;
    }

    .flatpickr-month {
        overflow: visible;
    }
}

.flatpickr-month {
    color: $input-color;
    height: $fp-month-row-height;
    line-height: 1;
    text-align: center;
    position: relative;
    user-select: none;
    border-radius: 4px;
}

/* rtl:ignore-start */

.flatpickr-prev-month,
.flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    line-height: 16px;
    height: $fp-month-row-height;
    padding: 10px calc(3.57% - 1.5px);
    z-index: 3;
    color: $input-color;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    &:hover svg {
        fill: $datetime-prev-next-month-hover-color;
    }
    svg {
        width: 16px;
        height: 16px;
        transition: fill 0.1s;
        fill: currentColor;
    }
}

/* rtl:ignore-end */

.flatpickr-prev-month {
    left: 0;
}

.flatpickr-next-month {
    right: 0;
}

[dir='rtl'] {
    .flatpickr-prev-month,
    .flatpickr-next-month {
        svg {
            transform: scale(-1, 1);
        }
    }
}

.flatpickr-calendar .numInputWrapper {
    position: relative;
    height: auto;
    input,
    span {}
    input {
        display: inline-block;
        width: 100%;
    }
    span {
        display: inline-block;
        position: absolute;
        right: 0;
        width: 14px;
        padding: 0 4px 0 2px;
        height: 50%;
        line-height: 50%;
        opacity: 0;
        cursor: pointer;
        border: 1px solid $input-border-color;
        box-sizing: border-box;
        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 33%;
        }
        &:active {
             background: transparent
        }
        &.arrowUp {
            top: 0;
            border-bottom: 0;
            &::after {
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                 border-bottom: 4px solid $btn-secondary-border-color;
            }
        }
        &.arrowDown {
            top: 50%;
            &::after {
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid $btn-secondary-border-color;
            }
        }
        svg {
            width: inherit;
            height: auto;
            path {
                fill: $input-color;
            }
        }
    }
    &:hover,
    &.hasFocus {
        background: $datetime-time-sections-hover-color;
        span {
            opacity: 1;
        }
    }
}

/* rtl:ignore-start */


.flatpickr-current-month {
    height: $fp-month-row-height;
    span.cur-month {
        cursor: default;

        &:hover{
            background-color: transparent;
        }
    }

    .numInputWrapper {
        width: 5.5ch;
        // IE11 hack
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            width: 7.5ch;
        }
        display: inline-block;
        margin-left: 0.5ch;

        span.arrowUp::after {
          border-bottom-color: $color-taupe-gray;
        }
        span.arrowDown::after {
            border-top-color: $color-taupe-gray;
        }
    }

    input.cur-year {
        cursor: default;
        padding: 0;
        &:focus {
            outline: 0;
        }
        &[disabled],
        &[disabled]:hover {
            color: $gray-light;
        }
    }
}

/* rtl:ignore-end */

.flatpickr-weekdays {
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    height: $fp-weekdays-height;
    border-top: $datetime-weekdays-top-bottom-border;
    border-bottom: $datetime-weekdays-top-bottom-border;
}

span.flatpickr-weekday {
    cursor: default;
    font-size: 90%;
    background: transparent;
    color: $datetime-weekday-color;
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    flex: 1;
    font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
    padding: 1px 0 0 0;
}

.flatpickr-days {
    position: relative;
    overflow: hidden;
    display: flex;
    width: $fp-width;
    padding-top: 5px;
    &:focus {
        outline: 0;
    }
}

.dayContainer {
    padding: 0;
    outline: 0;
    text-align: left;
    width: $fp-width;
    min-width: $fp-width;
    max-width: $fp-width;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    box-sizing: border-box;
    color: $input-color;
    cursor: pointer;
    font-weight: 400;
    width: 14.2857143%;
    flex-basis: 14.2857143%;
    max-width: 39px;
    height: 39px;
    line-height: 39px;
    margin: 0;
    display: inline-block;
    position: relative;
    justify-content: center;
    text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange {
    cursor: pointer;
    outline: 0;
    background: $datetime-range-color;
    border-color: $datetime-range-color;
    box-shadow: -5px 0 0 $datetime-range-color, 5px 0 0 $datetime-range-color;
}

.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover {
    cursor: pointer;
    outline: 0;
    background: $datetime-day-hover-color;
    border-color: $datetime-day-hover-color;
    box-shadow: $datetime-prev-next-month-day-box-shadow;
}

.flatpickr-day:hover:not(.isEmpty){
    cursor: pointer;
    outline: 0;
    background: $datetime-day-hover-color;
    border-color: $datetime-day-hover-color;
}

.flatpickr-day.isEmpty {
    cursor: default;
}

.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
    border-color: $datetime-day-focus-border-color;
}

.flatpickr-day.today {
    font-weight: $datetime-today-font-weight;
    border-color: $datetime-today-border-color;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
    border: 1px solid $datetime-today-hover-border-color;
    background: $datetime-day-hover-color;
    color: inherit;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: $datetime-start-end-range-bg-color;
    box-shadow: none;
    color: $datetime-start-end-range-text-color;
    border-color: $datetime-start-end-range-bg-color;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 $datetime-start-end-range-coinciding-box-shadow-color;
          box-shadow: -10px 0 0 $datetime-start-end-range-coinciding-box-shadow-color;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px;
}

.flatpickr-day.inRange {
    border-radius: 0;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
    color: $datetime-prev-next-month-day-text-color;
    background: transparent;
    border-color: transparent;
    cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
    cursor: not-allowed;
    color: $datetime-disabled-day-text-color;
}

.flatpickr-day.week.selected {
    border-radius: 0;
}

.rangeMode .flatpickr-day {
    margin-top: 1px;
}

.flatpickr-weekwrapper {
    display: inline-block;
    float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
    padding: 0 12px;
    box-shadow: 1px 0 0 $color-gray-lighter;
}

.flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: $fp-month-row-height;
}

.flatpickr-weekwrapper span.flatpickr-day {
    display: block;
    width: 100%;
    max-width: none;
}

.flatpickr-innerContainer {
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 8px;
}

.flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    box-sizing: border-box;
}

.flatpickr-time {
    text-align: center;
    outline: 0;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    &::after {
        content: '';
        display: table;
        clear: both;
    }
    .numInputWrapper {
        flex: 1;
        width: 40%;
        height: 40px;
        float: left;
        span.arrowUp::after {
            border-bottom-color: $input-color;
        }
        span.arrowDown::after {
            border-top-color: $input-color;
        }
    }
    &.has-seconds {
        .numInputWrapper {
            width: 26%;
        }
    }
    &.time24hr {
        .numInputWrapper {
            width: 49%;
        }
    }
    input {
        background: transparent;
        box-shadow: none;
        border: 0;
        border-radius: 0;
        text-align: center;
        margin: 0;
        padding: 0;
        height: inherit;
        line-height: inherit;
        cursor: pointer;
        color: $input-color;
        font-size: 14px;
        position: relative;
        box-sizing: border-box;
        &.flatpickr-hour {
            font-weight: $datetime-time-hour-font-weight;
        }
        &.flatpickr-minute,
        &.flatpickr-second {
            font-weight: 400;
        }

        &:focus {
            outline: 0;
            border: 0;
            background: transparent;
        }

        &:hover{
            background-color: $datetime-time-sections-hover-color;
        }
    }
    .flatpickr-time-separator,
    .flatpickr-am-pm {
        height: inherit;
        display: inline-block;
        float: left;
        line-height: inherit;
        color: $input-color;
        font-weight: bold;
        width: 2%;
        user-select: none;
        align-self: center;
    }
    .flatpickr-am-pm {
        outline: 0;
        width: 18%;
        cursor: pointer;
        text-align: center;
        font-weight: 400;
        &:focus {
            background: $color-white-dull;
        }
        &:hover {
            background: $datetime-time-sections-hover-color;
        }
    }
    &[readonly] {
        cursor: pointer;
        background-color: $color-white;
    }
}

.flatpickr-month-selector,
.flatpickr-year-selector {
    position: absolute;
    top: $fp-calendar-top-height - 1px;
    left: 0;
    width: 100%;
    height: calc(100% - #{$fp-calendar-top-height - 1px});
    z-index: 10;
    display: none;
    flex-flow: row wrap;
    background-color: $input-bg;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid $color-gray-lighter;
}

.flatpickr-selector {
    flex-flow: row wrap;
    cursor: default;
}

.flatpickr-selector-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $input-color;
    user-select: none;
    cursor: pointer;
    &:hover {
        background-color: $color-peach-sorbet;
        border-radius: 4px;
    }
    &.selected {
        background: $brand-primary;
        box-shadow: none;
        color: $color-white;
        border-color: $brand-primary;
        border-radius: 4px;
    }
}

.flatpickr-month-selector {
    .flatpickr-selector-item {
        min-width: 33.3%;
        max-width: 33.3%;
    }
}

.flatpickr-year-selector {
    display: none;
    padding-left: $fp-year-nav-width;
    padding-right: $fp-year-nav-width;
    overflow: hidden;
    .flatpickr-selector {
        display: flex;
        height: 100%;
        transform: translate3d(0, 0, 0);
        z-index: 2;
        background-color: $input-bg;
        opacity: 1;
        position: absolute;
        top: 0;
        left: $fp-year-nav-width;
        width: calc(100% - #{$fp-year-nav-width * 2});
    }
    .flatpickr-selector-item {
        min-width: 50%;
        max-width: 50%;
    }
    .flatpickr-prev-year,
    .flatpickr-next-year {
        position: absolute;
        top: 0;
        height: 100%;
        width: $fp-year-nav-width;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 5;
        background-color: $input-bg;
        &:hover {
            color: $color-brand-primary;
        }
        svg {
            width: 24px;
            transition: fill 0.1s;
            fill: currentColor;
            pointer-events: none;
        }
    }
    .flatpickr-prev-year {
        left: 0;
        cursor: default;
        border-right: 1px solid $color-gray-lighter;
    }
    .flatpickr-next-year {
        right: 0;
        cursor: default;
        border-left: 1px solid $color-gray-lighter;
    }
    &.atMinYear {
        .flatpickr-selector-item {
            &.isEmpty:hover {
                background-color: transparent;
            }
        }
        .flatpickr-prev-year {
            display: none;
        }
    }
    &.atMaxYear {
        .flatpickr-selector-item {
            &.isEmpty:hover {
                background-color: transparent;
            }
        }
        .flatpickr-next-year {
            display: none;
        }
    }
}

[dir='rtl'] {
    .flatpickr-year-selector {
        .flatpickr-prev-year,
        .flatpickr-next-year {
            >svg {
                transform: scale(-1, 1);
            }
        }
    }
}

.flatpickr-actions {
    border-top: 1px solid $color-keyline;
    background-color: $color-gray-lighter;
    padding: 6px 12px;
    font-size: 0.75rem;
    user-select: none;
    &::after {
        display: block;
        clear: both;
        content: '';
    }
}

.flatpickr-action-today {
    float: left;
    font: inherit;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.nx-btn-date-picker{
    position: absolute;
    top: 0;
    right: 0;
    margin: $icon-vertical-margin 0;
    background: transparent;
    cursor: pointer;

    svg {
        color: $datetime-icon-color;
        fill: $datetime-icon-fill;
    }

    &:hover {
        background-color: $datetime-btn-date-picker-hover-color;
    }
}

.flatpickr-input.form-control {
    color: $datetime-input-text-color;

    &::placeholder {
        color: $datetime-input-placeholder-text-color;
    }

    &[readonly] {
        background-color: $datetime-input-read-only-bg-color;
        border-color: $datetime-input-border-color;

        &:focus {
            border-color: $datetime-input-read-only-focus-border-color;
        }
    }
}

.nx-date-picker-disabled {
    .flatpickr-input.form-control[disabled] {
        background-color: $input-disabled-bg;
        cursor: $datetime-disabled-cursor;
        color: $datetime-input-disabled-text-color;
    }

    .nx-btn-date-picker {
        cursor: $datetime-disabled-cursor;
    }
}
