$space: 3px;
$border-radius: 2px;
$icon-position: -7px;

.nx-pill {
  padding: 0;
  display: inline-flex;
  align-items: center;
  border-width: $border-width;
  border-style: solid;
  border-radius: $border-radius;
  color: $color-charcoal;
  user-select: none;

  &-stretchable {
    align-items: stretch;

    .nx-pill-prepend,
    .nx-pill-append,
    .nx-pill-content {
      display: flex;
      align-items: center;
    }
  }

  &-info {
    border-color: $color-brand-blue;
    background-color: $color-brand-blue;
  }

  &-success {
    border-color: $color-brand-green;
    background-color: $color-brand-green;
  }

  &-image {
    border-color: $color-brand-blue;
    background-color: $color-nearly-white;
    align-items: normal;

    .nx-pill-prepend {
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: $color-brand-blue;
      display: inline-flex;
      align-items: center;
    }
  }

  &-default {
    border-color: $color-gray-dark;
    background-color: $color-nearly-white;

    .nx-pill-prepend,
    .nx-pill-append {
      color: $color-charcoal;
    }
  }

  &-borderless {
    border: none;
    outline: none;
    background: transparent;

    .nx-pill-prepend {
      overflow: hidden;
      background-color: $color-brand-blue;
      border-top-left-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
    }

    .nx-pill-content {
      background: transparent;
    }
  }

  &-colored {
    .nx-pill-content {
      background: transparent;
    }

    .nx-pill-close {
      background: transparent;
    }
  }

  &-prepend {
    padding: 1px $space;
    color: $color-white;
    text-align: center;

    .nx-icon {
      top: $icon-position;
      left: $icon-position;
      margin-right: 5px;
    }
  }

  &-append {
    min-width: 25px;
    padding: 1px $space;
    color: $color-white;
    text-align: center;

    .nx-icon {
      top: $icon-position;
    }
  }

  &-content {
    flex-grow: 1;
    padding: $space $space + 2px;
    border-top-right-radius: $border-radius - 1px;
    border-bottom-left-radius: $border-radius - 1px;
    background-color: $color-nearly-white;
    word-break: break-word;
    hyphens: auto;
  }

  &-content-placeholder {
    display: block;
    width: 100px;
    height: 1rem;
  }


  &-close {
    display: flex;
    align-items: center;
    background: $color-white;
    cursor: pointer;

    &:hover {
      background-color: $color-gray-lighter;
    }

    .nx-icon__close {
      display: block;
      width: 23px;
      height: 23px;
    }
  }

  &:focus {
    outline: none;
  }
}

.nx-disabled {
  color: $color-gray-light;

  .nx-pill {
    border-color: $color-gray-light;
    background-color: $color-gray-light;
  }

  .nx-pill-image {
    background-color: $color-nearly-white;
  }

  .nx-pill-prepend {
    border-right-color: $color-gray-light;
  }

  .nx-pill-content,
  .nx-pill-close {
    color: $color-steel-gray;
  }
}

.nx-pill-color-blue {
  background-color: $color-pattens-blue;
  border-color: $color-pattens-blue;
}

.nx-pill-color-hawkes-blue {
  background-color: $color-hawkes-blue;
  border-color: $color-hawkes-blue;
}

.nx-pill-color-fog {
  background-color: $color-fog;
  border-color: $color-fog;
}

.nx-pill-color-tara {
  background-color: $color-tara;
  border-color: $color-tara;
}

.nx-pill-color-provincial-pink {
  background-color: $color-provincial-pink;
  border-color: $color-provincial-pink;
}

.nx-pill-color-yellow-hint {
  background-color: $color-hint-of-yellow;
  border-color: $color-hint-of-yellow;
}

.nx-pill-color-snuff {
  background-color: $color-snuff;
  border-color: $color-snuff;
}

.nx-pill-color-clear-day {
  background-color: $color-clear-day;
  border-color: $color-clear-day;
}

.nx-pill-color-default {
  background-color: $color-white;
  border-color: $color-white;
}
