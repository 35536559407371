.table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 0;

  thead th {
    color: $table-heading-color;
    background-color: $table-heading-bg-color;
    padding: $table-heading-cell-padding $table-cell-padding;

    &.nx-table-column-sortable {
      position: relative;
      padding: 0;

      button {
        padding: $table-heading-cell-padding $table-cell-padding;
        width: 100%;
        background: none;
        border: none;
        text-align: left;
        font-weight: inherit;
        color: inherit;
        font-size: inherit;
        cursor: pointer;
        outline: none;
        @include button-style-focus-inset-shadow($color-brand-orange);
      }

      button:first-child {
        padding-left: 0;
      }

      &:hover .nx-table-column-sort-indicator, .nx-table-column-is-sorting {
        opacity: 1;
      }

      &:hover .nx-table-column-sort-indicator {
        color: $color-brand-orange;
      }
    }

    &:last-child button {
      padding-right: 36px;

      .nx-table-column-sort-indicator {
        right: 12px;
      }
    }
  }

  &.nx-table-empty {
    .nx-table-column-sort-indicator {
      display: none;
    }

    th.nx-table-column-sortable button {
      cursor: default;
    }
  }
}

.nx-table {
  &.table-hover {
    tr:hover, tbody > tr:hover {
      background-color: $table-hover-bg;
    }

    > tr.table-active:hover, tbody > tr.table-active:hover {
      background-color: $table-active-row-hover-bg-color;
    }

    > tr.table-inactive:hover {
      background-color: $table-bg-color;
    }
  }

  &.table-sm td, &.table-sm th {
    padding: 3px 15px;

    &:first-child {
      padding-left: 20px;
    }
  }

  tbody + tbody {
    border-top: 1px solid $table-border-color;
  }

  @if $table-show-row-dividers {
    tbody tr:after {
      content: "";
      position: absolute;
      height: 1px;
      width: calc(100% - 1rem);
      background-color: $color-nintex-grey-5;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: -1px;
    }
  }
}

.table .nx-table-row-action {
  padding: 0;
  vertical-align: middle;

  .nx-action-menu-container {
    padding: 0;
    height: 100%;
    width: 100%;
  }
}

.nx-table-column-sort-indicator {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
  margin-left: $padding-small / 2;

  .nx-btn-icon-wrapper {
    top: 0;
  }
}

.nx-table-row-action {
  position: relative;
  width: 48px;
  text-align: center;
  cursor: pointer;

  .nx-icon {
    fill: $color-taupe-gray;
  }

  &[tabindex] {
    outline: 0;
  }

  &:hover,
  &:focus {
    background-color: $table-row-action-hover-bg-color;

    svg.nx-icon {
      fill: $table-row-action-hover-color;
    }
  }

  .nx-action-menu {
    top: 36px;
  }
}

.nx-editable-table-row {
  .nx-editable {
    display: none;
    width: 100%;
  }

  &.nx-editing {
    .nx-cell-wrapper {
      padding: 13px 9px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    @include dark-theme-background;

    .nx-table-hover & {
      > tr:hover {
        @include dark-theme-background;
      }
    }

    td.nx-table-row-action {
      @include svg-utility-white-close;
      background-position: center;
      background-size: 40px 40px;

      &:hover,
      &:focus {
        @include svg-utility-white-close;
        background-color: darken($color-dark-ash, 10%);
      }
    }
  }

  .nx-editable-table-row-actions {
    @include dark-theme-background;
    color: $dark-theme-text-color;

    .nx-actions-wrapper {
      padding: 0 12px 12px 12px;
      display: none;
    }

    button,
    [class*="btn"] {
      display: inline-block;
    }

    td[colspan] {
      border-top: 0 none;
      text-align: right;
      padding: 0;
    }

    hr {
      height: 1px;
      background-color: $color-white;
      border: 0 none;
      margin: 0 0 14px;
    }
  }
}

.nx-table-status,
.nx-table-status-stacked {
  display: flex;
  justify-content: center;
  align-content: center;
}

/* Once the old spinner has been removed, this can go */
.nx-table-status {
  .nx-spinner {
    position: relative;
    margin-right: 10px;
    top: 2px;
  }
}

.nx-table-status-stacked {
  flex-direction: column;
  text-align: center;
}

/* Load more */
.nx-table-load-more, .nx-table-footer {
  td {
    text-align: center;
    border-top: 1px solid $color-gray-lighter;
  }
}

.nx-table.table-hover {
  .nx-table-load-more:hover,
  .nx-table-footer:hover {
    background-color: inherit;
  }
}

.nx-table-sticky-header {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;

  &.nx-show {
    display: block;
  }
}

.nx-clickable-table-row {
  cursor: pointer;
}

/* Active row */
$active-row-border-width: 4px;

.nx-table {
  tr.table-active {
    td:first-child {
      border-left: $active-row-border-width solid $table-active-row-border-color;
      padding-left: calc(#{$table-cell-padding} - #{$active-row-border-width});
    }
  }

  &.table-sm {
    tr.table-active {
        td:first-child, th:first-child {
            padding-left: calc(20px - #{$active-row-border-width});
        }
    }
  }
}

/* Edge border */
$table-edge-border-width: 1px;
$table-edge-border-color: $table-edge-color;

.nx-table-edge-border {
  border: $table-edge-border-width solid $table-edge-border-color;
}

/* Row select */
.nx-table-row-select,
.nx-table-selectable {

  .nx-checkbox {
    height: 18px;
    width: 18px;

    .nx-checkbox-container {
      padding-left: 0;
      left: -8px;
    }
  }
}

.nx-table {
  .nx-table-row-select,
  .nx-table-selectable {
    padding-right: 0;
  }
}

.nx-table-selectable .nx-checkbox {
  .nx-checkbox-container {
    left: 0;
    position: relative;
    top: -6px;
  }
}

.nx-table-header-actions {
  padding: $padding-small;
  background-color: $color-white;
}


// Turn off accessibility features when use not tabbing
[data-whatintent='mouse'] {
  .table thead th.nx-table-column-sortable button:focus,
  .table thead th.nx-table-column-sortable button:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}
