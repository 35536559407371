// Nintex Modular Style Guide Default Theme
// ========================================
//
// Table of contents:
//
//  1. Colors
//  2. Canvas
//  3. Global navigation
//  4. Side navigation
// xx. Forms
// xx. Panels
// xx. Tables
// xx. Action menu
// xx. Alerts
// xx. Spinner
// xx. Card
// xx. Tile
// xx. Animation
// xx. Tabs
// xx. Tooltip
// xx. Compatibility Flags
// xx. Placeholder
// xx. Breadcrumbs
// xx. Toolbar
// xx. Flag
// xx. Datetime
// xx. Select

@import "./nintex";
@import "../mixins/error";
@import "../mixins/appearance";
@import "../mixins/icons";
@import "../mixins/utils";

// 1. Colors
// ---------

// ### Ancillary colors
$color-keyline: $color-gray-light;
$color-hover-bg: $color-peach-sorbet;
$color-body-bg: $color-silverstone;
$color-text: $color-gray-dark;

// xx. Appearance
$padding-large-vertical: $input-padding-y-lg;
$padding-large-horizontal: $input-padding-x-lg;

$padding-base-vertical: $input-padding-y;
$padding-base-horizontal: $input-padding-x;

$padding-small-vertical: $input-padding-y-sm;
$padding-small-horizontal: $input-padding-x-sm;

$icon-svg-size: 36px;

// 2. Canvas
// ---------
$canvas-font-size: 1rem;
$canvas-lg-margin: 30px 35px 0;
$canvas-lg-min-width: 1280px;
$canvas-md-margin: 30px 35px 0;
$canvas-md-min-width: 1024px;
$canvas-sm-margin: 30px 12px 0;
$canvas-sm-width: 964px;
$canvas-header-bottom-padding: 20px;
$canvas-header-bottom-margin: 20px;

// xx. Toolbox
$toolbox-width: 300px;
$toolbox-handle-width: 20px;
$toolbox-handle-height: 30px;
$toolbox-content-padding: 20px;
$toolbox-item-title-padding: 8px;
$toolbox-title-bg-color: $color-white;
$toolbox-border-radius: 4px;
$toolbox-drag-width: 30px;
$toolbox-z-index: 10;
$toolbox-item-dragging-z-index: 15;
$toolbox-item-dragging-rotation: 3deg;

// 3. Global navigation
// --------------------

// ### Global header (for layout)
$global-header-position: fixed;
$global-header-height: 46px;
$nx-header-height: $global-header-height;
$global-header-bg-color: $color-white !default;
$global-header-keyline-color: $color-keyline;
$global-header-logo-src: "/images/nintex-logo-flat-orange.svg";
$global-header-logo-width: 36px;
$global-header-logo-height: 36px;
$global-header-logo-size: 122px 20px;
$global-header-logo-margin: 0 5px 0 0;
$global-header-logo-base-color: #03161e !default;
$global-header-logo-accent-color: $color-nintex-orange !default;
$global-header-logo-icon-color: null !default;
$global-header-zindex: 10000;
$global-header-padding: 0 20px;
$global-header-brand-width: 30%;
$global-header-breadcrumbs-text-color: $body-color !default;
$global-header-breadcrumbs-link-color: $color-nintex-orange !default;

// ### Global navigation
$global-nav-alignment: right;
$global-nav-item-text-color: $color-taupe-gray !default;
$global-nav-item-text-color-hover: $color-brand-primary !default;
$global-nav-item-hover-bg-color: inherit !default;
$global-nav-selected-highlight-color: $color-nintex-orange !default;
$global-nav-selected-highlight-height: 2px !default;
$global-nav-item-selected-text-color: $color-nintex-orange !default;
$global-nav-item-link-padding: null !default;
$global-nav-level-0-selected-text-color: $color-brand-primary;
$global-nav-level-0-selected-text-color-hover: $color-brand-primary;
$global-nav-level-0-selected-highlight-color: $color-brand-primary !default;
$global-nav-level-1-bg-color: $color-white;
$global-nav-level-1-divider-color: $color-silverstone;
$global-nav-level-1-text-color: $color-taupe-gray;
$global-nav-level-1-text-color-hover: $color-taupe-gray;
$global-nav-level-1-bg-color-hover: $color-hover-bg;
$global-nav-level-1-zindex: 10000;
$global-nav-meatball-menu-color: $color-taupe-gray !default;
$global-nav-meatball-menu-hover-color: $color-brand-orange !default;
$global-nav-meatball-menu-use-secondary-styling: true !default;
$global-nav-meatball-menu-badge-bg-color: null !default;
$global-nav-meatball-menu-badge-color: null !default;

// 4. Side navigation
// --------------------

// ### Side navigation
$side-nav-width: 200px;
$side-nav-bg-color: $color-gray-darker;

$side-nav-border-right: null !default;
$side-nav-nav-group-line-display: block !default;
$side-nav-bg-color-light: $color-silverstone !default;
$side-nav-item-color: $color-gray-dark !default;
$side-nav-item-bg-color: transparent;
$side-nav-item-selected-color: $color-brand-orange !default;
$side-nav-item-selected-bg-color: $color-gray-lighter !default;
$side-nav-item-selected-border-left-color: $color-brand-orange !default;
$side-nav-item-hover-color: $color-gray-medium !default;
$side-nav-item-hover-bg-color: null !default;

$side-nav-bg-color-dark: $side-nav-bg-color !default;
$side-nav-liner-color-dark: $color-charcoal;
$side-nav-item-color-dark: $color-gray-light !default;
$side-nav-item-bg-color-dark: transparent;
$side-nav-item-selected-color-dark: $color-brand-orange !default;
$side-nav-item-selected-bg-color-dark: $color-charcoal !default;
$side-nav-item-hover-color-dark: $color-white !default;


$side-nav-text-color: $color-gray-light;
$side-nav-text-color-hover: $color-white;
$side-nav-border-color-selected: $color-brand-primary;
$side-nav-text-color-selected: $color-brand-primary;
$side-nav-bg-color-selected: $color-charcoal;
$side-nav-text-color-disabled: $color-gray-half;
$side-nav-text-shadow-disabled: transparentize($color-white, 0.8);

// xx. Forms
// ---------

// ### General
$border-radius-small: 2px;
$border-radius-base: 4px !default;
$border-radius-large: 8px;
$cursor-disabled: not-allowed;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: $border-radius-base;
$btn-border-radius-large: $border-radius-base;
$btn-border-radius-small: $border-radius-base;

$padding-regular: 15px;
$padding-large: $padding-regular * 2;
$padding-small: 10px;

// ### Buttons
$btn-primary-border-color: darken($btn-primary-bg, 5%) !default;
$btn-primary-color-hover: $color-white;
$btn-primary-bg-hover: lighten($btn-primary-bg, 10%) !default;
$btn-primary-focus-outline-color: null !default;

$btn-secondary-bg: $color-white;
$btn-secondary-color-hover: darken($color-brand-orange, 5%) !default;
$btn-secondary-bg-hover: $color-peach-sorbet !default;
$btn-secondary-focus-border-color: $btn-primary-bg-hover !default;
$btn-secondary-active-bg-color: null !default;

$btn-danger-bg-hover: null !default;
$btn-danger-focus-outline-color: null !default;
$btn-danger-active-bg-color: null !default;

$btn-outline-secondary-bg: transparent;
$btn-outline-secondary-border-color: darken($color-white, 20%);
$btn-outline-secondary-color: darken($color-white, 20%);
$btn-outline-secondary-bg-hover: transparentize($color-white, 0.8);
$btn-outline-secondary-color-hover: $color-white;
$btn-outline-secondary-border-color-hover: $color-white;

$btn-disabled-color: $color-silverstone !default;
$btn-disabled-bg: $color-gray-light !default;
$btn-disabled-border: $color-gray-light !default;

$btn-inverse-color: $color-white;
$btn-inverse-border: $color-white;
$btn-inverse-bg-hover: rgba($color-white, 0.2) !default;
$btn-inverse-bg: transparent;
$btn-inverse-focus-bg-color: null !default;
$btn-inverse-active-bg-color: null !default;
$btn-inverse-disabled-solid: false !default;

$btn-text-color: $color-brand-primary !default;
$btn-text-hover-color: null !default;
$btn-text-hover-bg-color: null !default;
$btn-text-focus-background-color: null !default;

$btn-link-hover-color: $color-burnt-orange !default;
$btn-link-focus-text-decoration: null !default;
$btn-link-disabled-text-color: null !default;
$btn-link-disabled-text-bg-color: null !default;
$btn-link-text-decoration-color: $color-brand-orange !default;
$btn-link-hover-bg-color: null !default;

$btn-padding-y-sm: .3rem;
$btn-padding-x-sm: .45rem;
$btn-font-size-sm: 14px;

$btn-stateful-width: 36px;

// ### Dropdown
$dropdown-border-radius: $border-radius-base;
$dropdown-inner-border-radius: $dropdown-border-radius + 2px;
$dropdown-toggle-size: 30px;
$dropdown-height: 34px;

// ### Radios & Checkboxes
$checkbox-border-color: $color-gray-medium !default;
$checkbox-bg-color: $color-white;
$checkbox-text-color: $color-gray-medium;
$checkbox-selected-bg-color: $color-brand-primary !default;
$checkbox-selected-border-color: $color-brand-primary !default;
$checkbox-disabled-bg-color: $input-disabled-bg !default;
$checkbox-disabled-border-color: $input-disabled-bg !default;
$checkbox-disabled-label-color: $color-gray-medium !default;
$checkbox-focus-outline-color: rgba($btn-primary-border, .5) !default;
$checkbox-unselected-focus-bg-color: null !default;
$checkbox-unselected-focus-border-color: $btn-primary-border !default;
$checkbox-selected-focus-bg-color: null !default;
$checkbox-selected-focus-border-color: null !default;

$radio-border-color: $color-gray-medium;
$radio-bg-color: $color-white;
$radio-selected-bg-color: $color-white;
$radio-selected-border-color: $color-brand-primary;
$radio-disabled-bg-color: $color-white;
$radio-disabled-border-color: $input-disabled-bg;
$radio-disabled-label-color: $color-gray-medium;

// ### Checkbox toggle
$checkbox-toggle-unselected-border-color: $color-gray-light;
$checkbox-toggle-unselected-bg-color: $color-gray-light !default;
$checkbox-toggle-unselected-text-color: $body-color !default;
$checkbox-toggle-selected-border-color: $color-brand-primary;
$checkbox-toggle-selected-bg-color: $color-brand-primary !default;
$checkbox-toggle-selected-text-color: $body-color !default;
$checkbox-toggle-disabled-bg-color: $color-gray-lighter !default;
$checkbox-toggle-disabled-text-color: $color-gray-medium !default;
$checkbox-toggle-width: 52px;
$checkbox-toggle-height: 24px;
$checkbox-toggle-knob-width: 20px;
$checkbox-toggle-knob-height: 20px;
$checkbox-toggle-width-small: 40px;
$checkbox-toggle-height-small: 19px;
$checkbox-toggle-knob-width-small: 15px;
$checkbox-toggle-knob-height-small: 15px;
$checkbox-toggle-knob-color: $color-white;
$checkbox-toggle-padding: 2px;
$checkbox-toggle-font-size: 11px;
$checkbox-toggle-focus-outline-color: rgba($btn-primary-border, .5) !default;
$checkbox-toggle-focus-bg-color: null !default;

// ### Radio toggle
$radio-toggle-unselected-border-color: $color-keyline !default;
$radio-toggle-unselected-bg-color: $color-white;
$radio-toggle-unselected-text-color: $input-color !default;
$radio-toggle-selected-border-color: $color-brand-primary !default;
$radio-toggle-selected-bg-color: $color-brand-primary !default;
$radio-toggle-selected-text-color: $color-white;
$radio-toggle-hover-bg-color: $color-peach-sorbet !default;
$radio-toggle-hover-text-color: $color-brand-primary !default;
$radio-toggle-dark-unselected-border-color: $btn-outline-secondary-color !default;
$radio-toggle-dark-selected-border-color: $color-gray-lighter !default;
$radio-toggle-dark-selected-bg-color: $color-gray-lighter !default;
$radio-toggle-dark-label-bg-color: transparent !default;
$radio-toggle-dark-label-hover-bg-color: transparentize($color-white, 0.8) !default;
$radio-toggle-dark-label-unselected-text-color: $btn-outline-secondary-color !default;
$radio-toggle-dark-label-selected-text-color: $color-worn-black !default;
$radio-toggle-dark-disabled-unselected-bg-color: transparent !default;
$radio-toggle-disabled-unselected-bg-color: $color-white !default;
$radio-toggle-disabled-border-color: $input-disabled-bg !default;
$radio-toggle-disabled-selected-bg-color: $input-disabled-bg !default;
$radio-toggle-disabled-text-color: $color-gray-medium !default;
$radio-toggle-disabled-selected-text-color: $color-nearly-white !default;
$radio-toggle-border-radius: $input-border-radius;
$radio-toggle-label-border-radius: $input-border-radius + 2px;
$radio-toggle-label-focus-color: transparentize($brand-primary, 0.5) !default;
$radio-toggle-dark-label-focus-color: transparentize($radio-toggle-dark-selected-bg-color, 0.5) !default;
$radio-toggle-padding-x: $input-btn-padding-x;
$radio-toggle-padding-y: 0.1875rem;
$radio-toggle-btn-spacing: 0.125rem;

// ### Form Error
$error-control-border-color: $color-brand-red;
$error-message-text-color: $color-brand-red;
$error-message-bg-color: #f2dede;
$error-control-text-color: $color-brand-red;
$error-message-focus-text-color: $color-brand-red;
$error-message-focus-bg-color: #f2dede;

// ### Input
$input-disabled-color: null !default;
$input-disabled-border-color: null !default;
$input-read-only-color: null !default;
$input-read-only-bg-color: $color-gray-lighter !default;
$input-read-only-border-color: null !default;
$input-read-only-focus-border-color: $color-gray-light !default;
$input-error-bg-color: $color-monza-red !default;
$input-error-border-color: $color-monza-red !default;
$input-clear-button-hover-bg-color: null !default;

// xx. Panels
// ----------
$panel-bg-color: $color-white;
$panel-border-color: $color-keyline;
$panel-action-icon-size: 28px;
$panel-body-font-family: $font-family-sans-serif;
$panel-margin-bottom: 1rem;
$panel-padding: 15px;
$panel-heading-color: $color-taupe-gray !default;
$panel-header-vertical-padding: 12px;
$panel-header-background-color: $color-gray-lighter !default;
$panel-header-btn-close-color: $color-white !default;
$panel-header-btn-close-bg-color: transparentize($color-white, 0.8) !default;
$panel-header-btn-close-height: 20px !default;
$panel-header-btn-close-width: 20px !default;
$panel-header-btn-close-icon-position: absolute !default;
$panel-header-btn-close-include-focus-shadow: false !default;
$panel-heading-font-size: $h3-font-size;
$panel-extension-wrapper-color: $color-white !default;
$panel-extension-wrapper-margins: 0 !default;
$panel-extension-wrapper-background-color: $color-worn-black !default;
$panel-extension-wrapper-border-color: $color-worn-black !default;
$panel-extension-wrapper-border-width: 0 !default;
$panel-extension-wrapper-border-radius: 0 !default;
$color-form-panel-plain-text: $color-white;
$panel-display-dividers-visibiliity: none !default;
$panel-summary-border-top: 1px solid $color-gray-lighter !default;

// xx. Config Panel
$config-panel-color: $color-white !default;
$config-panel-bg-color: $color-dark-ash !default;
$config-panel-box-shadow: none !default;
$config-panel-width: 400px;
$config-panel-min-header-height: 90px;
$config-panel-max-header-height: 120px;
$config-panel-footer-height: 75px;
$config-panel-border-width: 4px;
$config-panel-header-border-left-color: $color-nintex-orange !default;
$config-panel-header-bottom-divider-color: $color-white !default;
$config-panel-heading-font-size: 20px;
$config-panel-sub-heading-font-size: 14px;
$config-panel-sub-heading-color: $color-gray-light !default;
$brand-icon-width: 48px;
$config-panel-heading-padding: 15px;
$config-panel-heading-padding-right: 20px;
$config-panel-section-border-left-color: $color-nintex-orange !default;
$config-panel-section-color: $color-white !default;
$config-panel-section-h3-border-bottom: null !default;
$config-panel-has-footer: true !default;

// xx. Config Panel Card
$config-panel-card-header-bg-color: $color-peach-sorbet !default;
$config-panel-editing-action-btn-color: $color-white !default;
$config-panel-card-editing-bg-color: $color-worn-black !default;
$config-panel-card-color: $color-worn-black !default;
$config-panel-card-border-color: $color-gray-light !default;
$config-panel-card-editing-color: null !default;
$config-panel-card-editing-headers-color: null !default;

// xx. Modals
$modal-overlay-bg-color: transparentize($color-black, 0.5) !default;
$modal-dialog-margin: 30px;
$modal-dialog-bg-color: $color-white;
$modal-dialog-border-color: $color-gray-light;
$modal-dialog-border-radius: $border-radius-base;
$modal-dialog-small-width: 480px;
$modal-dialog-medium-width: 900px;
$modal-dialog-large-width: calc(100% - 60px);
$modal-dialog-large-height: calc(100% - 60px);
$modal-dialog-header-color: currentColor !default;
$modal-dialog-header-bg-color: $color-silverstone !default;
$modal-dialog-header-close-is-gray-svg: true !default;
$modal-dialog-heading-1-font-size: $font-size-h2;
$modal-dialog-heading-2-font-size: $font-size-h3;
$modal-dialog-heading-3-font-size: $font-size-h4;
$modal-dialog-header-btn-close-color: $color-steel-gray !default;
$modal-dialog-header-btn-close-bg-color: transparent !default;
$modal-dialog-footer-pad-horizontal: 20px;
$modal-dialog-footer-pad-vertical: 18px;
$modal-dialog-footer-bg-color: $color-talking-heads !default;
$modal-dialog-display-dividers-visibility: none !default;

// xx. Tables
// ----------
$table-heading-bg-color: $color-gray-lighter !default;
$table-heading-color: null !default;
$table-heading-cell-padding: 0.625rem; // 10px
$table-border-active-color: $color-brand-primary;
$table-border-color: $color-keyline;
$table-bg-color: $color-white;
$table-text-inactive-color: $color-gray-medium;
$table-show-row-dividers: false !default;
$table-active-row-hover-bg-color: $color-silverstone !default;
$table-active-row-border-color: $color-nintex-orange !default;
$table-row-action-hover-bg-color: $color-dark-honeycomb !default;
$table-row-action-hover-color: $color-nintex-orange !default;
$table-edge-color: $color-keyline !default;

// xx. Action menu
// ---------------
$action-menu-box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3) !default;
$action-menu-text-color: $color-gray-light !default;
$action-menu-text-color-hover: $color-white !default;
$action-menu-text-color-disabled: $color-gray-medium !default;
$action-menu-keyline: $color-keyline !default;
$action-menu-bg-color: transparentize($color-gray-darker, 0.05) !default;
$action-menu-bg-color-hover: $color-gray-medium !default;
$action-menu-tail-border-color: rgba(32, 32, 32, 0.2) !default;
$action-menu-border: none !default;
$action-menu-default-min-width: 132px;
$action-menu-default-max-width: 170px;
$action-menu-large-min-width: 220px;
$action-menu-large-max-width: 400px;
$action-menu-border-radius: $border-radius-base;
$action-menu-item-hover-bg-color: $color-peach-sorbet !default;
$action-menu-item-hover-color: $color-brand-orange !default;
$action-menu-header-border-color: darken($color-gray-light, 40%) !default;
$action-menu-header-child-element-color: $color-gray-light !default;

// xx. Alerts
// -----------------
$alert-text-color: $color-white;
$alert-information-bg-color: $color-brand-blue !default;
$alert-information-text-color: $alert-text-color;
$alert-success-bg-color: $color-brand-green !default;
$alert-success-text-color: $alert-text-color;
$alert-warning-bg-color: $color-brand-yellow !default;
$alert-warning-text-color: $color-charcoal !default;
$alert-danger-bg-color: $color-brand-red !default;
$alert-danger-text-color: $alert-text-color;

// xx. Spinner
// -----------
$status-spinner-color: $color-charcoal !default;
$status-spinner-slice-width: 2px;
$spinner-theme-regular-left-color: $color-taupe-gray !default;
$spinner-theme-regular-right-color: $color-gray-light !default;
$spinner-theme-light-right-color: transparentize($color-white, 0.55) !default;

// xx. Card
// --------
$card-width: 250px; //300px;
$card-height: 210px; // 390px;
$card-padding: 5px;
$card-panel-bg-color: $color-talking-heads;
$card-header-height: 58px;
$card-header-bg-color: #3097D2;
$card-actions-bg-color: $color-kashmir-blue;
$card-actions-border-radius: 2px;
$card-body-height: $card-height - $card-header-height;
$card-label-bg-color: $color-silverstone;
$card-label-text-color: $color-gray-medium;

// xx. Tile
// --------
$tile-width: 33%;
$tile-border-color: $color-keyline;
$tile-bg-color: $color-white;
$tile-border-radius: $border-radius-base;
$tile-header-border-color: $color-keyline;
$tile-gutter: 20px;
$tile-actions-border-radius: $border-radius-small;
$tile-actions-bg-color: $color-white;
$tile-actions-border-color: $color-keyline;

// xx. Animation
// -----------------
$transition-default-duration: 0.3s;
$transition-default-function: ease;

// xx. Tabs
// -----------------
$tab-nav-border-color: $color-gray-lighter;
$tab-nav-link-bg: $color-gray-lighter;
$tab-nav-link-hover-bg: $color-gray-light;
$tab-nav-link-color: $color-charcoal;
$tab-nav-link-active-bg: $color-white;
$tab-nav-link-active-color: $color-brand-orange;
$tab-nav-link-active-border-color: $color-gray-lighter;
$tab-nav-link-active-bottom-border-color: $color-white;
$tab-nav-spacing: 0.3125rem;
$tab-nav-link-padding-y: .25rem;
$tab-nav-link-padding-x: 1.75rem;

$tab-nav-dark-border-color: $color-charcoal;
$tab-nav-dark-link-active-bg: $color-charcoal;
$tab-nav-dark-link-active-color: $color-white;
$tab-nav-dark-link-active-border-color: $color-charcoal;

// xx. Tooltip
// -----------------
$tooltip-border-width: 1px;
$tooltip-arrow-width: 8px;
$tooltip-arrow-height: 8px;
$tooltip-arrow-color: $color-black;
$tooltip-text-color: $color-white;
$tooltip-default-bg-color: $color-black !default;
$tooltip-default-text-color: $color-white !default;
$tooltip-information-bg-color: $color-brand-blue !default;
$tooltip-information-text-color: $tooltip-text-color;
$tooltip-success-bg-color: $color-brand-green !default;
$tooltip-success-text-color: $tooltip-text-color;
$tooltip-warning-bg-color: $color-brand-yellow !default;
$tooltip-warning-text-color: $color-charcoal !default;
$tooltip-danger-bg-color: $color-brand-red !default;
$tooltip-danger-text-color: $tooltip-text-color;
$tooltip-light-bg-color: $color-white;
$tooltip-light-text-color: $color-charcoal;
$tooltip-light-border-color: $color-gray-light;
$tooltip-box-shadow: none !default;

// xx. Compatibility Flags
$global-nav-compatibility: false !default;
$navigation-menu-compatibility: false !default;
$panel-compatibility: false !default;

// xx. Placeholder
$placeholder-color: $color-gray-medium !default;
$placeholder-shimmer-bg: linear-gradient(to right, $placeholder-color 4%, darken($placeholder-color, 6%) 25%, $placeholder-color 36%) !default;

// xx. Breadcrumbs
$breadcrumbs-text-color: $color-taupe-gray !default;
$breadcrumbs-text-color-inverse: $color-white;
$breadcrumbs-link-color-inverse: null !default;
$breadcrumb-link-hover-decoration: none !default;
$breadcrumb-icon-light-color: $color-gray-medium !default;

// xx. Toolbar
$toolbar-background-color: $color-charcoal !default;
$toolbar-item-color: $color-gray-light !default;
$toolbar-item-disabled-color: $color-gray-medium !default;
$toolbar-item-hover-color: $color-white !default;
$toolbar-item-hover-bg-color: rgba($color-white, 0.2) !default;
$toolbar-separator-color: $color-gray-light !default;
$toolbar-box-shadow: null !default;
$toolbar-sub-menu-box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.11) !default;
$toolbar-sub-menu-border: null !default;
$toolbar-sub-menu-item-color: $color-taupe-gray !default;
$toolbar-sub-menu-item-hover-color: $color-peach-sorbet !default;
$toolbar-inverse-bg-color: $color-silverstone !default;
$toolbar-inverse-separator-color: $color-gray-dark !default;
$toolbar-inverse-item-hover-bg-color: $toolbar-item-hover-bg-color !default;
$toolbar-inverse-item-disabled-color: $color-gray-light !default;
$toolbar-inverse-item-color: $body-color !default;
$toolbar-inverse-item-hover-color: $body-color !default;

// xx. Flag
$flag-unconfigured-bg-color: $color-brand-primary !default;
$flag-unconfigured-icon-color: $color-white !default;
$flag-alert-bg-color: $color-brand-yellow !default;
$flag-alert-icon-color: $color-charcoal !default;
$flag-error-bg-color: $color-brand-red !default;
$flag-error-icon-color: $color-white !default;
$flag-success-bg-color: $color-brand-green !default;
$flag-success-icon-color: $color-white !default;

// xx. Datetime
$datetime-icon-color: $color-taupe-gray !default;
$datetime-icon-fill: $color-nintex-orange !default;

$datetime-months-text-color: null !default;
$datetime-prev-next-month-hover-color: $color-brand-primary !default;

$datetime-weekday-color: $color-gray-medium !default;
$datetime-weekdays-top-bottom-border: 1px solid $color-gray-lighter !default;

$datetime-day-hover-color: $color-peach-sorbet !default;
$datetime-day-focus-border-color: $color-peach-sorbet !default;
$datetime-range-color: $color-peach-sorbet !default;
$datetime-start-end-range-coinciding-box-shadow-color: $brand-primary !default;
$datetime-start-end-range-bg-color: $brand-primary !default;
$datetime-start-end-range-text-color: $color-white !default;
$datetime-today-font-weight: bold !default;
$datetime-today-border-color: null !default;
$datetime-disabled-day-text-color: inherit !default;
$datetime-prev-next-month-day-text-color: $color-gray-light !default;
$datetime-prev-next-month-day-box-shadow: -5px 0 0 $datetime-day-hover-color, 5px 0 0 $datetime-day-hover-color !default;
$datetime-today-hover-border-color: $color-gray-light !default;

$datetime-time-section-border-top-color: $color-gray-lighter !default; 
$datetime-time-sections-hover-color: $color-peach-sorbet !default;
$datetime-time-hour-font-weight: bold !default;

$datetime-input-text-color: null !default;
$datetime-input-disabled-text-color: null !default;
$datetime-input-placeholder-text-color: null !default;
$datetime-input-border-color: null !default;
$datetime-input-read-only-bg-color: $color-gray-lighter !default;
$datetime-input-read-only-focus-border-color: null !default;
$datetime-disabled-cursor: null !default;
$datetime-btn-date-picker-hover-color: null !default;

// xx. Select
$select-border-color: $color-gray-light !default;
$select-focus-border-color: $color-nintex-orange !default;
$select-warning-border-color: $color-brand-yellow !default;
$select-warning-bg-color: $color-brand-yellow !default;
$select-warning-text-color: $color-charcoal !default;
$select-error-border-color: $color-brand-red !default;
$select-error-bg-color: $color-brand-red !default;
$select-disabled-bg-color: $color-gray-lighter !default;
$select-disabled-text-color: $color-gray-medium !default;
$select-input-text-color: $color-gray-dark !default;
$select-indicators-color: $color-gray-dark !default;

$select-option-selected-bg-color: $color-nintex-orange !default;
$select-option-selected-text-color: $color-white !default;
$select-option-hover-bg-color: $color-peach-sorbet !default;
$select-option-subtext-text-color: $color-gray-medium !default;
$select-option-selected-subtext-text-color: $color-white !default;
$select-option-action-text-color: $color-nintex-orange !default;
$select-option-action-active-selected-text-color: $select-option-selected-text-color !default;
$select-option-action-bottom-border-color: $color-gray-light !default;
$select-no-option-text-color: $color-steel-gray !default;
$select-group-heading-text-color: $color-gray-medium !default;

$select-menu-border-color: $select-focus-border-color !default;
$select-menu-top-bottom-border-color: $color-gray-light !default;
$select-menu-text-color: $color-gray-dark !default;

$select-multi-value-border-color: $color-gray-medium !default;
$select-multi-value-text-color: $color-gray-dark !default;
$select-multi-value-hover-bg-color: $color-peach-sorbet !default;
$select-multi-value-remove-hover-bg-color: $color-dark-honeycomb !default;