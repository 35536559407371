@import '../mixins/action-menu';

.nx-action-menu-container {
  display: inline-block;
  position: relative;

  > button:not(.btn) {
    padding: 0;
    outline: none;
    cursor: pointer;
    background: none;
    border: none;
  }
}

.nx-action-menu-overlay {
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  background: transparent;
  z-index: 800;
}

.nx-action-menu {
  display: none;
  position: absolute;
  min-width: $action-menu-default-min-width;
  max-width: $action-menu-default-max-width;
  z-index: 20000;
  margin-top: 7px;
  border-radius: $action-menu-border-radius;
  background-color: $action-menu-bg-color;
  box-shadow: $action-menu-box-shadow;
  border: $action-menu-border;

  &.nx-action-menu-fixed {
    position: fixed;
  }

  &.nx-action-menu-large {
    min-width: $action-menu-large-min-width;
    max-width: $action-menu-large-max-width;
  }

  &::before {
    content: ' ';
    position: absolute;
    top: -7px;
    width: 0;
    height: 0;
    border-width: 0 8px 7px 8px;
    border-style: solid;
    border-color: transparent transparent $action-menu-tail-border-color transparent;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: -6px;
    width: 0;
    height: 0;
    border-width: 0 8px 7px 8px;
    border-style: solid;
    border-color: transparent transparent $action-menu-bg-color transparent;
    z-index: 2;
  }

  &.nx-action-menu-inverse {
    margin-top: -7px;

    &::before {
      top: initial;
      bottom: -7px;
      transform: rotate(180deg);
    }

    &::after {
      top: initial;
      bottom: -6px;
      transform: rotate(180deg);
    }
  }

  &.nx-align-right {
    right: 0;
    &::before,
    &::after {
      right: 9px;
    }

    &.nx-table-row-action-menu {
      &::before,
      &::after {
        right: 15px;
      };
    }
  }

  &.nx-align-center {
    left: 50%;
    transform: translateX(-50%);
    &::before,
    &::after {
      left: 50%;
      margin-left: -8px;
    }
  }

  &.nx-align-left {
    left: 0;
    &::before,
    &::after {
      left: 11px;
    }
  }

  &.nx-align-baseline {
    margin-top: 0;
  }
}

.nx-action-menu-list {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 5px 0;
  position: relative;
  z-index: 900;

  @include action-menu-item;

  .nx-action-menu-header {
    border-bottom: 1px solid $action-menu-header-border-color;
    border-top: 1px solid $action-menu-header-border-color;

    > div, > h5 {
      padding: 10px 13px;
      font-size: 0.75rem;
      text-transform: uppercase;
      color: $action-menu-header-child-element-color;
      margin: 0;
    }
  }

  li:first-child {
    border-top: none;
  }

  li:last-child {
    border-bottom: none;
  }

  &.nx-has-icons {
    @include action-menu-item($has-icon: true);

    .nx-icon {
      position: absolute;
      float: left;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.nx-action-menu.nx-open {
  opacity: 0;

  &.nx-showing {
    opacity: 1;
  }
  &.nx-action-menu {
    display: block;
  }
}

.nx-action-menu.nx-action-menu-light {
  background-color: $color-white;

  &::after {
    border-color: transparent transparent $color-white transparent;
  }

  .nx-action-menu-header {
    border-color: $color-gray-light;
  }

  .nx-action-menu-list > li:not(.nx-action-menu-header) > button,
  .nx-action-menu-list > li:not(.nx-action-menu-header) > * {
    &:hover, &:focus {
      background-color: $action-menu-item-hover-bg-color;
      color: $action-menu-item-hover-color;
    }
  }

  .nx-action-menu-list > li > button,
  .nx-action-menu-list > li > *,
  .nx-action-menu-header > div,
  .nx-action-menu-header > h5 {
    color: inherit
  }
  }

  // Turn off accessibility features when use not tabbing
  [data-whatintent="mouse"] {
    .nx-action-menu-list button:not(.disabled):focus:not(:hover){
        background: none; 
        color: $color-gray-light;
        text-decoration: inherit;
    }
    .nx-action-menu.nx-action-menu-light .nx-action-menu-list  button:not(.disabled):focus:not(:hover){
      background: none;
      color: inherit;
      text-decoration: inherit;    
    }
  }

   // Show only accessibility features when using tabbing (dont show mouse hover effect)
   [data-whatintent="keyboard"] {
    .nx-action-menu-list button:not(.disabled):hover:not(:focus){
        background: none;
        color: $color-gray-light;
        text-decoration: inherit;
    }
    .nx-action-menu.nx-action-menu-light .nx-action-menu-list  button:not(.disabled):hover:not(:focus){
      background: none;
      color: inherit;
      text-decoration: inherit;    
    }
  }
