$icon-default-size: 48px;
$icon-default-ratio: 100%;
$icon-sizes: (small: 16px, small-medium: 24px, medium: 36px, medium-large: 48px, large: 55px, extra-large: 64px);

@function get-icon-size($size) {
  @if map-has-key($icon-sizes, $size) {
    @return map-get($icon-sizes, $size);
  }

  @return $size;
}

/// Set both container dimensions and background size for the icon.
@mixin icon-size($dimensions, $size: $dimensions) {
  @if length($dimensions) == 1 {
    $length: get-icon-size($dimensions);
    width: $length;
    height: $length;
  } @else if length($dimensions) == 2 {
    width: nth($dimensions, 1);
    height: nth($dimensions, 2);
  }

  @if length($size) == 1 {
    // For <=IE11, we need to duplicate the background size
    $bg-size: get-icon-size($size);
    background-size: $bg-size $bg-size;
  }
}

@mixin icon-bg-size($size) {
  background-size: get-icon-size($size);
}

@mixin foundation-icon-sizes {
  @each $name, $size in $icon-sizes {
    .icon-#{$name} {
      @include icon-bg-size($size);
    }
  }
}

@mixin foundation-icon-block($dimensions, $size: $dimensions) {
  display: block;
  @include icon-size($dimensions, $size);
}

@mixin foundation-icon-block-sizes {
  @each $name, $value in $icon-sizes {
    .icon-#{$name} {
      @include foundation-icon-block($name);
    }
  }
}