@import "../mixins/forms";
@import "../mixins/utils";

.nx-radio-toggle {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	justify-content: stretch;

	> label {
		// Override a TWBS property for all <label>s
		margin-bottom: 0;
    flex: 0 0 auto;
    border-color: $radio-toggle-unselected-border-color;
    border-width: 0;
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    padding: $radio-toggle-btn-spacing;
    background-color: $radio-toggle-unselected-bg-color;

    &:not(:first-of-type) {
      margin-left: -2px;
      margin-right: -2px;
		}

    &:first-child {
      border-left-width: 1px;
    }

    &:last-child {
      border-right-width: 1px;
      margin-left: 0;
    }

		&:focus,
		&.nx-has-focus {
      outline: 0;

			.nx-radio-toggle-label {
        border-color: $radio-toggle-selected-border-color;
			}
		}

		&:first-of-type {
      border-top-left-radius: $radio-toggle-label-border-radius;
      border-bottom-left-radius: $radio-toggle-label-border-radius;
		}

		&:last-of-type {
      border-top-right-radius: $radio-toggle-label-border-radius;
      border-bottom-right-radius: $radio-toggle-label-border-radius;
    }
  }

	input[type="radio"] {
    @include minimize-checkbox;
    @include form-element-style-focus-shadow($radio-toggle-label-focus-color, null, $radio-toggle-label-focus-color, null, '+ .nx-radio-toggle-label');

    &:not([disabled]):focus {
      + .nx-radio-toggle-label {
        position: relative;
        z-index: 1;
      }
    }

    &:not([disabled]):hover {
      + .nx-radio-toggle-label {
        background-color: $radio-toggle-hover-bg-color;
        color: $radio-toggle-hover-text-color;
      }
    }

		&:checked {
			&:not([disabled]) {
				+ .nx-radio-toggle-label {
					background-color: $radio-toggle-selected-bg-color;
					border-color: $radio-toggle-selected-border-color;
          color: $radio-toggle-selected-text-color;
				}
			}
		}

		&[disabled] {
			+ .nx-radio-toggle-label {
				border-color: $radio-toggle-disabled-border-color;
				color: $radio-toggle-disabled-text-color;
				cursor: $cursor-disabled;
			}

			&:checked {
				+ .nx-radio-toggle-label {
					background-color: $radio-toggle-disabled-selected-bg-color;
					color: $radio-toggle-disabled-selected-text-color;
					cursor: $cursor-disabled;
				}
			}
		}
	}

	.nx-radio-toggle-label {
    display: inline-block;
		padding: $radio-toggle-padding-y $radio-toggle-padding-x;
		// background-color: $radio-toggle-unselected-bg-color;
		color: $radio-toggle-unselected-text-color;
		text-align: center;
    cursor: pointer;
    border-radius: $radio-toggle-border-radius;
  }

  // Full width segmented buttons
  &.nx-radio-toggle-full-width {
    > label {
      flex: 1;
    }

    .nx-radio-toggle-label {
      display: block;
    }
  }
}

.nx-radio-toggle-disabled {
  > label {
    background-color: $radio-toggle-disabled-unselected-bg-color;
    border-color: $radio-toggle-disabled-border-color;
  }
}

// TODO: This will be refactored later to correct naming convention
.nx-control-toggle-vertical {
	display: flex;
	flex-flow: column nowrap;
	justify-content: stretch;
	width: auto;

	> input[type="radio"] {
		@include minimize-checkbox;

	  &:checked {
      + label {
        background-color: $radio-toggle-selected-bg-color;
        border-color: $radio-toggle-selected-border-color;
        border-bottom-color: $radio-toggle-unselected-border-color;
        color: $radio-toggle-selected-text-color;
      }
	  }
	}

	> label {
	  display: block;
	  border: 1px solid $radio-toggle-unselected-border-color;
	  padding: $input-padding-y $input-padding-x;
	  font-size: $font-size-base;
	  line-height: 1;
	  width: 100%;
	  background-color: $radio-toggle-unselected-bg-color;
	  border-color: $radio-toggle-unselected-border-color;
	  color: $radio-toggle-unselected-text-color;
	  text-align: center;
	  cursor: pointer;
	  margin-bottom: 0;

	  &:focus {
      outline: 0;
	  }

	  &:first-of-type {
		border-top-left-radius: $radio-toggle-border-radius;
		border-top-right-radius: $radio-toggle-border-radius;
	  }

	  &:not(:first-of-type) {
		border-top-width: 0;
	  }

	  &:last-of-type {
      border-bottom-left-radius: $radio-toggle-border-radius;
      border-bottom-right-radius: $radio-toggle-border-radius;
	  }
	}

	&.has-focus {
	  > label {
      border-color: $radio-toggle-selected-border-color;
	  }
	}
}

.nx-dark .nx-radio-toggle {
  input[type="radio"] {
    @include form-element-style-focus-shadow($radio-toggle-dark-label-focus-color, null, $radio-toggle-dark-label-focus-color, null, '+ .nx-radio-toggle-label');
  }

  > label {
    background-color: $radio-toggle-dark-label-bg-color;
    border-color: $radio-toggle-dark-unselected-border-color;
  }

  input[type="radio"] {
    + .nx-radio-toggle-label {
      color: $radio-toggle-dark-label-unselected-text-color;
    }

    &[disabled] {
      + .nx-radio-toggle-label {
        border-color: $radio-toggle-disabled-border-color;
        color: $radio-toggle-disabled-text-color;
        cursor: $cursor-disabled;
      }

      &:checked {
        + .nx-radio-toggle-label {
          color: $radio-toggle-disabled-selected-text-color;
        }
      }
    }
  }

  input[type="radio"]:not([disabled]):hover + .nx-radio-toggle-label {
    background-color: $radio-toggle-dark-label-hover-bg-color;
    color: $radio-toggle-dark-label-unselected-text-color;
  }

  input[type="radio"]:checked:not([disabled]) + .nx-radio-toggle-label,
  input[type="radio"]:checked:not([disabled]):hover + .nx-radio-toggle-label {
    background-color: $radio-toggle-dark-selected-bg-color;
    border-color: $radio-toggle-dark-selected-border-color;
    color: $radio-toggle-dark-label-selected-text-color;
  }
}

.nx-dark .nx-radio-toggle-disabled {
  > label {
    background-color: $radio-toggle-dark-disabled-unselected-bg-color;
    border-color: $radio-toggle-disabled-border-color;
  }
}

// Turn off accessibility features when use not tabbing
[data-whatintent='mouse'] .nx-radio-toggle input[type=radio]:focus + .nx-radio-toggle-label {
  box-shadow: none;
}
