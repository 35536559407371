@import "../../theme/theme";
@import "../../mixins/button";

@if $global-nav-compatibility == false {
  .nx-panel {
    border: none;
    border-radius: $border-radius-base + 2px;
    margin-bottom: $panel-margin-bottom;
    background-color: $color-white;

    &.nx-open {
      .nx-panel-header-content .nx-panel-header-info .nx-icon {
        transform: rotate(90deg);
      }
    }

    hr.nx-panel-divider {
      border-top: 1px solid $color-nintex-grey-10;
      margin: 0 15px;
      display: $panel-display-dividers-visibiliity;
    }
  }

  .nx-panel-collapsible:not(.nx-panel-has-summary) {
    &.nx-has-collapsed {
      .nx-panel-header {
        border-bottom-left-radius: $border-radius-base + 2px;
        border-bottom-right-radius: $border-radius-base + 2px;
      }
    }

    &.nx-open.nx-panel-has-extensions {
      .nx-panel-header-content {
        margin-right: 10px;
      }
    }
  }

  .nx-panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $panel-header-background-color;
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
  }

  .nx-panel-body .table {
    thead th {
      background-color: $color-white;
      border-width: 1px;
      border-color: $panel-header-background-color;
    }
  }

  .nx-panel-heading {
    position: relative;
    margin: 0;
    bottom: -1px;
    color: $panel-heading-color;
  }

  .nx-panel-extension-button,
  .nx-panel-header-content {
    &:focus, &:active {
      outline: none;
    }
  }

  .nx-panel-collapsible .nx-panel-header-content {
    cursor: pointer;
  }

  .nx-panel-extension-button {
    margin: 0;
    position: relative;
    cursor: pointer;

    &.nx-active::after {
      content: ' ';
      position: absolute;
      bottom: -11px;
      left: 50%;
      margin-left: -8px;
      width: 0;
      height: 0;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent $panel-extension-wrapper-background-color transparent;
    }

    &.nx-active::before {
      content: ' ';
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -9px;
      width: 0;
      height: 0;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent $panel-extension-wrapper-border-color transparent;
    }
  }

  .nx-panel-lextension-wrapper {
    display: flex;
    align-items: center;
  }

  .nx-panel-extension-button-align-right:not(:last-child) {
    margin-right: 10px;
  }

  .nx-panel-extension-button-align-right:last-child {
    margin-right: 15px;
  }

  .nx-panel-extension-button-align-left {
    margin-left: 10px;
  }

  .nx-panel-header-content {
    @include button-style-focus-shadow($btn-primary-border-color);
    display: flex;
    align-items: center;
    padding: 0;
    flex-grow: 1;
    border: 1px solid transparent;
    border-radius: $border-radius-base;
    background-color: $panel-header-background-color;
    color: currentColor;

    &:focus {
      border-color: $btn-primary-border-color;
    }

    .nx-icon {
      transition: transform 0.3s;
    }

    .nx-btn-icon-wrapper {
      margin-right: 2px;
    }

    .nx-panel-header-info {
      display: flex;
      align-items: center;
      padding: $panel-header-vertical-padding $panel-padding;
    }
  }

  .nx-panel-collapsible .nx-panel-header-content {
    padding-left: 0;
  }
  .nx-panel-collapsible .nx-panel-header-info {
    padding-left: 9px;
  }

  .nx-panel-content,
  .nx-panel-extension {
    position: relative;
    padding: $panel-padding;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .nx-panel-extension-wrapper {
    margin: $panel-extension-wrapper-margins;
    // TODO: Remove nx-dark class from markup once Color Unification is complete
    background-color: $panel-extension-wrapper-background-color;
    border: $panel-extension-wrapper-border-width solid $panel-extension-wrapper-border-color;
    border-radius: $panel-extension-wrapper-border-radius;
    overflow: hidden;
  
    h1, h2, h3, h4, h5, h6,
    label,
    p {
      color: $panel-extension-wrapper-color;
    }
  }

  .nx-panel-extension .nx-btn-close {
    position: absolute;
    height: $panel-header-btn-close-height;
    width: $panel-header-btn-close-width;
    overflow: hidden;
    top: 10px;
    right: 10px;
    color: $panel-header-btn-close-color;
    @if $panel-header-btn-close-include-focus-shadow {
      @include element-style-focus-shadow($btn-primary-border);
      @include button-focus-style($btn-primary-focus-outline-color, null, $btn-secondary-focus-border-color);
    }

    .nx-icon {
      position: $panel-header-btn-close-icon-position;
      left: -8px;
      top: -8px;
      color: currentColor;
    }

    &:hover {
      background-color: $panel-header-btn-close-bg-color;
    }
  }

  .nx-panel-extension-title {
    margin-right: 20px;
  }

  .nx-panel-extension-footer-divider {
    border-top: 1px solid $color-nintex-grey-10;
    margin: 15px 0 15px 0;
  }

  .nx-panel-summary {
    padding: 10px 15px;
    border-top: $panel-summary-border-top;
  }

  // Turn off accessibility features when use not tabbing
  [data-whatintent='mouse'] .nx-panel-header-content {
    box-shadow: none;
    border-color: $panel-header-background-color;
  }
}

// This is to support the deprecated feature
// We will remove this in the future
@if $global-nav-compatibility == true {
  .nx-panel {
    width: 100%;
    border-radius: $border-radius-base;
    margin-top: 25px;
    background-color: $color-white;
    border: 1px solid #cbcbcb;

    > header,
    .nx-panel-header {
      padding: 0 20px;
      position: relative;
      border-bottom: 1px solid $color-gray-light;

      &.nx-border-bottom {
        border-bottom: 1px solid $color-steel-gray;
      }

      h1, h2, h3 {
        width: auto;
        display: inline-block;
      }
      h3 {
        line-height: 27px;
        margin: 14px 0;
        color: inherit;
      }
    }
    .nx-btn-collapse {
      display: inline-block;
      cursor: pointer;
      fill: currentColor;
      transition: transform 0.3s;
      transform: rotate(90deg);
      background-size: 20px;
      height: 36px;
      width: 36px;
      margin-bottom: -12px;
      margin-left: -13px;
      text-decoration: none;
      border: 0;
      outline: none;
      vertical-align: inherit;
    }

    &.nx-collapsed,
    &.nx-collapsing {
      .nx-btn-collapse {
        transform: rotate(0deg);
      }

      > header, .nx-panel-header {
          border-bottom: none;
      }
    }

    .nx-panel-body, .nx-panel-summary {
      background-color: $panel-bg-color;
      font-family: $panel-body-font-family;
      border-bottom-left-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;

      &.nx-panel-body-flush {
          padding: 0;
      }
    }

    .nx-panel-summary {
        padding: 12px 24px;
        border-top: 1px solid $color-gray-light;
    }

    .nx-panel-content {
      padding: 24px;
    }
  }

  .nx-panel-actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    .nx-btn-action {

      *[class*="icon"] {
        @include icon-size($panel-action-icon-size, $panel-action-icon-size);
      }

      .icon-edit {
        @include svg-utility-orange-add;
      }

      .icon-filter {
        @include svg-utility-orange-filter;
      }

      .icon-add {
        @include svg-utility-orange-add-alt;
      }
    }

    .nx-btn-icon {
      position: relative;
      padding-left: $panel-action-icon-size;

      *[class*="icon"] {
        position: absolute;
        left: 0;
        top: 1px;
      }
    }

  }

  .nx-panel-extendable {
    .nx-panel-extension {
      overflow: hidden;
      height: 0;
      transition: height 0.3s ease, min-height 0.3s ease;

      &.nx-top {
        border-top-left-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;
      }

      &.nx-bottom {
        border-bottom-left-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
      }
    }

    &.nx-extending,
    &.nx-retracting {
      overflow: hidden;
    }

    &.nx-extended {
      overflow: visible;

      .nx-panel-extension {
        height: auto;
      }

      .nx-panel-body {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .nx-panel-extension-source {
    position: relative;
    margin: 13px 16px 13px 0;

    .nx-panel-extension-hook {
      display: none;
      background-color: $color-worn-black;
      position: absolute;
      z-index: 2;
      transform: rotate(45deg);
    }

    &.nx-active {
      .nx-panel-extension-hook {
        display: block;
        top: calc(100% + 4px);
        left: calc(50% - 8px);
        height: 16px;
        width: 16px;
      }
    }
  }

  .nx-panel-collapsible {
    header {
      cursor: pointer;
      &:hover {
        color: $color-brand-primary;
      }
    }
    .nx-panel-body, .nx-panel-summary {
      transition: height 0.3s ease;
    }

    &.nx-collapsing,
    &.nx-expanding {
      .nx-panel-body, .nx-panel-summary {
        overflow: hidden;
        border-top: 1px solid transparent;
      }
    }

    &.nx-panel-with-summary {
      &.nx-collapsing,
      &.nx-expanding {
        .nx-panel-header:after {
          content: " ";
          position: absolute;
          height: 1px;
          width: 100%;
          bottom: -1px;
          left: 0;
          background-color: $color-gray-light;
        }
      }
    }

    &.nx-collapsed {
      .nx-panel-body {
        overflow: hidden;
        border: 0;
        height: 0;
      }
    }
  }

  .nx-flex-one {
    > .nx-panel {
      flex: 1 0 auto;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}


.nx-panel-table {
  border-radius: $border-radius-base;
  background-color: $table-bg-color;
  position: relative;
  overflow: hidden;
}

.nx-panel {
  .nx-panel-body {
    padding: 0;
    width: 100%;

    p {
      color: inherit
    }
  }

  .nx-alert {
    border-radius: 0;
    margin: 0;
    padding-left: 41px;

    .nx-icon {
      left: 5px;
    }
  }

  .table {
    margin-bottom: 0;

    td, th {
      padding-left: 0;

      &:first-child {
        padding-left: 15px;
      }

      &:last-child:not(.nx-table-row-action) {
        padding-right: 15px;
      }
    }

    th {
      vertical-align: top;
    }
  }
}

.nx-panel-actions {
  text-align: right;
  border-top: 1px solid $color-gray-lighter;
  padding: $panel-padding;

  .btn {
    margin-left: 10px;
  }
}
