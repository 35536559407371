.nx-character-counter {
  margin-top: 2px;
  text-align: right;
  color: $color-taupe-gray;
  font-size: $font-size-sm;
}

.nx-character-counter-warning {
  color: $color-brand-red;
}
