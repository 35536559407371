.nx-form-panel {
  .nx-plain-text {
    display: block;
    width: 100%;
    padding: $padding-base-vertical 0 ($padding-base-vertical - 1);
    color: $color-form-panel-plain-text;
  }
}

.nx-panel-body {
  &.nx-form-panel {
    padding: 30px 20px;
    border-radius: $border-radius-base;
  }
}

.nx-panel-extension-source {
  svg.nx-form-panel-hook {
    fill: $color-worn-black;
  }
}

.nx-panel-extension {
  .nx-form-panel {
    position: relative;
    padding: 30px 20px;
    border-radius: $border-radius-base $border-radius-base 0 0;
    display: none;

    &.nx-show {
      display: block;
    }

    + .nx-table-panel {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    h2, h3, h4 {
      margin-top: 0;
      margin-bottom: 1em;
    }

    .nx-btn-close {
      position: absolute;
      z-index: 2;
      right: 10px;
      top: 16px;
      cursor: pointer;
      @include svg-utility-white-close;
      @include svg-icon-size(36px);
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
