.nx-has-footer {
  margin-bottom: 25px;
}

.nx-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 25px;
  padding: 5px 0;
  width: 100%;
  font-size: $font-size-sm;
  text-align: center;
  background: $color-gray-lighter;
}
