@import "../theme/theme";

.nx-dashboard {
  header {
    h1 {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $color-gray-light;
    }

    .panel-actions {
      position: absolute;
      top: 10px;
      right: 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      > button {
        margin-left: 15px;
      }
    }
  }
}