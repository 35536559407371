@mixin control-error-message {
  // Error messages

  .nx-error-message {
    display: none;
  }

  .nx-has-error {
    .nx-error-message {
      width: 100%;
      display: inline-block;
      padding: 5px 13px;
      border-bottom-left-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;
    }
  }
}

@mixin control-error-focus {
  &:focus,
  &.nx-focus {
    border: 1px solid $error-control-border-color;

    + .nx-error-message {
      background-color: $error-message-focus-bg-color;
      color: $error-message-focus-text-color;
    }
  }
}

@mixin control-error-icon($padding-right: 28px) {
  padding-right: $padding-right;
  @include svg-utility-error-alt;
  background-position: right top;
  @include icon-bg-size(32px 32px);
}
