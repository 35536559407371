@mixin status-spinner($count: 6, $element: div) {
  > #{$element} {

    @if $element == "span" {
      display: inline-block;
    }

    height: 100%;
    background-color: currentColor;
    animation: nx-spinner-animation-stretch 1.2s infinite ease alternate;
    animation-fill-mode: both;

    @for $i from 0 through ($count - 1) {
      &:nth-child(#{$count}n + #{$count - $i}) {
        animation-delay: (0s + (($count - $i)/ 10));
      }
    }
  }
}

@mixin status-spinner-size($width: $status-spinner-slice-width, $height: 100%, $count: 3, $element: div) {
  width: ($count * $width) + (($count - 1) * $width);
  height: $height;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  > #{$element} {
    flex: 0 0 $width;
  }
}

@keyframes nx-spinner-animation-stretch {
  0% {
    transform: scaleY(0.3);
    opacity: 0.2;
  }
  20% {
    transform: scaleY(0.65);
    opacity: 0.6;
  }
  50% {
    transform: scaleY(1);
    opacity: 1;
  }
  80% {
    transform: scaleY(0.6);
    opacity: 0.5;
  }
  100% {
    transform: scaleY(0);
    opacity: 0;
  }
}