@import "../theme/theme";
.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.nx-icon {
  width: $icon-svg-size;
  height: $icon-svg-size;
  fill: currentColor;
  vertical-align: top;
  pointer-events: none;
}

.react-css-collapse-transition {
  transition: height 250ms ease-in-out;
}
