@import "../mixins/forms";
@import "../mixins/utils";

.nx-checkbox-toggle {
    position: relative;
    display: inline-block;

    > label {
        outline: 0;
        // Override a TWBS property for all <label>s
        margin-bottom: 0;
    }

    input[type=checkbox] {
        @include minimize-checkbox;
        @include form-element-style-focus-shadow($checkbox-toggle-focus-outline-color, null, null, null, '+ .nx-checkbox-toggle-container .nx-checkbox-toggle-groove');

        &:checked {
            ~ .nx-checkbox-toggle-knob,
            + .nx-checkbox-toggle-container .nx-checkbox-toggle-knob {
                transform: translate(($checkbox-toggle-width - $checkbox-toggle-knob-width - ($checkbox-toggle-padding * 2)), 0);
            }

            + .nx-checkbox-toggle-container {
                .nx-icon {
                    display: block;
                }

                .nx-checkbox-toggle-off {
                    display: none;
                }

                .nx-checkbox-toggle-on {
                    display: inline-block;
                }
            }

            &:not([disabled]) {
                + .nx-checkbox-toggle-container {
                    .nx-checkbox-toggle-groove {
                        background-color: $checkbox-toggle-selected-bg-color;
                    }
                }
            }

            &:focus {
                + .nx-checkbox-toggle-container {
                    .nx-checkbox-toggle-groove {
                        background-color: $checkbox-toggle-focus-bg-color;
                    }
                }
            }
        }

        &[disabled] {
            + .nx-checkbox-toggle-container {
                cursor: $cursor-disabled;

                .nx-checkbox-toggle-groove {
                    background-color: $checkbox-toggle-disabled-bg-color;
                    cursor: $cursor-disabled;
                }

                .nx-checkbox-toggle-off,
                .nx-checkbox-toggle-on {
                    color: $checkbox-toggle-disabled-text-color;
                    cursor: $cursor-disabled;
                }
            }

            ~ .nx-checkbox-toggle-knob {
                cursor: $cursor-disabled;
            }
        }
    }

    .nx-checkbox-toggle-container {
        .nx-icon {
            display: none;
            width: 36px;
            height: 36px;
            top: -7px;
            left: -2px;
            position: absolute;
            z-index: 2;
            fill: $color-white;
        }
    }

    .nx-checkbox-toggle-groove {
        display: block;
        position: relative;
        width: $checkbox-toggle-width;
        height: $checkbox-toggle-height;
        background-color: $checkbox-toggle-unselected-bg-color;
        border-radius: 12px;

        .dark & {
            background-color: $color-gray-medium;
        }
    }

    .nx-checkbox-toggle-knob {
        position: absolute;
        left: $checkbox-toggle-padding;
        top: $checkbox-toggle-padding;
        z-index: 3;
        height: $checkbox-toggle-knob-height;
        width: $checkbox-toggle-knob-width;
        border: 1px solid $checkbox-toggle-knob-color;
        border-radius: 50%;
        background-color: $checkbox-toggle-knob-color;
        transition: transform 0.15s ease;
    }

    .nx-checkbox-toggle-on, .nx-checkbox-toggle-off {
        text-align: left;
        margin-left: 1ex;
        font-size: $checkbox-toggle-font-size;
        color: $checkbox-toggle-unselected-text-color;
    }

    .nx-checkbox-toggle-on {
        display: none;
    }

    .nx-checkbox-toggle-off {
        display: inline-block;
    }

    &.nx-checkbox-toggle-right,
    &.nx-checkbox-toggle-left {
        .nx-checkbox-toggle-container {
            display: flex;
            align-items: center;
        }
    }

    &.nx-checkbox-toggle-right {
        .nx-checkbox-toggle-off,
        .nx-checkbox-toggle-on {
            margin-right: 1ex;
            margin-left: initial;
        }
    }

    &.nx-checkbox-toggle-small {
        .nx-checkbox-toggle-groove {
            width: $checkbox-toggle-width-small;
            height: $checkbox-toggle-height-small;
            border-radius: 12px;
        }

        .nx-checkbox-toggle-knob {
            height: $checkbox-toggle-knob-height-small;
            width: $checkbox-toggle-knob-width-small;
            border-radius: 50%;
        }

        .nx-icon {
            top: -9px;
            left: -5px;
            transform: scale(0.85)
        }

        input[type=checkbox] {
            &:checked {
                ~ .nx-checkbox-toggle-knob,
                + .nx-checkbox-toggle-container .nx-checkbox-toggle-knob {
                    transform: translate(($checkbox-toggle-width-small - $checkbox-toggle-knob-width-small - ($checkbox-toggle-padding * 2)), 0);
                }
            }
        }
    }
}

// Turn off accessibility features when use not tabbing
[data-whatintent='mouse'] .nx-checkbox-toggle input[type=checkbox]:focus + .nx-checkbox-toggle-container .nx-checkbox-toggle-groove {
    box-shadow: none;
}
