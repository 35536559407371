.nx-file-picker {
  color: #CBCBCB;
  background: #000;
  border-style: dashed;
  border-width: 2px;
  border-radius: 4px; }

.nx-file-picker-button .btn {
  padding: 0.2rem;
  padding-right: 0.5rem; }
