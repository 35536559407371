.nx-item-picker {
    outline: none;
    &.has-focus,
    &:focus {
        border-color: $color-brand-orange;
    }
    .nx-input {
        input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .nx-item-picker-loader {
        overflow: hidden;
        border: 1px solid $color-gray-light;
        border-radius: $border-radius-base;
        background-color: $color-white;

        .nx-centered {
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            .nx-spinner-container {
                justify-content: center;
            }
        }
        .nx-item-picker-message {
            overflow: hidden;
            text-align: center;
            padding-bottom: 15px;
        }
    }

    ul.nx-item-picker-list {
        border-left: 1px solid $color-gray-light;
        border-right: 1px solid $color-gray-light;
        margin: 0;
        padding: 0;
        list-style-type: none;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: $color-white;
        li {
            display: block;
            line-height: 100%;
            color: $color-gray-dark;
            width: 100%;
            padding: 0 10px;
        }
    }

    .nx-item-picker-footer {
        color: $color-brand-orange;
        background-color: $color-silverstone;
        width: 100%;
        border: 1px solid $color-gray-light;
        border-top: 2px solid $color-gray-light;
        border-bottom-left-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 10px;

        .nx-item-picker-actions {
            display: flex;
            align-self: flex-end;
            flex-flow: row nowrap;
            margin-left: auto;

            > .btn-primary {
                align-self: flex-end;
            }

            > .btn-secondary {
                align-self: flex-end;
                margin-right: 10px;
            }
        }
    }
}