.nx-tag {
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent; }
  .nx-tag:focus {
    outline: none; }
  .nx-tag .nx-tag-content {
    border-top-left-radius: 10px;
    border-top-right-radius: 4px;
    margin-left: -2px;
    padding: 4px 10px 4px 10px;
    transition: all 200ms ease-in-out;
    overflow: hidden; }
    .nx-tag .nx-tag-content::before {
      content: '';
      position: absolute;
      top: 2px;
      bottom: 0;
      left: -6px;
      width: 12px;
      border-top-left-radius: 4px;
      transform: skew(-30deg);
      z-index: -1;
      transition: all 200ms ease-in-out; }

.nx-tag.nx-tag-default {
  color: #FFF; }
  .nx-tag.nx-tag-default .nx-tag-content {
    background: #5B5B5B; }
    .nx-tag.nx-tag-default .nx-tag-content::before {
      background: #5B5B5B; }

.nx-tag.nx-tag-info {
  color: #FFF; }
  .nx-tag.nx-tag-info .nx-tag-content {
    background: #2070D0; }
    .nx-tag.nx-tag-info .nx-tag-content::before {
      background: #2070D0; }

.nx-tag.nx-tag-warning {
  color: #FFF; }
  .nx-tag.nx-tag-warning .nx-tag-content {
    background: #E06E18; }
    .nx-tag.nx-tag-warning .nx-tag-content::before {
      background: #E06E18; }

.nx-tag.nx-tag-error {
  color: #FFF; }
  .nx-tag.nx-tag-error .nx-tag-content {
    background: #E10D11; }
    .nx-tag.nx-tag-error .nx-tag-content::before {
      background: #E10D11; }
