// Base Colors
// ===========

// Grays
// -----
$color-black: #000;
$color-worn-black: #232323;
$color-gray-darker: #404040;
$color-gray-dark: #5B5B5B;
$color-gray-half: #808080;
$color-gray-medium: #949494;
$color-gray-light: #CBCBCB;
$color-gray-lighter: #E6E6E6;
$color-white-dull: #FAFAFA;
$color-white: #FFF;
$color-slate: #707070;

$color-charcoal: #323232;
$color-dark-ash: #404040;
$color-taupe-gray: #5B5B5B;
$color-zinc-gray: #707070;
$color-steel-gray: #999;
$color-silverstone: #F1F1F1;
$color-talking-heads: #FAFAFA;
$color-nearly-white: #FAFAFA;

// Color
// -----
$color-monza-red: #E10D11;
$color-nintex-orange: #E06E18;
$color-burnt-orange: #E96025;
$color-dark-honeycomb: #FFE4CC;
$color-goldenrod-light: #FFCB00;
$color-peach-sorbet: #FFF2E6;
$color-medium-green: #008000;
$color-salem-green: #09894E;
$color-science-blue: #006FD1;
$color-kashmir-blue: #54698D;
$color-mariner-blue: #2070D0;
$color-sharepoint-blue: #4a90e2;
$color-powder-blue: #d2e2f6;

// ### Tag colors
$color-pattens-blue: #D6EBF5;
$color-hawkes-blue: #D6E0F5;
$color-fog: #E0D6F5;
$color-tara: #D6F5D6;
$color-provincial-pink: #F5E0D6;
$color-hint-of-yellow: #F5F5D6;
$color-snuff: #F5D6F5;
$color-clear-day: #D6F5EB;

// ### Default brand colors
$color-brand-orange: $color-nintex-orange !default;
$color-brand-yellow: $color-goldenrod-light !default;
$color-brand-blue: $color-mariner-blue !default;
$color-brand-green: $color-salem-green !default;
$color-brand-red: $color-monza-red !default;
$color-brand-primary: $color-brand-orange !default;

// ### Ancillary colors
$color-keyline: $color-gray-light !default;

// Nintex-Blue
// ===========
$color-nintex-orange-2: #F56900;

// Blue
// -----
$color-nintex-blue-5: #F0F7FF;
$color-nintex-blue-10: #D6EBFF;
$color-nintex-blue-20: #A3D1FF;
$color-nintex-blue-outline-overlay: transparentize($color-nintex-blue-20, .50);
$color-nintex-blue-selected-overlay: transparentize($color-nintex-blue-20, .75);
$color-nintex-blue-hover-overlay: transparentize($color-nintex-blue-20, .88);
$color-nintex-blue-30: #70B8FF;
$color-nintex-blue-40: #3D9EFF;
$color-nintex-blue-50: #0A85FF;
$color-nintex-blue-60: #006BD6;
$color-nintex-blue-70: #0052A3;
$color-nintex-blue-80: #003870;
$color-nintex-blue-90: #001F3D;
$color-nintex-blue: $color-nintex-blue-60;

// Grey
// -----
$color-nintex-grey-2: #FAFAFA;
$color-nintex-grey-5: #EFF0F0;
$color-nintex-grey-10: #E2E3E4;
$color-nintex-grey-20: #C4C7CA;
$color-nintex-grey-30: #A7ABAF;
$color-nintex-grey-40: #898F94;
$color-nintex-grey-50: #6D7379;
$color-nintex-grey-60: #575C61;
$color-nintex-grey-70: #414548;
$color-nintex-grey-80: #2C2E30;
$color-nintex-grey-90: #161718;
$color-nintex-grey: $color-nintex-grey-60;

// System colors
// -------------
// Green
$color-nintex-green-40: #00D257;
$color-nintex-green-50: #008738;
$color-nintex-green-60: #006C2D;
$color-nintex-green: $color-nintex-green-50;

// Red
$color-nintex-red-40: #FF1F1F;
$color-nintex-red-50: #E60000;
$color-nintex-red-60: #B80000;
$color-nintex-red: $color-nintex-red-50;

// Yellow
$color-nintex-yellow-40: #FFD948;
$color-nintex-yellow-50: #FFCF1A;
$color-nintex-yellow-60: #E1B200;
$color-nintex-yellow: $color-nintex-yellow-50;

// Deprecated colors
// -----------------
$color-torch-red: #F03;
$color-warm-vermilion: #D74D32;
$color-bloody-mary: #F50057;
$color-grenadier-red: #C74000;
$color-deep-carmine-pink: #F63535;
$color-legacy-orange: #FF7900;
$color-salted-caramel: #C65D00;
$color-hipster-peppermint: #48BC97;
$color-mountain-meadow: #18A377;
$color-robins-egg-blue: #02D1C0;
$color-cool-azure: #4A90E2;
$color-tufts-blue: #347CD2;

