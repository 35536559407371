@import "../mixins/alert";

.nx-alert {
  position: relative;
  border-radius: 4px;
  border-width: 0;
  padding: 12px 20px 12px 36px;
  background-position: 0 4px;
  background-size: 36px 36px;

  &.nx-alert-flush {
    border-radius: 0;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &.nx-alert-closable {
    padding-right: 36px;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &.alert-dismissible {
    .close {
      font-size: 26px;
      line-height: 18px;
      padding: .75rem;
      &:hover {
        color: inherit;
      }
    }
  }

  &.alert-info {
    @include alert-color($alert-information-bg-color, $alert-information-text-color);
  }

  &.alert-success {
    @include alert-color($alert-success-bg-color, $alert-success-text-color);
  }

  &.alert-danger {
    @include alert-color($alert-danger-bg-color, $alert-danger-text-color);
  }

  &.alert-warning {
    @include alert-color($alert-warning-bg-color, $alert-warning-text-color);
  }

  // The color property defined here can be used in the icon fill
  .nx-icon {
    fill: currentColor;
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    top: 3px;
  }

  .nx-btn-close {
    cursor: pointer;
    outline: none;
    position: absolute;
    border: 0;
    background: transparent;
    right: 0;
    top: 1px;
    color: currentColor;

    .nx-icon {
      position: static;
      transition: all .2s ease-in-out;
    }

    &:hover .nx-icon {
      transform: scale(1.5);
    }
  }
}


.nx-alert-list {
  display: flex;
  flex-flow: column nowrap;

  .nx-alert:not(:last-child) {
    margin-bottom: 5px;
  }
}

.nx-alert-list.nx-alert-list--compact {
  .nx-alert:not(:last-child) {
    margin-bottom: 1px;
  }
}
