$dark-theme-text-color: $color-white !default;
$dark-theme-error-message-text-color: $color-white !default;
$dark-theme-error-message-bg-color: $color-brand-red !default;
$dark-theme-bg-color: $color-worn-black;

@import "../mixins/theme/dark";

.nx-dark {
  @include dark-theme-background;

  // TODO doesn't belong here
  a.btn {
    transition: none;
  }

  a.secondary-link, a.secondary-link:visited {
    color: darken($color-white, 20%);
  }

  h1, h2, h3, h4, h5, h6,
  label,
  p {
    color: $dark-theme-text-color;
  }

  .nx-panel-heading {
    color: $panel-heading-color;
  }

  .nx-btn-default,
  .nx-btn-secondary {
    @include dark-theme-button-style-default;
  }

  &.nx-global-header {
    .nx-logo {
      background-image: url("/images/nintex-logo-flat-white-orange.svg");
    }
  }

  .nx-nav-level-0 > li:not(.selected) > a:not(.nx-btn-action) {
    color: $color-gray-light;

    &:hover {
      color: $color-white;
    }
  }

  @include control-error-message;

  .nx-has-error {
    .nx-error-message{
      color: $dark-theme-error-message-text-color;
      background-color: $dark-theme-error-message-bg-color;
    }
  }

}
