.nx-attachments {
    display: flex;
    align-items: center;

    &.nx-vertical-align-top {
        align-items: flex-start;
    }

    &.nx-vertical-align-middle {
        align-items: center;
    }

    &.nx-vertical-align-bottom {
        align-items: flex-end;
    }

    .nx-attachments__item {
        margin-right: 0.5rem;

        &:first-child {
            flex-grow: 1;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
