@import "../mixins/toolbox";

.nx-toolbox {
  position: absolute;
  left: -$toolbox-width;
  top: 0;
  bottom: 0;
  width: $toolbox-width;
  z-index: $toolbox-z-index;
  transition: all 0.5s ease;
  background-color: $color-gray-light;

  &.nx-with-nav {
    top: $nx-header-height + 1px;
  }

  .nx-handle {
    float: right;
    margin-right: -$toolbox-handle-width;
    background-color: $color-gray-light;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    width: $toolbox-handle-width;
    height: $toolbox-handle-height;
    position: relative;
  }

  .nx-icon-handle {
    position: absolute;
    top: -2px;
    left: -5px;
    cursor: pointer;
    transition: all 0.3s ease;
    transform: rotate(-90deg);
    @include svg-utility-gray-down;
    @include foundation-icon-block(32px);
  }

  &.nx-open {
    left: 0;

    .nx-icon-handle {
      transform: rotate(90deg);
      left: -9px;
    }
  }

  .nx-content {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: $toolbox-width;
    padding: $toolbox-content-padding;
    padding-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 4px solid transparent;

    &.nx-content-with-search {
      top: 46px + 15px;
      padding-top: 0;
    }

    & > * {
      width: $toolbox-width - 45px;
    }

    .nx-toolbox-group {
      border-radius: $toolbox-border-radius;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      background-color: #f2f2f2;
      padding: 10px;
      margin-bottom: 10px;
      overflow: hidden;

      .nx-icon-toggle {
        transition: all 0.2s ease;
        cursor: pointer;
        @include foundation-icon-block(32px);
        position: absolute;
        left: -10px;
        top: -8px;
        transform: rotate(0deg);
        opacity: 0.8;
      }

      > h4 {
        padding: 0 5px 0 16px;
        margin: 0;
        position: relative;
        font-size: $font-size-h5;
        font-family: $headings-font-family;
        cursor: pointer;
        line-height: 1rem;

        &:hover .nx-icon-toggle {
          opacity: 1;
        }
      }

      .nx-toolbox-items {
        display: none;
      }

      &.nx-open {
        height: auto;

        .nx-icon-toggle {
          transform: rotate(90deg);
        }

        .nx-toolbox-items {
          display: block;
        }
      }
    }

    .nx-toolbox-items {
      padding: 0;
      margin: 10px 0 0;
      list-style: none;
      overflow: hidden;
    }
  }
}

.nx-toolbox-search {
  padding: 0 25px 0 21px;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  color: $color-taupe-gray;

  &.nx-toolbox-search-with-spacing {
    margin: 15px 0;
  }

  .form-control {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }

  .nx-icon {
    width: $icon-svg-size;
    height: $icon-svg-size;
    color: currentColor;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .nx-icon-search {
    position: absolute;
    left: 20px;
    top: -2px;
  }

  .nx-btn-clear {
    position: absolute;
    right: 22px;
    top: -2px;
  }
}

.nx-toolbox-content {
  width: 100%;
  padding: 0 25px 0 21px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

.nx-toolbox .nx-toolbox-group:last-child {
  margin-bottom: 0;
}

.nx-toolbox-group {
  background-color: $color-silverstone;
  padding: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: $border-radius-base;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  width: 100%;
}

.nx-toolbox-item {
  @include nx-toolbox-item;
  @include nx-toolbox-item-gripper;
  width: 100%;
  display: block;
  user-select: none;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 2px;
  }

  &:hover {
    @include nx-toolbox-item-focus($dragging: false);
  }
}

.nx-toolbox-item-icon {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $color-kashmir-blue;
  width: 32px;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .nx-icon {
    width: 28px;
    height: 28px;
    color: $color-white;
  }
}

.nx-toolbox-item-title {
  display: inline-block;
  @include nx-toolbox-item-title;
}

.nx-toolbox-item-title b, .nx-toolbox-item-meta b {
  background: rgba($color-mariner-blue, 0.15);
}

.nx-toolbox-item-with-meta {
  margin-bottom: 10px;
  @include nx-toolbox-item;

  .nx-toolbox-item {
    margin-bottom: 0;
    box-shadow: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .nx-toolbox-item-title {
    border-bottom-right-radius: 0;
  }
}

.nx-toolbox-item-meta {
  background: $color-silverstone;
  border-bottom-right-radius: $border-radius-base;
  border-bottom-left-radius: $border-radius-base;
  font-style: italic;
  font-size: $font-size-sm;
  padding: 3px 5px;
}

.nx-toolbox .nx-content > .nx-toolbox-items {
  margin-top: 0;
}

.nx-toolbox-item-dragging {
  list-style-type: none;
  transform: rotate($toolbox-item-dragging-rotation);
  z-index: $toolbox-item-dragging-z-index;
}
