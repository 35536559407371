.nx-select {
  position: relative;
  box-sizing: border-box;
  transition: none !important; }
  .nx-select.nx-select--is-disabled {
    pointer-events: auto; }
    .nx-select.nx-select--is-disabled .nx-select__control.nx-select__control--is-disabled {
      cursor: not-allowed;
      background-color: #E6E6E6;
      border-color: #E6E6E6; }
      .nx-select.nx-select--is-disabled .nx-select__control.nx-select__control--is-disabled .nx-select__single-value.nx-select__single-value--is-disabled {
        color: #949494; }
    .nx-select.nx-select--is-disabled .nx-select__control .nx-select__multi-value.nx-select__multi-value--is-disabled {
      background-color: #E6E6E6;
      border-color: #949494;
      color: #949494; }
      .nx-select.nx-select--is-disabled .nx-select__control .nx-select__multi-value.nx-select__multi-value--is-disabled .nx-select__multi-value__label {
        color: #949494;
        cursor: default;
        padding-right: 6px; }
      .nx-select.nx-select--is-disabled .nx-select__control .nx-select__multi-value.nx-select__multi-value--is-disabled .nx-select__multi-value__remove {
        display: none; }
  .nx-select .nx-select__control {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 1.96875rem;
    padding: 1px;
    outline: 0 !important;
    position: relative;
    background-color: #FFF;
    border-color: #CBCBCB;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    box-shadow: none;
    cursor: default;
    transition: border-color 200ms ease-in-out; }
    .nx-select .nx-select__control--is-focused {
      border-color: #E06E18; }
      .nx-select .nx-select__control--is-focused.nx-select__control:hover {
        border-color: #E06E18; }
  .nx-select.nx-select-has-message .nx-select__control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .nx-select.nx-select-has-highlight .nx-select__control {
    padding: 0;
    border-width: 2px; }
  .nx-select.nx-select-has-highlight .nx-select__menu {
    border-width: 2px; }
  .nx-select.nx-select-has-highlight .nx-select__option {
    padding: 8px 11px; }
  .nx-select.nx-select-has-highlight.nx-select--is-info .nx-select__control {
    border-color: #2070D0; }
    .nx-select.nx-select-has-highlight.nx-select--is-info .nx-select__control--is-focused {
      border-color: #2070D0; }
      .nx-select.nx-select-has-highlight.nx-select--is-info .nx-select__control--is-focused.nx-select__control:hover {
        border-color: #2070D0; }
  .nx-select.nx-select-has-highlight.nx-select--is-info .nx-select__menu {
    border-color: #2070D0; }
  .nx-select.nx-select-has-highlight.nx-select--is-warning .nx-select__control {
    border-color: #FFCB00; }
    .nx-select.nx-select-has-highlight.nx-select--is-warning .nx-select__control--is-focused {
      border-color: #FFCB00; }
      .nx-select.nx-select-has-highlight.nx-select--is-warning .nx-select__control--is-focused.nx-select__control:hover {
        border-color: #FFCB00; }
  .nx-select.nx-select-has-highlight.nx-select--is-warning .nx-select__menu {
    border-color: #FFCB00; }
  .nx-select.nx-select-has-highlight.nx-select-has-error .nx-select__control {
    border-color: #E10D11; }
    .nx-select.nx-select-has-highlight.nx-select-has-error .nx-select__control--is-focused {
      border-color: #E10D11; }
      .nx-select.nx-select-has-highlight.nx-select-has-error .nx-select__control--is-focused.nx-select__control:hover {
        border-color: #E10D11; }
  .nx-select.nx-select-has-highlight.nx-select-has-error .nx-select__menu {
    border-color: #E10D11; }
  .nx-select.nx-select-has-error .nx-select__control {
    border-color: #E10D11; }
    .nx-select.nx-select-has-error .nx-select__control--is-focused {
      border-color: #E10D11; }
      .nx-select.nx-select-has-error .nx-select__control--is-focused.nx-select__control:hover {
        border-color: #E10D11; }
  .nx-select.nx-select-has-error .nx-select__menu {
    border-color: #E10D11; }
  .nx-select.nx-select-has-warning .nx-select__control {
    border-color: #FFCB00; }
    .nx-select.nx-select-has-warning .nx-select__control--is-focused {
      border-color: #FFCB00; }
      .nx-select.nx-select-has-warning .nx-select__control--is-focused.nx-select__control:hover {
        border-color: #FFCB00; }
  .nx-select.nx-select-has-warning .nx-select__menu {
    border-color: #FFCB00; }
  .nx-select.nx-select-has-error .nx-select__control {
    border-color: #E10D11; }
  .nx-select.nx-select-has-warning .nx-select__control {
    border-color: #FFCB00; }
  .nx-select.nx-select-has-warning .nx-select__multi-value--invalid .nx-select__multi-value {
    border-color: #FFCB00; }
  .nx-select .nx-select__value-container {
    -webkit-overflow-scrolling: touch;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 4px 0.75rem;
    position: relative;
    line-height: 1.25; }
  .nx-select .nx-select__value-container--is-multi.nx-select__value-container--has-value .nx-pill:not(:first-child) {
    margin: 0 3px; }
  .nx-select .nx-select__single-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: #5B5B5B;
    max-width: calc(100% -8px);
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-overflow: ellipsis;
    white-space: nowrap; }
  .nx-select .nx-select__single-value__label {
    flex: 1 1 auto; }
  .nx-select .nx-select__single-value__prefix {
    flex: 0 0 auto;
    margin: -8px;
    padding-right: 0.75rem; }
  .nx-select .nx-select__multi-value {
    border-radius: 2px;
    background: #FFF;
    color: #5B5B5B;
    border: 1px solid #949494;
    display: flex;
    min-width: 0;
    margin: 0 2px 0 0; }
    .nx-select .nx-select__multi-value:hover {
      background-color: #FFF2E6; }
  .nx-select .nx-select__multi-value--invalid .nx-select__multi-value {
    border: 1px solid #E10D11;
    background-color: #FFF2E6; }
  .nx-select .nx-select__multi-value--is-active .nx-select__multi-value {
    border-color: #2070D0;
    box-shadow: 0 0 0 1px #2070D0; }
  .nx-select .nx-select__multi-value--for-pill .nx-select__multi-value {
    border: none;
    margin: 0; }
  .nx-select .nx-select__multi-value--for-pill .nx-select__multi-value__label {
    padding: 0; }
  .nx-select .nx-select__multi-value--for-pill .nx-pill:hover .nx-pill-content,
  .nx-select .nx-select__multi-value--for-pill .nx-pill:hover .nx-pill-close {
    background-color: #FFF2E6; }
  .nx-select .nx-select__multi-value--for-pill .nx-pill:hover .nx-pill-close:hover {
    background-color: #FFE4CC; }
  .nx-select .nx-select__multi-value--for-pill .nx-disabled .nx-pill:hover .nx-pill-content,
  .nx-select .nx-select__multi-value--for-pill .nx-disabled .nx-pill:hover .nx-pill-close {
    background-color: #FFF; }
  .nx-select .nx-select__multi-value--for-pill .nx-disabled .nx-pill:hover .nx-pill-close:hover {
    background-color: #E6E6E6; }
  .nx-select .nx-select__multi-value__label {
    border-radius: 2px;
    overflow: hidden;
    padding-left: 6px;
    text-overflow: ellipsis;
    white-space: normal;
    user-select: none;
    font-size: 14px;
    line-height: 1.2;
    word-break: break-word;
    color: inherit; }
    .nx-select .nx-select__multi-value__label__child {
      color: #323232;
      padding: 3px 0px 3px 6px; }
  .nx-select .nx-select__multi-value__remove {
    align-items: center;
    border-radius: 2px;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
    display: flex; }
    .nx-select .nx-select__multi-value__remove:hover {
      cursor: pointer;
      color: inherit;
      background-color: #FFE4CC; }
    .nx-select .nx-select__multi-value__remove .nx-icon__close {
      color: #323232;
      display: inline-block;
      width: 23px;
      height: 23px; }
  .nx-select .nx-select__input {
    box-sizing: border-box;
    color: #5B5B5B; }
  .nx-select .nx-select__value-container--has-value.nx-select__value-container--is-multi .nx-select__input {
    margin-left: 4px; }
  .nx-select .nx-select__placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    color: #CBCBCB; }
  .nx-select.nx-select--is-disabled .nx-select__placeholder {
    position: static;
    transform: translate(0); }
  .nx-select .nx-select-bottom-menu .nx-select__menu {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 0;
    margin-top: -4px; }
    .nx-select .nx-select-bottom-menu .nx-select__menu .nx-select__menu-list {
      border-top: 1px solid #CBCBCB; }
  .nx-select .nx-select-top-menu .nx-select__menu {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 0;
    margin-bottom: -4px; }
    .nx-select .nx-select-top-menu .nx-select__menu .nx-select__menu-list {
      border-bottom: 1px solid #CBCBCB; }
  .nx-select .nx-select__menu {
    background-color: #FFF;
    border-radius: 0;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    z-index: 4;
    border: 1px solid #E06E18;
    color: #5B5B5B;
    box-shadow: none;
    transition: border-color 200ms ease-in-out; }
    .nx-select .nx-select__menu .nx-option__side-container {
      display: flex; }
  .nx-select .nx-select__menu-list {
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    max-height: 300;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0;
    margin-top: 2px; }
  .nx-select .nx-select__group--nested-nogroup {
    padding: 0px !important; }
    .nx-select .nx-select__group--nested-nogroup .nx-select__group-heading {
      display: none; }
  .nx-select .nx-select__group-heading {
    padding: 8px 0.75rem;
    font-size: 12px;
    color: #949494; }
  .nx-select .nx-select__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 0.75rem;
    background-color: #FFF;
    user-select: none;
    cursor: default;
    overflow: hidden; }
    .nx-select .nx-select__option--is-focused {
      background-color: #FFF2E6; }
    .nx-select .nx-select__option--is-selected {
      background-color: #E06E18;
      color: #FFF; }
    .nx-select .nx-select__option:active {
      background-color: #E06E18;
      color: #FFF; }
    .nx-select .nx-select__option.nx-select__option--is-selected.nx-select__option--is-focused {
      background-color: #E06E18;
      color: #FFF; }
    .nx-select .nx-select__option__footer {
      font-size: 12px;
      font-style: italic;
      background-color: #E6E6E6; }
    .nx-select .nx-select__option__child {
      padding-left: 1.5rem;
      padding-right: 1.5rem; }
  .nx-select .nx-select__option__label {
    flex: 1 1 auto;
    word-break: break-word; }
  .nx-select .nx-select__option__prefix {
    flex: 0 0 auto;
    margin: -8px;
    padding-right: 0.75rem; }
  .nx-select .nx-select__option__suffix {
    flex: 0 0 auto;
    margin: -8px;
    padding-left: 0.75rem;
    word-break: break-word; }
  .nx-select .nx-select__option__suffix__icon-wrapper {
    width: 40px;
    text-align: center; }
  .nx-select .nx-select__option--is-action {
    color: #E06E18;
    border-bottom: 1px solid #CBCBCB; }
    .nx-select .nx-select__option--is-action--is-selected, .nx-select .nx-select__option--is-action:active {
      background-color: #E06E18;
      color: #FFF; }
  .nx-select .nx-select__group .nx-select__option {
    padding: 8px 1.5rem; }
  .nx-select .nx-select__group .nx-select__option--nested-nogroup {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .nx-select .nx-select__option__label__subtext {
    font-size: 12px;
    color: #949494; }
  .nx-select .nx-select__option--is-selected.nx-select__option--is-focused {
    background-color: #E06E18;
    color: #FFF; }
  .nx-select .nx-select__option--is-selected .nx-select__option__label__subtext {
    background-color: #E06E18;
    color: #FFF; }
  .nx-select .nx-select__option--is-selected.nx-select__option--is-focused .nx-select__option__label__subtext {
    background-color: #E06E18;
    color: #FFF; }
  .nx-select .nx-select__menu-notice--no-options,
  .nx-select .nx-select__menu-notice--loading {
    box-sizing: border-box;
    background: #FFF;
    text-align: center;
    padding: 8px 0.75rem;
    color: #999; }
  .nx-select .nx-select__indicators {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    align-self: stretch;
    color: #5B5B5B; }
    .nx-select .nx-select__indicators .nx-select__indicator-separator {
      width: 0;
      display: none; }
  .nx-select .nx-select__indicator + .nx-select__indicator-separator + .nx-select__indicator {
    padding-left: 0; }
  .nx-select .nx-select__clear-indicator {
    display: flex;
    padding: 4px;
    color: #5B5B5B; }
  .nx-select .nx-select__control--menu-is-open .nx-select__dropdown-indicator .nx-icon__dropdown-arrow {
    transform-origin: 50%, 50%;
    transform: rotate(180deg); }
  .nx-select .nx-select__dropdown-indicator {
    position: relative;
    top: -1px;
    box-sizing: border-box;
    display: flex;
    padding: 4px;
    color: #5B5B5B; }
  .nx-select .nx-select__with-left-icon {
    position: relative;
    padding: 0;
    width: auto; }
    .nx-select .nx-select__with-left-icon .nx-select__left-icon {
      position: absolute;
      margin: -2px 0;
      z-index: 1; }
    .nx-select .nx-select__with-left-icon .nx-select__control {
      padding-left: 20; }
  .nx-select .nx-select__tab-action {
    position: relative;
    padding: 0;
    width: auto; }
  .nx-select .nx-select__with-tab-action:focus {
    outline: none; }
  .nx-select .nx-select__with-tab-action + .nx-select__menu {
    border-color: #2070D0; }
  .nx-select .nx-select__with-tab-action .nx-select__value-container {
    padding: 4px 0.75rem; }
  .nx-select .nx-select__with-tab-action .nx-select__tab-action-button {
    height: 24px;
    position: absolute;
    top: -24px;
    right: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer; }
    .nx-select .nx-select__with-tab-action .nx-select__tab-action-button svg {
      height: 24px; }
    .nx-select .nx-select__with-tab-action .nx-select__tab-action-button .nx-add-variable-button-text {
      background-color: #2070D0;
      border-radius: 0;
      border-top-right-radius: 4px;
      color: #FFF;
      line-height: 24px;
      padding: 0 0.75rem 0 0; }
  .nx-select .nx-select__with-tab-action .nx-select__control,
  .nx-select .nx-select__with-tab-action .nx-select__control:focus {
    outline: none;
    min-height: 39px; }
    .nx-select .nx-select__with-tab-action .nx-select__control .nx-select__value-container:focus,
    .nx-select .nx-select__with-tab-action .nx-select__control:focus .nx-select__value-container:focus {
      outline: none; }
  .nx-select .nx-select__with-tab-action .nx-select__control--is-focused {
    border-color: #2070D0; }
  .nx-select .nx-select__with-tab-action .nx-select__value-container--is-multi.nx-select__value-container--has-value .nx-pill:not(:first-child) {
    margin: 3px 5px; }
  .nx-select .nx-select__with-tab-action + .nx-select__menu .nx-select__option {
    padding: 5px 0.75rem; }
    .nx-select .nx-select__with-tab-action + .nx-select__menu .nx-select__option:first-child {
      padding-top: 10px; }
    .nx-select .nx-select__with-tab-action + .nx-select__menu .nx-select__option:last-child {
      padding-bottom: 10px; }
  .nx-select .nx-select__custom-indicators-container {
    display: flex; }
  .nx-select .nx-select__custom-indicators-indicator {
    background-color: transparent; }
    .nx-select .nx-select__custom-indicators-indicator:hover {
      background-color: transparent; }
  .nx-select .nx-select-create-new-option {
    color: #E06E18; }
    .nx-select .nx-select-create-new-option:active {
      color: white; }
    .nx-select .nx-select-create-new-option .nx-btn-icon-wrapper {
      top: -1px; }

.nx-select-message {
  width: 100%;
  display: inline-block;
  padding: 5px 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #FFF;
  background-color: #E10D11; }
  .nx-select-message--is-warning {
    color: #323232;
    background-color: #FFCB00; }
