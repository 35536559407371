$multiselect-toggle-size: 30px + 24px;

.nx-multiselect {
  .nx-dropdown-text {
    text-overflow: unset;
    overflow: hidden;
  }

  .nx-multiselect-count {
    position: absolute;
    top: 0;
    left: 1px;
    width: calc(100% - #{$multiselect-toggle-size});
    height: calc(100% - 2px);
    background-color: $color-white;
  }

  .nx-dropdown-input {
    padding-right: $multiselect-toggle-size;
  }

  .nx-dropdown-clear {
    right: 20px;
  }

  &.nx-has-items {
    .nx-dropdown-clear {
      display: inline;
    }
  }
}

.nx-multiselect-menu-inner {
  li.nx-multiselect-reset {
    position: inherit;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray-light;

    &:before {
      content: ' ';
      width: 100%;
      height: 3px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $color-silverstone;
      border-top-left-radius: $dropdown-inner-border-radius;
      border-top-right-radius: $dropdown-inner-border-radius;
    }

    background-color: $color-silverstone;

    > button {
      padding: 15px 12px 18px;
      background: none;
      border: none;
      display: inline-block;
      width: auto;
      cursor: pointer;
      font-size: inherit;
      color: $color-taupe-gray;
    }

    .nx-multiselect-reset, .nx-multiselect-reset:hover {
      text-align: right;
      color: $color-brand-orange;

      &.nx-disabled {
        color: $color-taupe-gray;
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    .nx-multiselect-reset:hover:not(.nx-disabled) {
      text-decoration: underline;
    }

    .nx-multiselect-select-all {
      padding: 15px 12px 18px 40px;
    }
  }

  li.nx-dropdown-item:not(.nx-multiselect-reset) {
    position: relative;
    background-color: inherit;

    > a {
      padding: 18px 12px 18px 40px;
    }
  }

  li.nx-dropdown-item {
    &.nx-selected {

      .nx-checkbox-faux {
        background-color: $color-nintex-orange;
        border-color: $color-nintex-orange;

        &::before {
          content: '\0020';
          position: absolute;
          z-index: 2;
          width: 36px;
          height: 36px;
          background-size: 36px 36px;
          // Tick
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNiAzNiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNGRkZ9PC9zdHlsZT48L2RlZnM+PHBhdGggaWQ9Imljb24tY29sb3VyLWdyZWVuIiBjbGFzcz0iY2xzLTEiIGQ9Ik0xMi4zNzUgMjAuMTFhMS4yOCAxLjI4IDAgMCAxIDEuODEtMS44MDlsMi42MTEgMi42MSA1Ljk0OC02LjUzOGExLjI4MSAxLjI4MSAwIDEgMSAxLjgxMiAxLjgxTDE3LjcgMjMuNjI3YTEuMjg0IDEuMjg0IDAgMCAxLTEuODEgMHoiLz48L3N2Zz4=");
          background-repeat: no-repeat;
          background-position: center;
          top: -12px;
          left: -12px;
        }
      }

      > a {
        &::before {
          content: '\0020';
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: $color-brand-primary;
        }
      }
    }
  }

  .nx-checkbox-faux {
    position: absolute;
    display: inline-block;
    border: 1px solid $color-gray-medium;
    border-radius: 2px;
    background-color: $color-white;
    z-index: 1;
    top: 17px;
    left: 14px;
    width: 16px;
    height: 16px;
  }
}
