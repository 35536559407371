@import "../theme/theme";
$icon-size: 36px;
$excess-icon-height: 4px;
$missing-spinner-height: 20px;
$horizontal-padding: 5px;
$spinner-width: 14px;

$icon-vertical-margin: -$excess-icon-height / 2;
$spinner-vertical-margin: $missing-spinner-height / 2;

$input-btn-icon-size: 25px;
$input-padding-y: 7px;
$input-padding-x: 12px;

.nx-input {
  position: relative;
  padding: 0;
  width: auto;

  .nx-input-control {
    display: block;
    width: 100%;
    height: 37px;
    padding: $input-padding-y $input-padding-x;
    font-weight: normal;
    line-height: 1.25;
    color: $input-color;
    background-clip: padding-box;
    border: 1px solid $input-border-color;
    border-radius: $border-radius-base;
    transition: all 200ms ease-in-out;

    &:focus {
      border-color: $input-focus-border-color;
    }

    &:read-only{
      color: $input-read-only-color;
      background: $input-read-only-bg-color;
      border-color: $input-read-only-border-color;
      &:focus {
        border-color: $input-read-only-focus-border-color;
      }
    }

    &::placeholder, &:-ms-input-placeholder {
      color: $input-placeholder-color;
    }

    &:disabled {
      color: $input-disabled-color;
      border-color: $input-disabled-border-color;
    }
  }

  .nx-input-search-icon {
    position: absolute;
    margin: $icon-vertical-margin 0;
    cursor: pointer;
  }

  .nx-input-filter-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  &.nx-input-has-highlight .nx-input-control {
    padding: ($input-padding-y - 1) ($input-padding-x - 1);
    border-width: 2px;
    border-color: $color-mariner-blue;
  }

  &.nx-input-has-search .nx-input-control {
    padding-left: $icon-size - $horizontal-padding;
  }

  &.nx-input-has-loading .nx-input-control {
    padding-right: $icon-size;
  }

  &.nx-input-has-reset .nx-input-control {
    padding-right: $icon-size;
  }

  &.nx-input-has-loading.nx-input-has-reset .nx-input-control {
    padding-right: $icon-size + $spinner-width + $horizontal-padding;
  }

  .nx-input-spinner {
    position: absolute;
    top: 0;
    right: ($icon-size - $spinner-width) / 2;
    width: $spinner-width;
    margin: $spinner-vertical-margin 5px $spinner-vertical-margin 0;
  }

  &.nx-input-has-reset .nx-input-spinner {
    right: $icon-size;
  }

  .nx-input-filter-icon {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
  }

  &.nx-input-has-reset.nx-input-has-filter {
    .nx-input-control {
      padding-right: 2 * $icon-size;
    }
    .nx-input-filter-icon {
      margin-right: $input-btn-icon-size;
    }
  }

  &.nx-input-has-loading.nx-input-has-reset.nx-input-has-filter {
    .nx-input-control {
      padding-right: 3 * $icon-size;
    }
    .nx-input-spinner {
      right: 2 * $icon-size;
    }
  }

  &.nx-input-has-error {
    .nx-input-control {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-color: $input-error-border-color;
    }
    .nx-error-message {
      width: 100%;
      display: inline-block;
      padding: 5px 13px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      color: $color-white;
      background-color: $input-error-bg-color;
    }
  }

  .nx-btn-clear {
    position: absolute;
    top: 0;
    right: 0;
    margin: $icon-vertical-margin 0;
    background: transparent;
    cursor: pointer;

    &:hover, &:focus {
      background-color: $input-clear-button-hover-bg-color;
    }
  }
}
