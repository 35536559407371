.nx-date-time-clock {
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &.nx-is-active {
    display: flex;
  }
}

.nx-date-time-clock-input {
  display: block;
  color: $input-color;
  background-color: $input-bg;
  border: 1px solid $input-border-color;
  border-radius: $border-radius-base;
  padding: 16px 0;
  text-align: center;
  font-size: 30px;
  line-height: 1;
  outline: 0;
  width: 2em;
}

.nx-date-time-clock-separator {
  font-size: 50px;
  margin: -5px 5px 0;
  font-weight: bold;
}

.nx-date-time-clock-meridian {
  margin-left: 5px;
}
