/// Set the outline for an element that has focus.
@mixin tab-focus($size: 0) {
    @if $size == 0 {
        outline: 0 none;
    } @else {
        outline: $size solid $input-border-focus;
        outline-offset: -2px;
    }
}

/// Reset an ordered or unordered list
@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

/// Creates a box shadow according to UX specifications
///
/// @param {Number} $index - The category of box shadow.
@mixin box-shadow($index: 1) {
    @if $index == 2 {
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.11);
    } @else if $index == 3 {
        box-shadow: 0 21px 21px 0 rgba(0, 0, 0, 0.12);
    } @else if $index == 4 {
        box-shadow: 2px 3px 8px 0 rgba(0, 0, 0, 0.12);
    } @else {
        box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
    }
}

/// Creates a focus glow on any element on focus or an element
/// inside that element
@mixin element-style-focus-shadow($border-color, $selector: null) {
    @if $selector == null {
        transition: box-shadow 0.2s ease-in-out;
    }
    @else {
        #{$selector} {
            transition: box-shadow 0.2s ease-in-out;
        }
    }
    &:focus,
    &.focus {
        @if $selector == null {
            border-color: $border-color;
            box-shadow: 0 0 0 $btn-focus-width rgba($border-color, .5);
        }
        @else {
            #{$selector} {
                border-color: $border-color;
                box-shadow: 0 0 0 $btn-focus-width rgba($border-color, .5);
            }
        }
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        &:focus {
            @if $selector == null {
                border-color: $border-color;
                box-shadow: 0 0 0 $btn-focus-width rgba($border-color, .5);
            }
            @else {
                #{$selector} {
                    border-color: $border-color;
                    box-shadow: 0 0 0 $btn-focus-width rgba($border-color, .5);
                }
            }
        }
    }
}

@mixin form-element-style-focus-shadow($outline-color: null, $background-color: null, $border-color: null, $text-decoration: null, $selector: null) {
    @if $selector == null {
        transition: box-shadow 0.2s ease-in-out;
    }
    @else {
        #{$selector} {
            transition: box-shadow 0.2s ease-in-out;
        }
    }
    
    &:focus,
    &.focus {
        @if $selector == null {
            @if $outline-color {
                box-shadow: 0 0 0 $btn-focus-width $outline-color;
            }
            background-color: $background-color;
            border-color: $border-color;
            text-decoration: $text-decoration;
        }
        @else {
            #{$selector} {
                @if $outline-color {
                    box-shadow: 0 0 0 $btn-focus-width $outline-color;
                }
                background-color: $background-color;
                border-color: $border-color;
                text-decoration: $text-decoration;
            }
        }
    }
}
