@import '../theme/_theme.scss';

.nx-global-header {
    @if ($global-header-keyline-color != transparent) {
        border-bottom: 1px solid $global-header-keyline-color;
    }

    position: $global-header-position;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $global-header-zindex;
    height: $global-header-height;
    background-color: $global-header-bg-color;
    padding: $global-header-padding;

    > p {
        display: inline-block;
        margin: 0;
        line-height: 1;
        font-family: $headings-font-family;
    }
}

.nx-global-header-brand {
    height: $global-header-height;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

// The website uses an inline SVG
.nx-logo {
  display: inline-block;
  background: none;
  position: relative;
  margin: $global-header-logo-margin;
  height: $global-header-logo-height;
  width: $global-header-logo-width;

  .nx-icon {
      position: absolute;
      top: -18px;
      left: -20px;
      height: 200%;
      width: 200%;
  }
}

a.nx-logo,
a.nx-logo:hover,
button.nx-logo {
  border: none;
  background: inherit;
  text-decoration: none;
  cursor: pointer;
}

.nx-canvas {
    &.nx-with-global-header {
        padding-top: $global-header-height;
    }
}
