@import "../theme/theme";

$row-height: 42px;
$column-width: 250px;

.nx-json-schema-explorer {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  background: $color-white;
  font-size: 0.9em;
}

.nx-json-schema-list {
  display: flex;
  outline: 1px solid $color-gray-light;

  &-space {
    width: $column-width;
  }
}

.nx-hidden {
  display: none;
}

.list-group-radio {
  flex: 0;
  min-width: $column-width;
  overflow-y: auto;

  .list-group-item {
    padding: 0.5rem;
    border-width: 0;
    background: transparent;
    color: $color-charcoal;

    &:first-child,
    &:last-child {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.nx-showing-detail {
    background: $color-brand-orange;
    color: $color-nearly-white;
  }

  .list-group-item-radio {
    position: absolute;
    display: block;
  }
}

.list-group-item-radio {
  & + .list-group-item-action {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nx-meta {
      margin-left: 5px;
    }

    .nx-action {
      position: relative;
      margin-left: 5px;
      padding: 0.2rem 0.625rem;
    }

  }

  & + .nx-disabled {
    color:$color-gray-medium;
    &:hover {
      background-color: $color-peach-sorbet;
    }
  }

  &:checked + .list-group-item-action {
    background: $color-dark-honeycomb;
    width: calc(100% - 1px);
  }

  &:focus:checked + .list-group-item-action {
    background: $color-brand-orange;
    color: $color-nearly-white;
  }

  &:focus + .list-group-item-action {
    background: $color-brand-orange;
    color: $color-nearly-white;
  }
}

.nx-list-group-item-attachment {
  display: flex;
  width: 25px;
  height: 25px;
  padding: 0;
  border: 0;
  background: transparent;
  justify-content: center;
  align-items: center;

  .nx-btn-icon-wrapper {
    margin: 0;
  }
}
