@import "../theme/theme";

.nx-async-select-token-partial {
  padding: 2px 0 2px 6px;
  background-color: rgba($color-brand-blue, 0.15);
}

.nx-async-select-token-partial+.nx-async-select-token-partial {
  padding-left: 0;
}

.nx-select__value-container {
  .nx-async-select-token-partial:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  // .nx-async-select-token-partial:last-child selector is not possible
  // as there is another element(input) in the same level as the last child
  span:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.nx-async-select-highlighted-text {
  padding: 0;
  background-color: rgba($color-brand-blue, 0.15);
}

.nx-async-select {
  .nx-select .nx-select__control {
    // removing the input field(see code below) in disabled state affects the height(gets reduced), 
    // so need to adjust the height back to the size equal to its normal state
    min-height: 35px;
  }

  .nx-select__control--is-disabled {
    .nx-select__value-container {
      >div:nth-child(2) {
        // parent div of .nx-select__input(this has no custom class to target)
        // reason for removing this is, we dont want the input field occupying a row of its own,
        // when the control becomes multiline and disabled. Visually its just some empty space(row)
        display: none;
      }
    }
  }
}

.nx-async-select__token-option {
  display: flex;
  width: 100%;
}

.nx-async-select__token-option__pill {
  flex-grow: 1;
}

.nx-async-select__token-option__suffix {
  display: flex;
  flex: 0 0 80px;
  align-items: center;
  justify-content: flex-end;
}

.nx-async-select__token-option__subtext {
  font-size: 12px;
  color: $color-gray-medium;
}

.nx-async-select__token-option--is-selected.nx-select__option--is-focused {
  background-color: $color-nintex-orange;
  color: $color-white;
}

.nx-async-select__token-option--is-selected .nx-async-select__token-option__subtext {
  background-color: $color-nintex-orange;
  color: $color-white;
}

.nx-async-select__token-option--is-selected.nx-select__option--is-focused .nx-async-select__token-option__subtext {
  background-color: $color-nintex-orange;
  color: $color-white;
}
