.nx-has-flag {
  position: relative; }

.nx-flag {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  z-index: 2; }
  .nx-flag .nx-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 45px;
    width: 45px; }
  .nx-flag .nx-icon__cu-unconfigured {
    fill: #E06E18;
    color: #FFF; }
  .nx-flag .nx-icon__cu-alert-tick {
    fill: #FFCB00;
    color: #323232; }
  .nx-flag .nx-icon__cu-error {
    fill: #E10D11;
    color: #FFF; }
  .nx-flag .nx-icon__cu-success {
    fill: #09894E;
    color: #FFF; }

.nx-fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 4s, opacity 4s linear; }
