@mixin action-menu-item-icon {
  > svg.icon {
    fill: $action-menu-text-color;
    float: left;
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    top: 4px;
  }

  &:hover,
  &:focus {
    > svg.icon {
      fill: $action-menu-text-color-hover;
    }
  }
}

@mixin action-menu-item($has-icon: false) {
  > li {
    display: block;
    line-height: 100%;

    &.nx-has-separator {
      border-bottom: 1px solid $action-menu-keyline;
    }

    &.nx-action-menu-group-start {
      border-top: 1px solid $action-menu-keyline;
    }

    > a,
    > button {
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 100%;
      color: $action-menu-text-color;
      text-align: left;
      width: 100%;
      border: none;
      background: none;
      font-family: inherit;
      font-size: inherit;
      outline: none;

      > .nx-btn-icon-wrapper, > .nx-icon {
        margin-right: 10px;
        flex-shrink: 0;
      }

      @if ($has-icon == false) {
        padding: 9px 10px;
      } @else {
        // Add space to to pin icon
        padding: 9px 10px 9px 36px;
        position: relative;
        @include action-menu-item-icon;
      }

      &:hover:not(.disabled),
      &:focus:not(.disabled) {
        background-color: $action-menu-bg-color-hover;
        color: $action-menu-text-color-hover;
        text-decoration: none;
      }

      &.disabled {
        background-color: transparent;
        color: $action-menu-text-color-disabled;
        text-decoration: none;
        cursor: not-allowed;
        padding: 15px 13px 14px;
      }
    }
  }
}
