@import "../mixins/forms";
@import "../mixins/utils";

.nx-checkbox {
    padding: $input-padding-y 0;

    input[type=checkbox] {
        @include minimize-checkbox;
        @include form-element-style-focus-shadow($checkbox-focus-outline-color, $checkbox-unselected-focus-bg-color, $checkbox-unselected-focus-border-color, null, '+ .nx-checkbox-container .nx-checkbox-faux');

        &:checked + .nx-checkbox-container {
            .nx-checkbox-faux {
                &::before {
                    content: '\0020';
                    position: absolute;
                    z-index: 2;
                    width: 16px;
                    height: 16px;
                    background-size: 36px 36px; // Tick
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNiAzNiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNGRkZ9PC9zdHlsZT48L2RlZnM+PHBhdGggaWQ9Imljb24tY29sb3VyLWdyZWVuIiBjbGFzcz0iY2xzLTEiIGQ9Ik0xMi4zNzUgMjAuMTFhMS4yOCAxLjI4IDAgMCAxIDEuODEtMS44MDlsMi42MTEgMi42MSA1Ljk0OC02LjUzOGExLjI4MSAxLjI4MSAwIDEgMSAxLjgxMiAxLjgxTDE3LjcgMjMuNjI3YTEuMjg0IDEuMjg0IDAgMCAxLTEuODEgMHoiLz48L3N2Zz4=");
                    background-repeat: no-repeat;
                    background-position: center;
                    top: -2px;
                    left: -2px;
                }
            }
        }

        &:not([disabled]):checked {
            + .nx-checkbox-container {
                .nx-checkbox-faux {
                    background-color: $checkbox-selected-bg-color;
                    border-color: $checkbox-selected-border-color;
                }
            }

            &:focus {
                + .nx-checkbox-container {
                    .nx-checkbox-faux {
                        background-color: $checkbox-toggle-focus-bg-color;
                        border-color: $checkbox-toggle-focus-bg-color;
                    }
                }
            }
        }

        &[disabled] {
            cursor: $cursor-disabled;

            &:checked {
                + .nx-checkbox-container {
                    .nx-checkbox-faux {
                        background-color: $checkbox-disabled-bg-color;
                    }
                }
            }

            + .nx-checkbox-container {
                cursor: $cursor-disabled;

                .nx-checkbox-faux {
                    cursor: $cursor-disabled;
                    border-color: $checkbox-disabled-border-color;
                }

                .nx-checkbox-label {
                    cursor: $cursor-disabled;
                    color: $checkbox-disabled-label-color;
                }
            }
        }
    }

    > label {
        display: inline-block;
        width: 100%;
        // Override a TWBS property for all <label>s
        margin-bottom: 0;
    }

    .nx-checkbox-container {
        cursor: pointer;
        padding-left: 24px;
        position: relative;
        user-select: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        min-height: 18px;
        width: 100%;
    }

    .nx-checkbox-faux {
        cursor: pointer;
        position: absolute;
        display: inline-block;
        border: 1px solid $checkbox-border-color;
        border-radius: 2px;
        background-color: $checkbox-bg-color;
        z-index: 1;
        top: 1px;
        left: 0;
        width: 16px;
        height: 16px;
    }

    .nx-checkbox-label {
        top: 1px;
        font-size: 14px;
        cursor: pointer;
    }

    &.nx-checkbox-inline {
        display: inline-block;
        padding-right: $input-padding-x;
    }
}

// Turn off accessibility features when use not tabbing
[data-whatintent='mouse'] .nx-checkbox input[type=checkbox]:focus + .nx-checkbox-container .nx-checkbox-faux {
    box-shadow: none;
    border-color: $checkbox-border-color;
}
