@mixin nx-toolbox-item {
  border-radius: $toolbox-border-radius;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}

@mixin nx-toolbox-item-image {
  img {
    position: absolute;
    width: 28px;
    height: 28px;
    top: calc(50% - 14px);
    left: 2px;
  }
}

@mixin nx-toolbox-item-title {
  background-color: $toolbox-title-bg-color;
  border-top-right-radius: $toolbox-border-radius;
  border-bottom-right-radius: $toolbox-border-radius;
  line-height: 1;
  padding: $toolbox-item-title-padding $toolbox-drag-width $toolbox-item-title-padding $toolbox-item-title-padding;
  margin-left: 32px;
  border-left: 1px solid $color-keyline;
  display: inline-block;
  width: calc(100% - 32px);
  white-space: normal;
  user-select: none;
}

@mixin nx-toolbox-item-gripper {
  .nx-icon-drag {
    position: absolute;
    right: 0;
    top: calc(50% - 20px);
    @include foundation-icon-block(32px, 40px);
  }
}

@mixin nx-toolbox-item-focus($dragging) {
  cursor: pointer;

  @if ($dragging) {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
  } @else {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
  }

  .nx-toolbox-item-title {
    background-color: $color-hover-bg;
  }

  .nx-icon-drag {
    @include svg-utility-gray-drag-2;
  }
}

@mixin nx-toolbox-item-dragging {
  .nx-toolbox-item-dragging {
    list-style-type: none;
    transform: rotate($toolbox-item-dragging-rotation);
    z-index: $toolbox-item-dragging-z-index;
    @include nx-toolbox-item;
    @include nx-toolbox-item-image;
    @include nx-toolbox-item-title;
    @include nx-toolbox-item-gripper;
    @include nx-toolbox-item-focus($dragging: true);
  }
}
