.nx-remote-user-select .nx-select__option__label__subtext {
  display: inline;

  &::before {
    content: ' (';
  }

  &::after {
    content: ')';
  }
}
