@import "./dropdown";

.nx-combobox-input {
  position: relative;
  display: block;
  padding: 0 $dropdown-toggle-size 0 0;
  background: none;
  border: none;
  outline: 0;
  width: 100%;
  height: $dropdown-height;

  > input[type=text] {
    border-top-left-radius: $dropdown-border-radius;
    border-bottom-left-radius: $dropdown-border-radius;
    padding: 10px 0 11px 12px;
    height: calc(100% - 1px);
    width: 100%;
    border: 1px solid $color-white;
    outline: 0;
    font-size: $font-size-base;

    &[disabled] {
      color: $color-gray-medium;
      cursor: not-allowed;
    }

    // Hide the shadow DOM that Edge/IE creates to clear the input
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

  }
}

.nx-combobox-toggle {
  position: absolute;
  height: 100%;
  @include svg-dropdown-caret;
  width: $dropdown-toggle-size;
  background-position: center;
  border: none;
  outline: 0;
  top: 0;
  right: 0;
}

.nx-control-combobox {
  border-radius: $dropdown-border-radius;

  &.form-control {
    padding: 0;
  }

  &.nx-has-focus {
    border: 1px solid $color-brand-primary;
    outline: 0;
  }

  &.nx-disabled {
    .nx-combobox-toggle {
      opacity: 0.8;
    }
  }

  &.nx-open {
    .nx-dropdown-menu {
      display: block;
    }
  }

  .nx-combobox-clear {
    position: absolute;
    width: $dropdown-toggle-size;
    height: 100%;
    @include svg-dropdown-clear;
    background-size: 10px 10px;
    background-position: center;
    border: none;
    outline: 0;
    display: none;
  }

  &.nx-has-query {
    .combobox-toggle {
      display: none;
    }

    .nx-combobox-clear {
      display: inline;
    }
  }
}
