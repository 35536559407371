@import "../mixins/spinner";

@keyframes middle {
  0% {
    opacity: 0;
  }
  32% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes second {
  0% {
    opacity: 0;
  }
  24% {
    opacity: 0;
  }
  32% {
    opacity: 0.9;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes third {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 0;
  }
  24% {
    opacity: 0.8;
  }
  40% {
    opacity: 0
  }
  100% {
    opacity: 0;
  }
}

@keyframes forth {
  0% {
    opacity: 0;
  }
  8% {
    opacity: 0.7;
  }
  24% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fifth {
  0% {
    opacity: 0.6;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* rtl:ignore-start */
.nx-spinner {
  color: $status-spinner-color;
}

.nx-spinner-large {
  @include status-spinner($count:6, $element: div);
  @include status-spinner-size($height: 84px, $width: 3px, $count: 6);
}

.nx-spinner-small {
  @include status-spinner($count:4, $element: span);
  @include status-spinner-size($height: 14px, $count: 4, $element: span);
}

.nx-spinner-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.nx-spinner-container-large {
  flex-direction: column;

  .nx-spinner-text {
    margin-top: $padding-regular;
  }
}

.nx-spinner-container--left {
  justify-content: flex-start;
}

.nx-spinner-container--center {
  justify-content: center;
}

.nx-spinner-container--right {
  justify-content: flex-end;
}

.nx-spinner-text {
  margin-left: 10px;
}

.nx-nintex-spinner {
  .nx-icon {
    height: 60px;
    width: 60px;
  }

  > div {
    display: inline-block;
  }

  &-middle {
    position: relative;
  }

  &-left {
    position: absolute;
  }

  &-trail-left, &-trail-right {
    margin: 0 -8px;
  }

  &-trail-left, &-left {
    color: $spinner-theme-regular-left-color;
  }

  &-trail-right, &-right {
    color: $spinner-theme-regular-right-color;
  }

  &-middle, &-second, &-third, &-forth, &-fifth {
    animation-duration: 2000ms;
    transform-origin: center;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  &-middle {
    animation-name: middle;
  }

  &-second {
    animation-name: second;
  }

  &-third {
    animation-name: third;
  }

  &-forth {
    animation-name: forth;
  }

  &-fifth {
    animation-name: fifth;
  }
}

.nx-nintex-spinner-small {
  padding: 0;

  .nx-icon {
    height: 15px;
    width: 15px;
  }

  .nx-nintex-spinner-trail-left, .nx-nintex-spinner-trail-right {
    margin: 0 -2px;
  }

  .nx-spinner-status {
    font-size: $font-size-sm;
    margin: 0;
  }
}

.nx-nintex-spinner-medium {
  .nx-icon {
    height: 50px;
    width: 50px;
  }

  .nx-nintex-spinner-trail-left, .nx-nintex-spinner-trail-right {
    margin: 0 -6px;
  }

  .nx-spinner-status {
    font-size: $font-size-h4;
    margin: $font-size-base;
  }
}

.nx-spinner-theme-light {
  .nx-nintex-spinner-trail-left, .nx-nintex-spinner-trail-right {
    color: $color-white;
  }

  .nx-nintex-spinner-left {
    color: $color-white;
  }

  .nx-nintex-spinner-trail-right, .nx-nintex-spinner-right {
    color: $spinner-theme-light-right-color;
  }

  .nx-spinner-status {
    color: $color-white;
  }
}

/* rtl:ignore-end */

.nx-spinner-status {
  margin: 1em;
  font-size: $font-size-h3;
  text-align: center;
}
