.nx-json-schema-form-required::after {
  content: "*";
  margin-left: 3.5px;
  color: #E06E18; }

.nx-json-schema-form-card {
  position: relative;
  padding: 14px;
  background: #232323;
  border: 1px solid #FFF;
  border-radius: 4px;
  color: #FFF; }

.nx-json-schema-form-fields {
  margin: -7px; }

.nx-json-schema-form-field {
  padding: 7px; }

.nx-json-schema-form-group-heading {
  border-bottom: 1px solid;
  font-size: 20px;
  padding-bottom: 4px;
  margin-bottom: 14px !important; }

.nx-json-schema-form-sub-title {
  font-style: italic;
  font-size: 10pt; }
