.nx-placeholder {
  opacity: 0.75;
  padding: 15px; }

.nx-placeholder-item, .nx-placeholder-label {
  background: #949494;
  width: 50%;
  height: 15px;
  margin-bottom: 15px; }

.nx-placeholder-item {
  width: 100%;
  height: 30px;
  margin-bottom: 30px; }

.nx-shimmer {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #949494 4%, #858585 25%, #949494 36%);
  background-size: 1000px 100%; }

@keyframes shimmer {
  0% {
    background-position: -1000px 0; }
  100% {
    background-position: 1000px 0; } }
