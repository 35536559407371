/// Define the basic properties of a button
@mixin button-style-base {
    line-height: $btn-line-height;
    border: 1px solid;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    display: inline-block;
}

/// Specify the style for the button
@mixin button-style($font-color, $background-color, $border-color) {
    background-color: $background-color;
    color: $font-color;
    border-color: $border-color;
}

/// Specify the transition effects for the button
@mixin button-transition {
    transition: background-color 0.3s linear, color 0.3s linear, border-color 0.3s linear;
}

/// Specify the hover style for the form control or button
@mixin button-style-hover($font-color-hover, $background-color-hover, $border-color-hover: null) {
    .hover,
    &:hover {
        background-color: $background-color-hover;
        color: $font-color-hover;
        cursor: pointer;
        text-decoration: none;
        @if $border-color-hover != null {
            border-color: $border-color-hover;
        }
    }
}

/// Specify the focus style for the button
@mixin button-style-focus($font-color-focus, $background-color-focus, $border-color-focus: null, $glow-color: null) {
    .focus,
    &:focus {
        text-decoration: none;
        background-color: $background-color-focus;
        color: $font-color-focus;
        @if $border-color-focus !=null {
            border-color: $border-color-focus;
        }
        @if $glow-color !=null {
            $color-rgba: transparentize($glow-color, 0.5);
            box-shadow: 0 0 8px $color-rgba;
        }
    }
}

@mixin button-style-focus-shadow($border-color, $active-bg: null, $text-color: null, $text-color-active: null) {
    &:focus,
    &.focus {
        @if $active-bg {
            border-color: $active-bg;
        }
        @if $enable-shadows {
            box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border-color, .5);
        }
        @else {
            box-shadow: 0 0 0 $btn-focus-width rgba($border-color, .5);
        }
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @if $active-bg {
            border-color: $active-bg;
            background-color: $active-bg;
        }
        @if $text-color-active {
            color: $text-color-active;
        }
        &:focus {
            @if $text-color != null and $text-color-active == null {
                color: $text-color;
            }
            @if $enable-shadows {
                box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border-color, .5);
            }
            @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($border-color, .5);
            }
        }
    }
}

@mixin button-focus-style($outline-color: null, $background-color: null, $border-color: null, $text-decoration: null) {
    &:focus,
    &.focus {
        @if $outline-color {
            box-shadow: 0 0 0 $btn-focus-width $outline-color;
        }
        background-color: $background-color;
        border-color: $border-color;
        text-decoration: $text-decoration;
    }
}

@mixin button-active-style($color: null, $background-color: null) {
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: $color;
        background-color: $background-color;
    }
}

@mixin button-style-focus-inset-shadow($border-color, $active-bg: null, $text-color: null, $text-color-active: null) {
    &:focus,
    &.focus {
        // border-color: $border-color;
        @if $active-bg {
            border-color: $active-bg;
        }
        @if $enable-shadows {
            box-shadow: inset $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border-color, .5);
        }
        @else {
            box-shadow: inset 0 0 0 $btn-focus-width rgba($border-color, .5);
        }
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @if $active-bg {
            border-color: $active-bg;
            background-color: $active-bg;
        }
        @if $text-color-active {
            color: $text-color-active;
        }
        &:focus {
            @if $text-color != null and $text-color-active == null {
                color: $text-color;
            }
            @if $enable-shadows {
                box-shadow: inset $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border-color, .5);
            }
            @else {
                box-shadow: inset 0 0 0 $btn-focus-width rgba($border-color, .5);
            }
        }
    }
}

@mixin button-style-disabled {
    &[disabled],
    &.disabled {
        cursor: $cursor-disabled;
        @include button-style($btn-disabled-color, $btn-disabled-bg, $btn-disabled-border);
        @include button-style-hover($btn-disabled-color, $btn-disabled-bg);
        &:hover {
            cursor: $cursor-disabled;
        }
    }
}

/// Specify size properties of the button
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $border-radius: $border-radius-base) {
    // Removing 1px from bottom of button to ensure text looks in the middle of the button,
    // for both <button> and <a> elements
    padding: $padding-vertical $padding-horizontal ($padding-vertical - 1px);
    font-size: $font-size;
    @if $border-radius>0 {
        border-radius: $border-radius;
    }
}

/// Specify the button dimensions
@mixin button-dimensions($width) {
    // height is determined by font-size and padding
    @if $width==auto {
        width: auto;
    }
    @else if $width==100% {
        width: 100%;
    }
    @else {
        min-width: $width;
    }
}

%secondary-button {
    @include button-style-hover($btn-secondary-color-hover, $btn-secondary-bg-hover, $btn-secondary-border-color);
    @include button-style-disabled;
    @include button-style-focus-shadow($btn-primary-border-color, $btn-primary-bg-hover, null, $color-white);
    @include button-focus-style($btn-primary-focus-outline-color, $btn-secondary-bg-hover, $btn-secondary-focus-border-color);
    @include button-active-style(null, $btn-secondary-active-bg-color);
    border-color: $btn-secondary-border-color;
    background-color: $btn-secondary-bg;
    color: $btn-secondary-color;
}

%inverse-button {
    &, &:disabled, &.btn.disabled {
        background: $btn-inverse-bg;
        color: $btn-inverse-color;
        border: 1px solid $btn-inverse-border;

        &:not([disabled]):not(.disabled):hover {
        background: $btn-inverse-bg-hover;
        color: $btn-inverse-color;
        }

        @include button-focus-style($btn-primary-focus-outline-color, $btn-inverse-focus-bg-color, $btn-inverse-border);
        @include button-active-style($btn-inverse-color, $btn-inverse-active-bg-color);
    }

    &:disabled,
    &.btn.disabled {
        @if $btn-inverse-disabled-solid {
            @include button-style-disabled;
        } @else {
            opacity: 0.5;
        }
    }
}