@import "../theme/theme";
@import "../mixins/navigation";
@import "../mixins/utils";
@import "../mixins/button";

$meatball-menu-width: 36px !default;
$meatball-menu-height: 32px !default;
$meatball-icon-width: 36px !default;
$logo-padding: 1px 6px;

.nx-global-nav {
    float: $global-nav-alignment;
}

.nx-global-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nx-logo-large {
        height: $global-header-height - 1px;
        width: 122px;
        flex-shrink: 0;
        padding: $logo-padding;

        .nx-icon {
            width: 100%;
            height: 100%;
            position: relative;
            top: 0;
            left: 0;
        }
    }

    .nx-logo-default {
        color: $global-header-logo-accent-color;
        fill: $global-header-logo-base-color;
    }

    .nx-logo-icon {
        color: $global-header-logo-icon-color;
    }

    .nx-breadcrumbs {
        margin-left: 30px;

        &.nx-breadcrumbs-inverse {
            &, .nx-breadcrumb-text {
                color: $global-header-breadcrumbs-text-color;
            }
            
            button > .nx-breadcrumb-text {
                color: $global-header-breadcrumbs-link-color;
            }
        }
    }

    .nx-global-nav {
        margin-left: auto;

        ul {
            display: flex;
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                display: flex;
                padding: 0 $padding-regular;
                margin-top: 1px;
                height: 46px;
                align-items: center;

                &.nx-global-nav-menu {
                    padding: 0;
                }

                > a,> .btn-link, .nx-action-menu-container button.btn-text {
                    text-decoration: none;
                    border-bottom: 0;
                    color: $global-nav-item-text-color;
                    transition: color 0.3s ease;
                    font-size: 14px;
                    position: relative;
                    height: 100%;

                    /* position it's content in the center with flex */
                    display: flex;
                    align-items: center;
                    height: 100%;

                    &:hover {
                        background-color: $global-nav-item-hover-bg-color;
                        color: $global-nav-item-text-color-hover;
                    }
                }

                >a, > .btn-link {
                    padding: $global-nav-item-link-padding;
                }

                &.nx-selected, &.selected {
                    background-color: inherit;

                    > a, > .btn, > .nx-action-menu-container button {
                        &::after {
                            content: " ";
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            display: block;
                            height: $global-nav-selected-highlight-height;
                            background: $global-nav-selected-highlight-color;
                        }

                        color: $global-nav-item-selected-text-color;
                    }
                }

                .badge.badge-pill {
                    position: absolute;
                    padding: 3px;
                    min-width: 17px;
                    top: 2px;
                    right: $meatball-menu-width - 4;
                    background-color: $global-nav-meatball-menu-badge-bg-color;
                    color: $global-nav-meatball-menu-badge-color;
                }

                .nx-btn-icon-wrapper {
                    pointer-events: none;
                }
            }
        }

        .nx-meatball-menu .nx-action-menu-container > .nx-icon-wrapper {
            @if $global-nav-meatball-menu-use-secondary-styling {
                @extend %secondary-button;
            } @else {
                @extend %inverse-button;
            }
            
            width: $meatball-menu-width;
            height: $meatball-menu-height;
            color: $global-nav-meatball-menu-color !important;
            border-radius: $border-radius-small;

            &:hover {
                color: $global-nav-meatball-menu-hover-color;
            }

            &:active {
                color: $color-white;
            }

            .nx-icon {
                width: $meatball-icon-width;
                height: $meatball-icon-width;
                position: absolute;
            }
        }

        .nx-meatball-menu .nx-icon-wrapper .nx-icon {
            width: 100%;
            height: 100%;
            left: 0;
            top: -1px;
        }
    }
}

.nx-action-menu-item {
    .badge.badge-pill{
        position: absolute;
        padding: 3px;
        min-width: 17px;
        right: 10px;
        background-color: $global-nav-meatball-menu-badge-bg-color;
        color: $global-nav-meatball-menu-badge-color;
    }
}

// Turn off accessibility features when use not tabbing
[data-whatintent='mouse'] {
    .nx-action-menu-list li button:focus{
        background: none;
        color: inherit;
        text-decoration: inherit;
    }
}

@if $global-nav-compatibility == true {
    /* Compatibility for old navigation */
    .nx-global-nav-level-0 {
        @include reset-list;

        > li {
            display: inline-block;
            padding: 0 $padding-regular;
            margin-top: 1px;

            &.nx-selected,
            &.nx-active {
                .nx-global-nav-level-0-item {
                    position: relative;
                    color: $global-nav-level-0-selected-text-color;
                    cursor: default;
                    transition: none;

                    &:hover {
                        color: $global-nav-level-0-selected-text-color;
                    }

                    // Line under the selected item
                    &::after {
                        position: absolute;
                        left: 0;
                        top: calc(100% - 3px);
                        width: 100%;
                        content: '\0020';
                        display: block;
                        height: 2px;
                        background: $global-nav-level-0-selected-highlight-color;
                    }
                }
            }

            &.nx-has-sub-level {
                position: relative;
                padding-right: 0;

                > .nx-global-nav-level-0-item {
                    padding-right: 20px;
                }

                &.nx-open {
                    > .nx-global-nav-level-1 {
                        display: block;
                        z-index: $global-nav-level-1-zindex;
                    }
                }

                .nx-icon-caret {
                    right: -8px;
                    top: 7px;
                }
            }

            &.nx-global-nav-user {
                padding-left: 0;
            }
        }
    }

    .nx-global-nav-level-0-item {
        @include nav-item($global-nav-item-text-color, $global-nav-item-text-color-hover);
        padding: 16px 0;
        display: inline-block;
    }

    button.nx-global-nav-level-0-item {
    border: 0;
    background-color: transparent;
    }

    .nx-global-nav-user {
        > .nx-global-nav-level-0-item {
            padding-left: 20px;
        }

        .nx-icon-user {
            left: -10px;
            top: 8px;
        }
    }

    .nx-global-nav-level-1 {
        @include reset-list;
        @include box-shadow(2);
        position: absolute;
        background-color: $global-nav-level-1-bg-color;
        display: none;
        margin: 9px 0 0;
        padding: 5px 0;
        top: 100%;
        border-radius: $border-radius-base;

        .nx-pointer {
            position: absolute;
            top: -16px;
            height: 8px;
            border: 8px solid;
            border-color: transparent transparent $global-nav-level-1-bg-color;
        }

        &.nx-left {
            // Align text in nav-level-1 with text in nav-level-0
            left: 1px;

            .nx-pointer {
                left: 13px;
            }
        }

        &.nx-center {
            left: 5px; // Consider width of <li>
            margin-left: -50%;

            .nx-pointer {
                left: 50%;
                margin-left: -8px;
            }
        }

        &.nx-right {
            right: -8px;

            .nx-pointer {
                right: 7px;
            }
        }

        > li {
            display: block;

            &.nx-has-divider {
                border-top: 1px solid $global-nav-level-1-divider-color;
            }
        }
    }

    a.nx-global-nav-level-1-item {
        @include nav-item($global-nav-level-1-text-color, $global-nav-level-1-text-color-hover, $global-nav-level-1-bg-color-hover);
        display: block;
        min-width: 150px;
        padding: 14px 15px;
    }

    .nx-global-nav-icon {
        transition: fill $transition-default-duration $transition-default-function;
        fill: currentColor;
        width: 32px;
        height: 32px;
        position: absolute;
    }
}
