.nx-notification {
  position: fixed;
  left: 0;
  right: 0; }

.nx-notification-alert {
  position: relative;
  border-radius: 0px;
  padding: 6px 20px 6px 36px;
  background-position: 0 4px;
  background-size: 36px 36px;
  border: none;
  margin-bottom: 0; }
  .nx-notification-alert .nx-icon {
    top: -3px; }
