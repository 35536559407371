$btn-icon-size: 36px;
$btn-reset-width: 30px;
$hover-color: $color-peach-sorbet;

.nx-remote-path-builder {
    z-index: 22000;
    .nx-error-message.with-refresh-button {
        width: calc(100% - #{$btn-reset-width});
    }
    .nx-btn-folder {
        position: absolute;
        border: 0;
        transition: background 0.2s ease-in-out;
        z-index: 2;
        height: 34px;
        width: 35px;
        top: 2px;
        right: 1px;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .nx-remote-path-builder-input-refresh {
        .nx-remote-path-builder-input-wrapper {
            width: calc(100% - #{$btn-reset-width});
            display: inline-block;
            .nx-remote-path-builder-input {
                position: relative;
                > .btn {
                    top: 1px;
                    background: transparent;
                    color: $color-taupe-gray;
                }
                .has-focus {
                    + .nx-btn-folder {
                        height: 33px;
                    }
                }
                input {
                    min-height: 37px;
                    background: $color-white;
                    position: relative;
                    width: 100%;
                    border: 1px solid $color-gray-light;
                    border-radius: 4px;
                    padding: 0 $btn-icon-size 0 10px;
                    color: $color-gray-medium;
                }
            }
        }
        .btn-reset {
            position: absolute;
            vertical-align: top;
            z-index: 22000;
            border-color: transparent;
            height: $btn-icon-size;
            cursor: pointer;

            &:disabled {
                background-color: transparent;
            }
            &:hover {
                background: transparent;
            }
        }
    }
    .nx-path-picker-overlay {
        width: 100vw;
        height: 100vh;
        z-index: 21000;
        top: 0;
        left: 0;
        position: fixed;
        cursor: default;
        opacity: 0;
    }
}

.nx-portal {
    position: static;
    .nx-remote-path-builder-path-list-wrapper {
        z-index: 22000;
        visibility: visible;
        .nx-remote-path-builder-path-list {
            background-color: $color-white;
            box-shadow: 1px 1px 2px 0 $color-black;
            border: 1px solid $color-gray-light;
            border-radius: 4px;
            margin-top: 7px;
            position: absolute;
            z-index: 22000;
            width: 100%;
            .nx-breadcrumb {
                padding: 10px 12px;
                background-color: $color-zinc-gray;
                color: $color-gray-light;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                .btn-link {
                    color: $color-white;
                    cursor: pointer;
                    text-decoration: none;
                    transition: color 0.3s ease;
                    display: inline-block;
                    word-break: break-all;
                    white-space: normal;
                    &:not(:last-child)::after {
                        content: '/';
                        display: inline-block;
                        padding: 0 1ex;
                    }
                    &:active,
                    &:hover,
                    &:focus,
                    &.focus {
                        text-decoration: underline;
                        box-shadow: none;
                    }
                }
            }
            .nx-remote-path-builder-loader {
                overflow: hidden;
                .nx-centered {
                    height: 120px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;

                    .nx-spinner-container {
                        justify-content: center;
                    }
                }
                .nx-remote-path-builder-message {
                    overflow: hidden;
                    text-align: center;
                    padding-bottom: 15px;
                }
            }
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                width: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                li {
                    display: block;
                    line-height: 100%;
                    color: $color-gray-medium;
                    width: 100%;
                    .btn {
                        transition: none;
                    }
                    >.btn-link {
                        padding: 10px 12px 10px 8px;
                        display: block;
                        color: $color-taupe-gray;
                        cursor: pointer;
                        width: 100%;
                        text-align: left;
                        .nx-btn-icon-wrapper {
                            height: 11px;
                            top: -3px;
                        }
                        span {
                            padding-left: 4px;
                        }
                        &.active:focus,
                        &:active:focus,
                        &:hover,
                        &:focus,
                        &.focus {
                            background-color: $hover-color;
                            color: $color-taupe-gray;
                            text-decoration: none;
                            box-shadow: none;
                        }
                    }
                    &.has-focus {
                        >.btn-link {
                            background-color: $hover-color;
                            color: $color-taupe-gray;
                            text-decoration: none;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
        &.hidden {
            visibility: hidden;
        }
        &:not(.hidden) {
            .nx-remote-path-builder-path-list {
                &::before {
                    content: ' ';
                    position: absolute;
                    right: 7px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    z-index: 22001;
                }
                &:not(.top)::before {
                    top: -7px;
                    border-width: 0 8px 7px 8px;
                    border-color: transparent transparent $color-zinc-gray transparent;
                }
                &.top::before {
                    bottom: -7px;
                    border-width: 7px 8px 0 8px;
                    border-color: $color-white transparent transparent transparent;
                }
            }
        }
    }
}
