@import "../theme/theme";

$config-panel-navigator-padding: 12px;
$config-panel-navigator-icon-padding: $padding-small / 2;
$config-panel-navigator-text-padding: $config-panel-navigator-padding / 2;

.nx-config-panel-navigator {
    display: block;
    width: 100%;
    border-radius: $border-radius-base;
    background-color: $color-white;
    border: 1px solid $color-nintex-orange;
    text-align: left;
    padding: $config-panel-navigator-padding 0 $config-panel-navigator-padding $config-panel-navigator-padding;

    &:hover {
        background-color: $color-peach-sorbet;
        border-color: $color-nintex-orange;
    }

    &:focus {
        outline: none;
    }

    div {
        float: left;

        h4 {
            margin-bottom: $config-panel-navigator-text-padding;
            color: $color-nintex-orange;
        }

        p {
            margin-bottom: 0;
            color: $color-taupe-gray;
            
        }
    }

    .nx-config-panel-navigator-icon {
        float: right;
        color: $color-taupe-gray;
        margin: $config-panel-navigator-icon-padding (-$config-panel-navigator-icon-padding) 0 0;
    }
}

.nx-config-panel-navigator-back {
    margin-bottom: $padding-small;
    padding-left: 0.525rem;
}