@mixin select-color-disabled($foregroundColor, $backgroundColor) {
  .nx-select__control--is-disabled {
    cursor: not-allowed;
    background-color: $backgroundColor;
    border-color: $backgroundColor;
    .nx-select__single-value {
        color: $foregroundColor;
    }
  }

  .nx-select__multi-value--is-disabled {
    background-color: $backgroundColor;
  } 
}

@mixin select-input-and-values-disabled-colors($foregroundColor, $backgroundColor) {
  .nx-select__control {
    &.nx-select__control--is-disabled {
      cursor: not-allowed;
      background-color: $backgroundColor;
      border-color: $backgroundColor;
      .nx-select__single-value.nx-select__single-value--is-disabled {
          color: $foregroundColor;
      }
    }
  
    .nx-select__multi-value.nx-select__multi-value--is-disabled {
      background-color: $backgroundColor;
      border-color: $foregroundColor;
      color: $foregroundColor;
      
      .nx-select__multi-value__label {
        color: $foregroundColor;
        cursor: default;
        padding-right: 6px;
      }

      .nx-select__multi-value__remove {
        display: none;
      }
    } 
  }
}