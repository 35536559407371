.nx-side-nav {
  overflow-y: auto;
  width: 200px;
  z-index: 10;
  background-color: #F1F1F1; }
  .nx-side-nav .nx-nav-group-line {
    display: block;
    margin: 0 auto;
    width: 90%;
    height: 1px;
    background-color: #CBCBCB; }
  .nx-side-nav .nx-nav-group {
    margin: 0;
    padding: 0;
    padding-top: 20px;
    list-style-type: none; }
    .nx-side-nav .nx-nav-group:first-child {
      padding-top: 15px; }
    .nx-side-nav .nx-nav-group:last-child {
      padding-bottom: 15px; }
    .nx-side-nav .nx-nav-group.nx-nav-group-no-gap {
      padding-top: 0; }
    .nx-side-nav .nx-nav-group a {
      color: #5B5B5B;
      background-color: transparent;
      cursor: pointer;
      padding: 12px 20px;
      text-decoration: none;
      display: flex;
      align-items: center;
      transition: background 0.3s ease-in-out; }
      .nx-side-nav .nx-nav-group a.nx-selected, .nx-side-nav .nx-nav-group a.selected {
        color: #E06E18;
        background-color: #E6E6E6;
        cursor: pointer;
        position: relative;
        border-left: 4px solid #E06E18;
        padding-left: 16px; }
      .nx-side-nav .nx-nav-group a:not([class*='selected']), .nx-side-nav .nx-nav-group a:not([class*='nx-selected']) {
        transition: color 0.3s ease-in-out; }
        .nx-side-nav .nx-nav-group a:not([class*='selected']):hover, .nx-side-nav .nx-nav-group a:not([class*='nx-selected']):hover {
          color: #949494; }
      .nx-side-nav .nx-nav-group a .nx-btn-icon-wrapper {
        margin-right: 10px;
        align-self: flex-start;
        top: 0; }
  .nx-side-nav.nx-dark {
    background-color: #404040; }
    .nx-side-nav.nx-dark .nx-nav-group a {
      color: #CBCBCB;
      background-color: transparent; }
      .nx-side-nav.nx-dark .nx-nav-group a.nx-selected, .nx-side-nav.nx-dark .nx-nav-group a.selected {
        color: #E06E18;
        background-color: #323232; }
      .nx-side-nav.nx-dark .nx-nav-group a:not([class*='nx-selected']):hover, .nx-side-nav.nx-dark .nx-nav-group a:not([class*='selected']):hover {
        color: #FFF; }
    .nx-side-nav.nx-dark .nx-nav-group .nx-nav-group-line {
      background-color: #323232; }
  .nx-side-nav.nx-with-global-header {
    top: 46px; }

.nx-canvas.nx-with-side-nav {
  padding-left: 200px; }
