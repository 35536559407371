// Nintex Modular Style Guide Blue Theme
// ========================================
//
// Table of contents:
//
//  1. Colors
//  2. Body
//  3. Canvas
//  4. Global navigation
//  5. Side navigation
//  6. Links
// xx. Forms
// xx. Panels
// xx. Modals
// xx. Tables
// xx. Action menu
// xx. Modals
// xx. Alerts
// xx. Spinner
// xx. Card
// xx. Tile
// xx. Animation
// xx. Tabs
// xx. Tooltip
// xx. Placeholder
// xx. Breadcrumbs
// xx. Toolbar
// xx. Flag
// xx. Datetime
// xx. Select

@import '../color';

@function hover-composite($color) {
  @return mix($color-nintex-blue-20, $color, 12%);
}

@function selected-composite($color) {
  @return mix($color-nintex-blue-20, $color, 25%);
}

/// 1. Colors
// ----------
$primary: $color-nintex-blue;
$success: $color-nintex-green;
$info: $color-nintex-blue;
$danger: $color-nintex-red;

// 2. Body
// -------
//
// Settings for the `<body>` element.
$body-color: $color-nintex-grey-90;

// 3. Global navigation
// --------------------

// ### Global header (for layout)
$global-header-bg-color: $color-nintex-blue-80;
$global-header-logo-base-color: $color-white;
$global-header-logo-accent-color: $color-nintex-orange-2;
$global-header-logo-icon-color: $color-white;
$global-header-breadcrumbs-text-color: $color-white;
$global-header-breadcrumbs-link-color: $color-nintex-blue-30;

// ### Global navigation
$global-nav-item-text-color: $color-white;
$global-nav-item-hover-bg-color: $color-nintex-blue-hover-overlay;
$global-nav-item-text-color-hover: $global-nav-item-text-color;
$global-nav-selected-highlight-color: $color-nintex-orange-2;
$global-nav-selected-highlight-height: 4px;
$global-nav-item-selected-text-color: $global-nav-item-text-color;
$global-nav-item-link-padding: 0 4px;
$global-nav-meatball-menu-color: $color-white;
$global-nav-meatball-menu-hover-color: $color-white;
$global-nav-meatball-menu-use-secondary-styling: false;
$global-nav-meatball-menu-badge-bg-color: $color-nintex-green-40;
$global-nav-meatball-menu-badge-color: $color-nintex-grey-90;

// 5. Side navigation
// --------------------

// ### Side navigation
$side-nav-border-right: 1px solid $color-nintex-grey-10;
$side-nav-nav-group-line-display: none;
$side-nav-bg-color-light: $color-nintex-grey-5;
$side-nav-item-color: $body-color;
$side-nav-item-selected-color: $color-nintex-blue;
$side-nav-item-selected-bg-color: $color-white;
$side-nav-item-selected-border-left-color: $color-nintex-orange-2;
$side-nav-item-hover-color: $body-color;
$side-nav-item-hover-bg-color: $color-nintex-blue-hover-overlay;

$side-nav-bg-color-dark: $color-nintex-grey-5;
$side-nav-item-color-dark: $body-color;
$side-nav-item-selected-color-dark: $color-nintex-blue;
$side-nav-item-selected-bg-color-dark: $color-white;
$side-nav-item-hover-color-dark: $body-color;

// 6. Links
// --------
//
// Style anchor elements.
$link-color: $color-nintex-blue;
$link-hover-color: $color-nintex-blue;

// ### Ancillary colors
$field-keyline: $color-nintex-grey-30;

// xx. Forms
// ---------

$border-radius-base: 6px;
$input-color: $body-color;
$input-placeholder-color: $color-nintex-grey-50;
$input-disabled-bg: $color-nintex-grey-20;
$input-disabled-color: $color-nintex-grey-70;
$input-disabled-border-color: $input-disabled-bg;
$input-read-only-color: $color-nintex-grey-90;
$input-read-only-bg-color: $color-nintex-grey-20;
$input-read-only-border-color: $input-read-only-bg-color;
$input-focus-border-color: $color-nintex-blue;
$input-border-color: $color-nintex-grey-30;
$input-read-only-focus-border-color: $input-read-only-bg-color;
$input-placeholder-color: $color-nintex-grey-50;
$input-group-addon-bg: $color-nintex-grey-20;
$input-group-addon-color: $color-nintex-grey-90;
$input-group-addon-border-color: $color-nintex-grey-30;
$input-error-bg-color: $color-nintex-red;
$input-error-border-color: $color-nintex-red;
$input-clear-button-hover-bg-color: $color-nintex-blue-hover-overlay;

// ### Radios & Checkboxes
$checkbox-border-color: $color-nintex-grey-60;
$checkbox-selected-bg-color: $color-nintex-blue;
$checkbox-selected-border-color: $checkbox-selected-bg-color;
$checkbox-disabled-bg-color: $color-nintex-grey-20;
$checkbox-disabled-border-color: $checkbox-disabled-bg-color;
$checkbox-disabled-label-color: $color-nintex-grey-40;
$checkbox-focus-outline-color: $color-nintex-blue-outline-overlay;
$checkbox-unselected-focus-bg-color: hover-composite($color-white);
$checkbox-unselected-focus-border-color: $color-nintex-grey-60;
$checkbox-selected-focus-bg-color: hover-composite($color-nintex-blue);
$checkbox-selected-focus-border-color: $checkbox-selected-focus-bg-color;

// ### Checkbox toggle
$checkbox-toggle-selected-bg-color: $color-nintex-blue;
$checkbox-toggle-selected-text-color: $color-nintex-grey;
$checkbox-toggle-unselected-text-color: $color-nintex-grey;
$checkbox-toggle-unselected-bg-color: $color-nintex-grey-60;
$checkbox-toggle-disabled-text-color: $color-nintex-grey-30;
$checkbox-toggle-disabled-bg-color: $color-nintex-grey-20;
$checkbox-toggle-focus-outline-color: $color-nintex-blue-outline-overlay;
$checkbox-toggle-focus-bg-color: hover-composite($color-nintex-blue);

// ### Radio toggle
$radio-toggle-unselected-border-color: $field-keyline;
$radio-toggle-unselected-text-color: $input-color;
$radio-toggle-selected-border-color: $color-nintex-blue;
$radio-toggle-selected-bg-color: $color-nintex-blue;
$radio-toggle-hover-bg-color: $color-nintex-blue-5;
$radio-toggle-hover-text-color: $input-color;
$radio-toggle-dark-unselected-border-color: $field-keyline;
$radio-toggle-dark-selected-border-color: $color-nintex-blue;
$radio-toggle-dark-selected-bg-color: $color-nintex-blue;
$radio-toggle-dark-label-bg-color: $color-white;
$radio-toggle-dark-label-hover-bg-color: $color-nintex-blue-5;
$radio-toggle-dark-label-unselected-text-color: $input-color;
$radio-toggle-dark-label-selected-text-color: $color-white;
$radio-toggle-dark-disabled-unselected-bg-color: $color-nintex-grey-10;
$radio-toggle-disabled-unselected-bg-color: $color-nintex-grey-10;
$radio-toggle-disabled-border-color: $color-nintex-grey-10;
$radio-toggle-disabled-selected-bg-color: $input-disabled-bg;
$radio-toggle-disabled-text-color: $color-nintex-grey-70;
$radio-toggle-disabled-selected-text-color: $color-nintex-grey-70;
$radio-toggle-label-focus-color: $color-nintex-blue-outline-overlay;
$radio-toggle-dark-label-focus-color: $radio-toggle-label-focus-color;

// xx. Panels
// ----------
$panel-heading-color: $color-nintex-orange-2;
$panel-header-background-color: $color-white;
$panel-header-btn-close-color: currentColor;
$panel-header-btn-close-bg-color: $color-nintex-blue-hover-overlay;
$panel-header-btn-close-height: auto;
$panel-header-btn-close-width: auto;
$panel-header-btn-close-icon-position: initial;
$panel-header-btn-close-include-focus-shadow: true;
$panel-extension-wrapper-color: $color-nintex-grey-90;
$panel-extension-wrapper-margins: 0 15px 15px 15px;
$panel-extension-wrapper-background-color: $color-nintex-grey-2;
$panel-extension-wrapper-border-color: $color-nintex-grey-10;
$panel-extension-wrapper-border-width: 1px;
$panel-extension-wrapper-border-radius: 6px;
$panel-display-dividers-visibiliity: block;
$panel-summary-border-top: none;

// xx. Tables
// ----------
$table-heading-bg-color: $color-white;
$table-heading-color: $color-nintex-grey-60;
$table-show-row-dividers: true;
$table-hover-bg: $color-nintex-blue-hover-overlay;
$table-active-bg: selected-composite($color-white);
$table-active-row-hover-bg-color: hover-composite($table-active-bg);
$table-active-row-border-color: $color-nintex-orange-2;
$table-row-action-hover-bg-color: $color-nintex-blue-hover-overlay;
$table-row-action-hover-color: $color-nintex-blue;
$table-edge-color: $color-nintex-grey-10;

// 12. Buttons
// -----------
$input-btn-focus-width: .25rem;
$btn-primary-border-color: $color-nintex-blue-70;
$btn-primary-bg-hover: hover-composite($color-nintex-blue-60);
$btn-primary-focus-outline-color: $color-nintex-blue-outline-overlay;
$btn-secondary-border-color: $color-nintex-grey-10;
$btn-secondary-bg-hover: hover-composite($color-white);
$btn-secondary-color: $color-nintex-blue;
$btn-secondary-color-hover: $color-nintex-blue;
$btn-secondary-focus-border-color: $color-nintex-grey-10;
$btn-secondary-active-bg-color: $color-nintex-blue;
$btn-danger-bg-hover: scale-color($color-nintex-red-40, $lightness: 25%);
$btn-danger-border-color: $color-nintex-red-60;
$btn-danger-focus-outline-color: transparentize($color-nintex-red-40, .88);
$btn-danger-active-bg-color: $color-nintex-red-60;
$btn-inverse-bg-hover: $color-nintex-blue-hover-overlay;
$btn-inverse-focus-bg-color: $color-nintex-blue-hover-overlay;
$btn-inverse-active-bg-color: $color-nintex-blue-outline-overlay;
$btn-inverse-disabled-solid: true;
$btn-disabled-bg: $color-nintex-grey-20;
$btn-disabled-border: $color-nintex-grey-20;
$btn-disabled-color: $color-nintex-grey-70;
$btn-disabled-opacity: 1;
$btn-text-color: $color-nintex-blue;
$btn-text-hover-color: $color-nintex-blue;
$btn-text-hover-bg-color: $color-nintex-blue-hover-overlay;
$btn-text-focus-background-color: $color-nintex-blue-hover-overlay;
$btn-link-hover-color: $color-nintex-blue;
$btn-link-focus-text-decoration: underline;
$btn-link-disabled-text-color: $body-color;
$btn-link-disabled-text-bg-color: transparent;
$btn-link-text-decoration-color: $color-nintex-blue;
$btn-link-hover-bg-color: transparent;

// xx. Config Panel
$config-panel-color: $body-color;
$config-panel-bg-color: $color-white;
$config-panel-box-shadow: 0 0 8px $color-nintex-grey-30;
$config-panel-header-border-left-color: $color-nintex-orange-2;
$config-panel-header-bottom-divider-color: $color-nintex-grey-10;
$config-panel-sub-heading-color: $color-nintex-grey;
$config-panel-section-border-left-color: $color-white;
$config-panel-section-color: $color-nintex-grey;
$config-panel-section-h3-border-bottom: 1px solid $color-nintex-grey-10;
$config-panel-has-footer: false;

// xx. Config Panel Card
$config-panel-card-header-bg-color: hover-composite($color-white);
$config-panel-editing-action-btn-color: $color-nintex-blue;
$config-panel-card-editing-bg-color: $color-nintex-grey-2;
$config-panel-card-color: $color-nintex-grey-50;
$config-panel-card-border-color: $color-nintex-grey-10;
$config-panel-card-editing-color: $body-color;
$config-panel-card-editing-headers-color: $color-nintex-grey;

// xx. Modals
// ----------
$modal-overlay-bg-color: transparentize($color-nintex-grey-90, 0.4);
$modal-dialog-header-color: $color-nintex-orange-2;
$modal-dialog-header-bg-color: $color-white;
$modal-dialog-header-close-is-gray-svg: false;
$modal-dialog-header-btn-close-color: $body-color;
$modal-dialog-header-btn-close-bg-color: $color-nintex-blue-hover-overlay;
$modal-dialog-footer-bg-color: $color-white;
$modal-dialog-display-dividers-visibility: block;

// xx. Action menu
// ---------------
$action-menu-box-shadow: 0 0 8px transparentize($color-nintex-grey-30, 0.5);
$action-menu-text-color: $body-color;
$action-menu-text-color-hover: $body-color;
$action-menu-text-color-disabled: $color-nintex-grey-70;
$action-menu-keyline: $color-nintex-grey-5;
$action-menu-bg-color: $color-white;
$action-menu-bg-color-hover: $color-nintex-blue-selected-overlay;
$action-menu-tail-border-color: $color-nintex-grey-10;
$action-menu-border: 1px solid $color-nintex-grey-10;
$action-menu-item-hover-bg-color: $color-nintex-blue-selected-overlay;
$action-menu-item-hover-color: $body-color;
$action-menu-header-border-color: $color-nintex-grey-10;
$action-menu-header-child-element-color: $body-color;

// xx. Alerts
// ----------
$alert-information-bg-color: $color-nintex-blue;
$alert-success-bg-color: $color-nintex-green;
$alert-warning-bg-color: $color-nintex-yellow;
$alert-warning-text-color: $color-nintex-grey-90;
$alert-danger-bg-color: $color-nintex-red;

// xx. Spinner
// -----------
$status-spinner-color: $color-nintex-grey-90;
$spinner-theme-regular-left-color: $color-nintex-grey-90;
$spinner-theme-regular-right-color: $color-nintex-grey-50;
$spinner-theme-light-right-color: $color-nintex-grey-20;

// xx. Tooltip
// -----------------
$tooltip-default-bg-color: $color-white;
$tooltip-default-text-color: $color-nintex-grey-90;
$tooltip-information-bg-color: $color-nintex-blue;
$tooltip-success-bg-color: $color-nintex-green-50;
$tooltip-warning-bg-color: $color-nintex-yellow-50;
$tooltip-warning-text-color: $color-nintex-grey-90;
$tooltip-danger-bg-color: $color-nintex-red-50;
$tooltip-light-bg-color: $color-white;
$tooltip-light-text-color: $color-charcoal;
$tooltip-light-border-color: $color-gray-light;
$tooltip-box-shadow: 0 0 8px transparentize($color-nintex-grey-30, 0.5);

// xx. Placeholder
// ----------
$placeholder-color: $color-nintex-grey-5;
$placeholder-shimmer-bg: linear-gradient(to right, $placeholder-color 4%, mix($color-nintex-grey-10, $placeholder-color, 75%) 25%, $placeholder-color 36%);

// xx. Breadcrumbs
// ---------------
$breadcrumbs-text-color: $body-color;
$breadcrumbs-link-color-inverse: $color-nintex-blue-30;
$breadcrumb-link-hover-decoration: underline;
$breadcrumb-icon-light-color: inherit;

// xx. Toolbar
// -----------
$toolbar-background-color: $color-white;
$toolbar-item-color: $color-nintex-grey-90;
$toolbar-item-disabled-color: $color-nintex-grey-30;
$toolbar-item-hover-color: $color-nintex-blue-60;
$toolbar-item-hover-bg-color: $color-nintex-blue-hover-overlay;
$toolbar-separator-color: $color-nintex-grey-10;
$toolbar-box-shadow: 0px 0px 8px transparentize($color-nintex-grey-30, 0.5);
$toolbar-sub-menu-box-shadow: 0 0 8px 0 transparentize($color-nintex-grey-30, 0.5);
$toolbar-sub-menu-border: 1px solid $color-nintex-grey-10;
$toolbar-sub-menu-item-color: $color-nintex-grey-90;
$toolbar-sub-menu-item-hover-color: $color-nintex-blue-hover-overlay;
$toolbar-inverse-bg-color: $toolbar-background-color;
$toolbar-inverse-separator-color: $toolbar-separator-color;
$toolbar-inverse-item-hover-bg-color: $toolbar-item-hover-bg-color;
$toolbar-inverse-item-disabled-color: $toolbar-item-disabled-color;
$toolbar-inverse-item-color: $toolbar-item-color;
$toolbar-inverse-item-hover-color: $toolbar-item-hover-color;

// xx. Flag
// --------
$flag-unconfigured-bg-color: $color-nintex-orange-2;
$flag-alert-bg-color: $color-nintex-yellow;
$flag-alert-icon-color: $color-nintex-grey-90;
$flag-error-bg-color: $color-nintex-red;
$flag-success-bg-color: $color-nintex-green;

// xx. Datetime
// ------------
$datetime-icon-color: $color-nintex-grey-90;
$datetime-icon-fill: $color-nintex-blue;

$datetime-months-text-color: $body-color;
$datetime-prev-next-month-hover-color: $color-nintex-blue;

$datetime-weekday-color: $color-nintex-grey;
$datetime-weekdays-top-bottom-border: none;

$datetime-day-hover-color: $color-nintex-blue-hover-overlay;
$datetime-day-focus-border-color: $color-nintex-blue-hover-overlay;
$datetime-range-color: selected-composite($color-white);
$datetime-start-end-range-coinciding-box-shadow-color: $datetime-range-color;
$datetime-start-end-range-bg-color: selected-composite($color-white);
$datetime-start-end-range-text-color: $body-color;
$datetime-today-font-weight: normal;
$datetime-today-border-color: $body-color;
$datetime-disabled-day-text-color: $color-nintex-grey-30;
$datetime-prev-next-month-day-text-color: $color-nintex-grey-50;
$datetime-prev-next-month-day-box-shadow: none;
$datetime-today-hover-border-color: $datetime-today-border-color;

$datetime-time-section-border-top-color: $color-nintex-grey-10;
$datetime-time-sections-hover-color: $color-nintex-blue-hover-overlay;
$datetime-time-hour-font-weight: normal;

$datetime-input-text-color: $body-color;
$datetime-input-disabled-text-color: $color-nintex-grey-70;
$datetime-input-placeholder-text-color: $color-nintex-grey-50;
$datetime-input-border-color: $color-nintex-grey-30;
$datetime-input-read-only-bg-color: $color-white;
$datetime-input-read-only-focus-border-color: $color-nintex-blue;
$datetime-disabled-cursor: not-allowed;
$datetime-btn-date-picker-hover-color: transparent;

// xx. Select
// ----------
$select-border-color: $color-nintex-grey-30;
$select-focus-border-color: $color-nintex-blue;
$select-warning-border-color: $color-nintex-yellow;
$select-warning-bg-color: $color-nintex-yellow;
$select-warning-text-color: $body-color;
$select-error-border-color: $color-nintex-red;
$select-error-bg-color: $color-nintex-red;
$select-disabled-bg-color: $color-nintex-grey-20;
$select-disabled-text-color: $color-nintex-grey-70;
$select-input-text-color: $body-color;
$select-indicators-color: $body-color;

$select-option-selected-bg-color: selected-composite($color-white);
$select-option-selected-text-color: $body-color;
$select-option-hover-bg-color: $color-nintex-blue-hover-overlay;
$select-option-subtext-text-color: $color-nintex-grey-50;
$select-option-selected-subtext-text-color: $select-option-subtext-text-color;
$select-option-action-text-color: $color-nintex-blue;
$select-option-action-active-selected-text-color: $select-option-action-text-color;
$select-option-action-bottom-border-color: $color-nintex-grey-5;
$select-no-option-text-color: $color-nintex-grey-70;
$select-group-heading-text-color: $color-nintex-grey;

$select-menu-border-color: $select-focus-border-color;
$select-menu-top-bottom-border-color: $color-nintex-grey-5;
$select-menu-text-color: $body-color;

$select-multi-value-border-color: $body-color;
$select-multi-value-text-color: $body-color;
$select-multi-value-hover-bg-color: $color-nintex-blue-hover-overlay;
$select-multi-value-remove-hover-bg-color: hover-composite($select-multi-value-hover-bg-color);