@import '../mixins/modal';
@import "../mixins/utils";
@import "../mixins/button";

// min-height & max-height refer to the viewport height.
// These are estimates based on browser chrome occupying 140px
// of screen height.
$viewport-small-height: 768px - 140;
$viewport-large-height: 1080px - 140;

/* rtl:ignore-start */
.nx-modal-dialog {
  &.nx-dialog-small {
    width: $modal-dialog-small-width;
  }

  &.nx-dialog-medium {
    $modal-dialog-medium-fluid-width: calc(100% - 60px);

    @media screen and (max-width: 767px) {
      min-width: $modal-dialog-small-width;
      width: $modal-dialog-medium-fluid-width;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: $modal-dialog-medium-fluid-width;
      max-width: $modal-dialog-medium-width;
    }

    @media screen and(min-width: 1024px) {
      width: $modal-dialog-medium-width;
    }
  }

  &.nx-dialog-large {
    position: relative;
    margin: $modal-dialog-margin / 2;
    width: calc(100% - #{$modal-dialog-margin});
    height: calc(100% - #{$modal-dialog-margin});
    margin: $modal-dialog-margin / 2;
    overflow: hidden;

    > footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  .nx-modal-dialog-header,
  > header {
    position: relative;
    padding: 15px 20px 14px;
    background-color: $modal-dialog-header-bg-color;
    width: 100%;
    border-top-left-radius: $modal-dialog-border-radius;
    border-top-right-radius: $modal-dialog-border-radius;

    h1 {
      color: $modal-dialog-header-color;
      font-size: $modal-dialog-heading-1-font-size;
      margin: 0;
    }

    hr {
      border-top: 1px solid $color-nintex-grey-10;
      margin-bottom: 0;
      display: $modal-dialog-display-dividers-visibility;
    }
  }

  .nx-modal-dialog-body {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;

    > h2 {
      margin: 0 -30px;
      padding: 0 30px 20px;
      border-bottom: 1px solid $color-keyline;
    }
  }

  h2 {
    font-size: $modal-dialog-heading-2-font-size;
  }

  h3 {
    font-size: $modal-dialog-heading-3-font-size;
  }

  &.nx-dialog-small {
    // In general, most dialogs will have a header.
    // Some small information dialogs may not,
    // but as part of the dressing of the dialog
    // require the heading to appear without text.
    > header {
      min-height: 56px;
    }

    .nx-modal-dialog-body {
      padding: 40px 30px;

      @media screen and (min-height: $viewport-large-height) {
        max-height: 600px;
      }

      @media screen and (min-height: $viewport-small-height) and (max-height: ($viewport-large-height - 1)) {
        max-height: 320px;
      }
    }
  }

  &.nx-dialog-medium {
    .nx-modal-dialog-body {
      padding: 20px 30px;
      min-height: 200px;

      @media screen and (min-height: $viewport-large-height) {
        max-height: 800px;
      }

      @media screen and (min-height: $viewport-small-height) and (max-height: ($viewport-large-height - 1)) {
        max-height: 500px;
      }

      @media screen and (max-height: $viewport-small-height - 1) {
        max-height: 350px;
      }
    }
  }

  &.nx-dialog-large {
    .nx-modal-dialog-body {
      padding: 20px 30px;
    }
  }

  > footer {
    > hr {
        border-top: 1px solid $color-nintex-grey-10;
        margin-top: 0;
        display: $modal-dialog-display-dividers-visibility;
    }
    padding: $modal-dialog-footer-pad-vertical $modal-dialog-footer-pad-horizontal;
    background-color: $modal-dialog-footer-bg-color;
  }

  &.nx-dialog-large > footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &.nx-dialog-input {
    @include modal-dialog-frame;

    .nx-panel > header h3,
    .nx-panel .nx-panel-header h3 {
      margin-top: 3px;
    }

    .nx-dialog-section-panel {
      margin-top: 30px;

      .nx-panel-header {
        position: relative;
        margin-bottom: 25px;

        &.nx-border-bottom {
          border-bottom: 1px solid $color-steel-gray;
        }
      }

      h3 {
        margin-top: 6px;
        width: auto;
      }
    }
  }

  &.nx-dialog-info {
    @include modal-dialog-frame;

    .nx-modal-dialog-body {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      > h2 {
        border-bottom: none;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .nx-dialog-section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    > .nx-dialog-section-header {
      margin: 0 -30px;
      padding: 0 30px 20px;
      border-bottom: 1px solid $color-keyline;
    }

    > .nx-dialog-section-body {
      margin-top: 30px;
    }
  }

  &.nx-dialog-thin {
    .nx-modal-dialog-body {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }
  }

  .nx-dialog-status-text {
    margin: 1em;
    color: $color-white;
    font-size: $font-size-h3;
    text-align: center;
  }

  .nx-spinner-dialog {
    color: $color-white;

    @include status-spinner;
    @include status-spinner-size($height: 84px, $width: 3px, $count: 6);
  }
}
/* rtl:ignore-end */

/* rtl:ignore-start */
.nx-modal-overlay, .nx-modal-dialog-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.nx-modal-overlay {
  position: fixed;
}
.nx-modal-dialog-wrapper {
  position: absolute;
}

.nx-modal-overlay {
  background-color: $modal-overlay-bg-color;
  z-index: 10000;
}

/* rtl:ignore-end */

.nx-dialog-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  position: relative;

  button {
    margin-left: 15px;
  }
}

.nx-modal-dialog {
  .nx-modal-dialog-header,
  > header {
    // Class to include button for styles-core consumption users only
    .nx-btn-close {
      position: absolute;
      z-index: 2;
      right: 8px;
      top: 8px;
      cursor: pointer;
      @include foundation-icon-block(36px);
      @if ($modal-dialog-header-close-is-gray-svg) {
        @include svg-utility-gray-close;
      }
      @else {
        @include svg-utility-nx-grey-90-close;
      }
      background-position: center;
      text-decoration: none;
      border: none;
      background-color: transparent;
      @include element-style-focus-shadow($btn-primary-border);
      @include button-focus-style($btn-primary-focus-outline-color, null, $btn-secondary-focus-border-color);
      outline: none;
      border-radius: $border-radius-base;
    }

    .nx-button-close {
      color: $modal-dialog-header-btn-close-color;
      position: absolute;
      z-index: 2;
      right: 8px;
      top: 8px;
      background-color: transparent;
      @include element-style-focus-shadow($btn-primary-border);
      @include button-focus-style($btn-primary-focus-outline-color, null, $btn-secondary-focus-border-color);
      border-radius: $border-radius-base;

      .nx-icon {
        color: currentColor;
      }

      &:hover {
        background-color: $modal-dialog-header-btn-close-bg-color;
      }
    }
  }
}

.nx-dialog-input {
  .nx-dialog-actions {
    // To use this class, there has to be a primary action button
    .nx-btn-extra {
      position: absolute;
      left: $modal-dialog-footer-pad-horizontal;
      top: $modal-dialog-footer-pad-vertical;
    }
  }
}
