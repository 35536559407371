.nx-json-editor {
  .jsoneditor {
    border: none;
  }

  .ace_editor, textarea.jsoneditor-text {
    min-height: 350px;
  }

  .jsoneditor-tree-inner {
    background: $color-white;
  }

  div.jsoneditor-menu {
    background: $color-charcoal;
    border-bottom: none;
  }

  ul.jsoneditor-menu {
    margin-top: 3px;

    button.jsoneditor-selected, button.jsoneditor-selected:hover {
      background-color: $color-brand-orange;
    }

    button:hover {
      background-color: $color-peach-sorbet;
    }
  }
}
