@import "../theme/theme";
@import "../mixins/button";
@import "../mixins/utils";

.nx-side-nav {
  overflow-y: auto;
  width: $side-nav-width;
  z-index: 10;
  background-color: $side-nav-bg-color-light;
  border-right: $side-nav-border-right;

  .nx-nav-group-line {
    display: $side-nav-nav-group-line-display;
    margin: 0 auto;
    width: 90%;
    height: 1px;
    background-color: $color-gray-light;
  }

  .nx-nav-group {
      margin: 0;
      padding: 0;
      padding-top: 20px;
      list-style-type: none;
      &:first-child {
          padding-top: 15px;
      }
      &:last-child {
          padding-bottom: 15px;
      }

      &.nx-nav-group-no-gap {
        padding-top: 0;  
      }

      li:hover {
        background-color: $side-nav-item-hover-bg-color;
      }
      
      a {
          color: $side-nav-item-color;
          background-color: $side-nav-item-bg-color;
          cursor: pointer;
          padding: 12px 20px;
          text-decoration: none;
          display: flex;
          align-items: center;
          transition: background 0.3s ease-in-out;

          &.nx-selected, &.selected {
              color: $side-nav-item-selected-color;
              background-color: $side-nav-item-selected-bg-color;
              cursor: pointer;
              position: relative;
              border-left: 4px solid $side-nav-item-selected-border-left-color;
              padding-left: 16px;
          }

          &:not([class*='selected']), &:not([class*='nx-selected']) {
              transition: color 0.3s ease-in-out;

              &:hover{
                  color: $side-nav-item-hover-color;
              }
          }

          .nx-btn-icon-wrapper {
              margin-right: 10px;
              align-self: flex-start;
              top: 0;
          }
      }
  }

  &.nx-dark {
      background-color: $side-nav-bg-color-dark;
      .nx-nav-group {
        a {
          color: $side-nav-item-color-dark;
          background-color: $side-nav-item-bg-color-dark;
          &.nx-selected, &.selected {
            color: $side-nav-item-selected-color-dark;
            background-color: $side-nav-item-selected-bg-color-dark;
          }
          &:not([class*='nx-selected']), &:not([class*='selected']) {
            &:hover{
                color: $side-nav-item-hover-color-dark;
            }
        }
      }
      .nx-nav-group-line {
        background-color: $side-nav-liner-color-dark;
      }
    }
  }

  &.nx-with-global-header {
    top: $global-header-height;
  }
}

.nx-canvas {
  &.nx-with-side-nav {
    padding-left: $side-nav-width;
  }
}

@if $navigation-menu-compatibility == true {
  .nx-vertical-nav-level-0 {
    @include reset-list;

    > li {
      border-left: 4px solid $side-nav-bg-color;

      &.nx-selected,
      &.nx-active {
        background-color: $side-nav-bg-color-selected;
        border-left-color: $side-nav-border-color-selected;
        padding-left: 0;

        > .nx-vertical-nav-item {
          color: $side-nav-text-color-selected;
          transition: none;
        }
      }
    }
  }

  .nx-vertical-nav-group {
    padding-top: 20px;
  }

  .nx-vertical-nav-actions {
    @include reset-list;
    padding: 15px 20px;
    border-bottom: 1px solid $color-keyline;

    > li {
      width: 100%;
    }

    + .nx-vertical-nav-group {
      padding-top: 15px;
    }
  }

  .nx-vertical-nav-button {
    width: 100%;
    display: block;
    font-size: 1rem;
    border-radius: $border-radius-base;
    padding: 8px 15px 7px;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }

  .nx-vertical-nav-item {
    display: block;
    color: $side-nav-text-color;
    cursor: pointer;
    transition: color $transition-default-duration $transition-default-function;

    &:hover {
      color: $side-nav-text-color-hover;
      text-decoration: none;
    }

    .nx-vertical-nav-icon {
      float: left;
      fill: currentColor;
      width: 20px;
      height: 29px;
      margin: -6px 8px 0 -8px;
    }
  }

  .nx-vertical-nav-level-0-item {
    padding: 15px 20px 15px 16px;
    font-size: $font-size-lg;

    &.nx-disabled {
      &,
      &:hover,
      &:active {
        color: $side-nav-text-color-disabled;
        text-shadow: 1px 1px $side-nav-text-shadow-disabled;
      }
    }
  }

  .nx-vertical-nav-level-1 {
    list-style-type: none;
    margin: 0 0 0 30px;
    padding: 0;
  }

  .nx-vertical-nav-level-1-item {
    padding: 8px 20px 7px 16px;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 15px;
    }
  }

  .nx-vertical-nav-level-2 {
      list-style-type: none;
      margin: 0 0 0 15px;
      padding: 0;
  }

  .nx-vertical-nav-level-2-item {
      padding: 8px 20px 7px 16px;

      &:first-of-type {
          padding-top: 0;
      }

      &:last-of-type {
          padding-bottom: 15px;
      }
  }
}
