.nx-notification-overlay {
  width: 480px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center; }

.nx-notification-overlay-icon {
  display: inline-block; }
  .nx-notification-overlay-icon .nx-btn-icon-wrapper {
    width: 74px;
    height: 74px;
    vertical-align: top;
    color: #FFF; }
  .nx-notification-overlay-icon .nx-icon {
    width: 150px;
    height: 150px;
    left: -36px;
    top: -36px; }

.nx-notification-overlay-text {
  margin: 0 0 0 0.5em;
  color: #FFF;
  font-size: 24px;
  text-align: left; }

@keyframes simple-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.nx-notification-overlay-fade {
  animation: simple-fade-out 0.6s linear 1s; }
