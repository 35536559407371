.nx-breadcrumbs {
  display: flex;
  color: #5B5B5B;
  align-items: center; }
  .nx-breadcrumbs ul {
    min-width: 100px; }
  .nx-breadcrumbs.nx-breadcrumbs-inverse, .nx-breadcrumbs.nx-breadcrumbs-inverse .nx-breadcrumb-text {
    color: #FFF; }
  .nx-breadcrumbs .nx-breadcrumbs-title {
    margin-right: 8px; }
  .nx-breadcrumbs .nx-action-menu-container > .nx-icon-wrapper {
    width: 30px;
    height: 24px;
    color: #5B5B5B;
    border: 1px solid #CBCBCB;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    background: #FFF; }
    .nx-breadcrumbs .nx-action-menu-container > .nx-icon-wrapper svg {
      color: #000;
      height: 24px;
      width: 24px;
      transform: scale(1.55); }
  .nx-breadcrumbs .btn-link .nx-tooltip-container:hover {
    text-decoration: underline; }
  .nx-breadcrumbs .btn-link:focus {
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .nx-breadcrumbs.nx-breadcrumbs-theme-light svg {
    color: #949494; }
  .nx-breadcrumbs.nx-breadcrumbs-theme-light .nx-action-menu-container > .nx-icon-wrapper svg {
    color: #949494; }
  .nx-breadcrumbs ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 100; }
    .nx-breadcrumbs ul li {
      display: flex;
      align-items: center; }
      .nx-breadcrumbs ul li .nx-icon {
        border: 0px solid #E10D11;
        font-weight: normal; }
      .nx-breadcrumbs ul li .nx-left-icon {
        margin-left: -5px; }
      .nx-breadcrumbs ul li .nx-start-icon {
        margin-right: -5px;
        margin-bottom: -1px; }
      .nx-breadcrumbs ul li a:hover {
        text-decoration: none; }
