.nx-input {
  position: relative;
  padding: 0;
  width: auto; }
  .nx-input .nx-input-control {
    display: block;
    width: 100%;
    height: 37px;
    padding: 7px 12px;
    font-weight: normal;
    line-height: 1.25;
    color: #5B5B5B;
    background-clip: padding-box;
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    transition: all 200ms ease-in-out; }
    .nx-input .nx-input-control:focus {
      border-color: #E06E18; }
    .nx-input .nx-input-control:read-only {
      background: #E6E6E6; }
      .nx-input .nx-input-control:read-only:focus {
        border-color: #CBCBCB; }
    .nx-input .nx-input-control::placeholder, .nx-input .nx-input-control:-ms-input-placeholder {
      color: #CBCBCB; }
  .nx-input .nx-input-search-icon {
    position: absolute;
    margin: -2px 0;
    cursor: pointer; }
  .nx-input .nx-input-filter-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
  .nx-input.nx-input-has-highlight .nx-input-control {
    padding: 6px 11px;
    border-width: 2px;
    border-color: #2070D0; }
  .nx-input.nx-input-has-search .nx-input-control {
    padding-left: 31px; }
  .nx-input.nx-input-has-loading .nx-input-control {
    padding-right: 36px; }
  .nx-input.nx-input-has-reset .nx-input-control {
    padding-right: 36px; }
  .nx-input.nx-input-has-loading.nx-input-has-reset .nx-input-control {
    padding-right: 55px; }
  .nx-input .nx-input-spinner {
    position: absolute;
    top: 0;
    right: 11px;
    width: 14px;
    margin: 10px 5px 10px 0; }
  .nx-input.nx-input-has-reset .nx-input-spinner {
    right: 36px; }
  .nx-input .nx-input-filter-icon {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0; }
  .nx-input.nx-input-has-reset.nx-input-has-filter .nx-input-control {
    padding-right: 72px; }
  .nx-input.nx-input-has-reset.nx-input-has-filter .nx-input-filter-icon {
    margin-right: 25px; }
  .nx-input.nx-input-has-loading.nx-input-has-reset.nx-input-has-filter .nx-input-control {
    padding-right: 108px; }
  .nx-input.nx-input-has-loading.nx-input-has-reset.nx-input-has-filter .nx-input-spinner {
    right: 72px; }
  .nx-input.nx-input-has-error .nx-input-control {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-color: #E10D11; }
  .nx-input.nx-input-has-error .nx-error-message {
    width: 100%;
    display: inline-block;
    padding: 5px 13px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #FFF;
    background-color: #E10D11; }
  .nx-input .nx-btn-clear {
    position: absolute;
    top: 0;
    right: 0;
    margin: -2px 0;
    background: transparent;
    cursor: pointer; }
