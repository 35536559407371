button {
  font-family: inherit;
}

button:disabled {
  background-color: $color-gray-lighter;
}

.btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .nx-spinner-container {
    display: inline-block;
    width: auto;
    margin-right: 10px;

    .nx-spinner {
      color: currentColor;
    }
  }

  &.btn-primary {
    @include button-style-hover($btn-primary-color-hover, $btn-primary-bg-hover, $btn-primary-border-color);
    @include button-style-disabled;
    @include button-focus-style($btn-primary-focus-outline-color, $btn-primary-bg-hover);
    border-color: $btn-primary-border-color;
  }
  &.btn-secondary {
    @extend %secondary-button;
  }
  &.btn-danger {
    @include button-style-hover($btn-primary-color-hover, $btn-danger-bg-hover, $btn-danger-border-color);
    @include button-focus-style($btn-danger-focus-outline-color, $btn-danger-bg-hover, $btn-danger-border-color);
    @include button-active-style(null, $btn-danger-active-bg-color);
    @include button-style-disabled;
    border-color: $btn-danger-border-color;
  }

  &-warning {
    color: $btn-warning-color;
  }

  &.btn-lg{
    border-radius: $btn-border-radius-large;

  }
  &.btn-sm{
    border-radius: $btn-border-radius-small;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    font-size: 14px;
  }
}

.btn-text {
  color: $btn-text-color;
  background: transparent;

  &:hover {
    text-decoration: underline;
    color: $btn-text-hover-color;
    background-color: $btn-text-hover-bg-color;
  }

  @include button-style-focus-shadow($btn-primary-border-color);
  @include button-focus-style($btn-primary-focus-outline-color, $btn-text-focus-background-color);
  @include button-style-disabled;
}

.btn-slim {
  padding: .357rem .75rem;
}

.nx-tooltip-container .nx-btn-icon-wrapper {
  // margin interferes with centering tooltips
  margin-right: 0;
  margin-left: 0;
}

.nx-btn-icon-wrapper {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 14px;
  margin-right: 5px;
  top: -2px;

  &.nx-btn-icon-wrapper-right {
    margin-right: 0;
    margin-left: 5px;
  }

  .nx-icon {
    fill: currentColor;
    position: absolute;
    top: -9px;
    left: -9px;
  }
}

.btn-stateful {
  width: $btn-stateful-width;
  height: $btn-stateful-width;
  padding: 0;
  @include button-style-focus-shadow($btn-primary-border-color);

  &:hover {
    cursor: pointer;
  }
}

.btn-icon-only {
  padding: 0;

  .nx-icon {
    vertical-align: top;
  }
}

.btn-inverse,
.btn-inverse:disabled,
.btn-inverse.btn.disabled {
  @extend %inverse-button;
}

.btn-icon-only, .btn-stateful {
  .nx-icon {
    margin: 0;
  }
}

.btn-stateful, .btn-stateful:hover, .btn-stateful:active, .btn-stateful:focus {
  background: none;
  border: none;
}

.btn.btn-borderless {
  border: none;
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
}

a.btn.disabled,
a.btn[disabled] {
  pointer-events: none;
}

.btn.btn-link {
  padding: 0;
  border: 0;
  color: $btn-text-color;
  text-decoration: none;
  display: inline;

  &:hover {
    text-decoration: underline;
    color: $btn-link-hover-color;
    text-decoration-color: $btn-link-text-decoration-color;
    background-color: $btn-link-hover-bg-color;
  }

  @include button-style-focus-shadow($btn-primary-border-color);
  @include button-focus-style($btn-primary-focus-outline-color, $btn-text-focus-background-color, null, $btn-link-focus-text-decoration);

  &[disabled],
  &.disabled {
      color: $btn-link-disabled-text-color;
      background-color: $btn-link-disabled-text-bg-color;
  }
}

.nx-dark {
  .btn {
    &.btn-outline-secondary {
      @include button-style($btn-outline-secondary-color, $btn-outline-secondary-bg, $btn-outline-secondary-border-color);
      @include button-style-hover($btn-outline-secondary-color-hover, $btn-outline-secondary-bg-hover, $btn-outline-secondary-border-color-hover);
      @include button-style-disabled;
    }

    &.btn-link {
      color: $color-white;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
}

// Turn off accessibility features when use not tabbing
[data-whatintent='mouse'] {
  .btn:focus,
  .btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  .btn-secondary:focus {
    border-color: $btn-secondary-border-color;
  }
}
