@import "../mixins/tooltip";

// Base class
.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  font-size: $font-size-sm;
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;

  &.show {
    opacity: $tooltip-opacity;
  }

  &.tooltip-top,
  &.tooltip-top_start,
  &.tooltip-top_end,
  &.bs-tether-element-attached-bottom {
    padding: $tooltip-arrow-width 0;
    margin-top: -$tooltip-margin;
    .nx-btn-close {
      top: 17px;
      right: 9px;
    }
  }

  &.tooltip-bottom,
  &.tooltip-bottom_start,
  &.tooltip-bottom_end,
  &.bs-tether-element-attached-top {
    padding: $tooltip-arrow-width 0;
    margin-top: $tooltip-margin;
    .nx-btn-close {
      top: 17px;
      right: 9px;
    }
  }

  &.tooltip-left,
  &.tooltip-left_start,
  &.tooltip-left_end,
  &.bs-tether-element-attached-right {
    padding: 0 $tooltip-arrow-width;
    margin-left: -$tooltip-margin;
    .nx-btn-close {
      top: 9px;
      right: 16px;
    }
  }

  &.tooltip-right,
  &.tooltip-right_start,
  &.tooltip-right_end,
  &.bs-tether-element-attached-right {
    padding: 0 $tooltip-arrow-width;
    margin-left: -$tooltip-margin;
    .nx-btn-close {
      top: 9px;
      right: 16px;
    }
  }

  &.nx-popover {
    &.show {
      opacity: 1;
    }
    .tooltip-inner {
      .alert {
        margin: 0;
        padding: 0;
        padding-left: 28px;
        display: flex;
        flex-direction: row;
        .nx-icon {
          min-width: $icon-svg-size;
          min-height: $icon-svg-size;
          position: absolute;
          top: -8px;
          left: -8px;
        }
      }
    }
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  border-style: solid;
  border-width: $tooltip-border-width;
  box-shadow: $tooltip-box-shadow;
  @include border-radius($border-radius);
  &-closeable {
    padding: 10px;
    padding-right: 36px;
  }

  .nx-btn-close {
    position: absolute;
    height: 20px;
    width: 20px;
    &:hover {
      transform: scale(1.5);
      background-color: transparent;
    }
    .nx-icon {
      position: absolute;
      left: -8px;
      top: -8px;
      color: $tooltip-color;
    }
  }
  &::before,
  &::after {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
}

.tooltip {
  font-size: $font-size-base;

  &.tooltip-default {
    @include tooltip-color($tooltip-default-bg-color, $tooltip-default-text-color, $tooltip-default-bg-color);
  }
  &.tooltip-information {
    @include tooltip-color(
      $tooltip-information-bg-color,
      $tooltip-information-text-color,
      $tooltip-information-bg-color
    );
  }

  &.tooltip-success {
    @include tooltip-color($tooltip-success-bg-color, $tooltip-success-text-color, $tooltip-success-bg-color);
  }

  &.tooltip-danger {
    @include tooltip-color($tooltip-danger-bg-color, $tooltip-danger-text-color, $tooltip-danger-bg-color);
  }

  &.tooltip-warning {
    @include tooltip-color($tooltip-warning-bg-color, $tooltip-warning-text-color, $tooltip-warning-bg-color);
  }

  &.tooltip-light {
    @include tooltip-color($tooltip-light-bg-color, $tooltip-light-text-color, $tooltip-light-border-color);
  }
}

.nx-tooltip-container {
  display: inline-block;
  position: relative;
  &.nx-disabled::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

.tooltip {
  &.show {
    left: -99999;
    top: 0;
  }
  &.show.nx-opaque {
    opacity: 1;
  }
}

.nx-tooltip-arrow {
  position: absolute;
  display: block;
  &::before,
  &::after {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }

  &.top,
  &.top_start,
  &.top_end {
    width: (2 * $tooltip-arrow-width);
    height: $tooltip-arrow-height;
    &::before {
      border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    }
    &::after {
      top: -$tooltip-border-width;
      border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    }
  }

  &.bottom,
  &.bottom_start,
  &.bottom_end {
    width: (2 * $tooltip-arrow-width);
    height: $tooltip-arrow-height;
    &::before {
      border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    }
    &::after {
      bottom: -$tooltip-border-width;
      border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    }
  }

  &.left,
  &.left_start,
  &.left_end {
    height: (2 * $tooltip-arrow-height);
    width: $tooltip-arrow-width;
    &::before {
      border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
    }
    &::after {
      left: -$tooltip-border-width;
      border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
    }
  }

  &.right,
  &.right_start,
  &.right_end {
    height: (2 * $tooltip-arrow-height);
    width: $tooltip-arrow-width;
    &::before {
      border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
    }
    &::after {
      right: -$tooltip-border-width;
      border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
    }
  }
}

.nx-tooltip-full-width {
  .tooltip-inner {
    background-color: green;
    text-align: left;
    max-width: 100%;
    white-space: pre-line;
    &::before,
    &::after {
      display: none;
    }
  }
}
