@import "../theme/theme";

.nx-toolbar {
  width: 100%;
  height: 32px;
  background: $toolbar-background-color;
  padding: 0 20px;
  box-shadow: $toolbar-box-shadow;

  button {
    background: none;
    border: none;
    text-align: left;
    outline: none;
  }
}

.nx-with-sub-menu {
  margin-right: 6px;
}

.nx-toolbar-group {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;

  .nx-has-sub-level {
    position: relative;
    margin-left: -6px;

    > button, > div > button {
      padding-right: 0;
      width: 28px;

      .nx-icon {
        position: relative;
        left: -4px;
      }
    }
  }

  &:not(:first-child) {
    margin-left: 10px;

    > li {
      margin-left: 10px;
    }

    &::before {
      content: '\0020';
      position: absolute;
      left: -1px;
      top: 8px;
      height: 16px;
      width: 2px;
      background-color: $toolbar-separator-color;
    }
  }
}

.nx-toolbar-item {
  float: left;

  .nx-disabled-overlay {
    background: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .nx-icon {
    transition: fill 0.3s;
    float: left;
    margin-top: -9px;
  }

  > button, > div > button {
    padding: 7px 12px 7px 0;
    height: 32px;
    display: block;
    overflow: hidden;
  }

  &:not(.nx-disabled) > button,
  &:not(.nx-disabled) > div > button {
    color: $toolbar-item-color;
    cursor: pointer;
    transition: all 0.3s;
    background-color: transparent;

    &:hover {
      color: $toolbar-item-hover-color;
      background-color: $toolbar-item-hover-bg-color;
    }
  }

  &.nx-disabled > button,  &.nx-disabled > button:hover,
  &.nx-disabled > div > button,  &.nx-disabled > div > button:hover {
    color: $toolbar-item-disabled-color;
    cursor: not-allowed;
  }
}

.nx-toolbar-inverse {
  background: $toolbar-inverse-bg-color;

  .nx-toolbar-group {
    &:not(:first-child) {
      &::before {
        background-color: $toolbar-inverse-separator-color
      }
    }
  }

  .nx-toolbar-item {
    &:not(.nx-disabled) > button,
    &:not(.nx-disabled) > div > button {
      color: $toolbar-inverse-item-color;

      &:hover {
        color: $toolbar-inverse-item-hover-color;
        background-color: $toolbar-inverse-item-hover-bg-color;
      }
    }

    &.nx-disabled > button,  &.nx-disabled > button:hover,
    &.nx-disabled > div > button,  &.nx-disabled > div > button:hover {
      color: $toolbar-inverse-item-disabled-color;
    }
  }
}

.nx-toolbar-sub-menu-item {
  background-color: $color-white;
  display: none;
  position: absolute;
  margin: 9px 0 0;
  padding: 5px 0;
  top: 100%;
  border: $toolbar-sub-menu-border;
  border-radius: 4px;
  box-shadow: $toolbar-sub-menu-box-shadow;
  right: 0;

  .nx-open & {
    display: block;
    z-index: 1000;
  }

  &::before {
    content: ' ';
    position: absolute;
    top: -16px;
    right: 7px;
    height: 8px;
    border: 8px solid;
    border-color: transparent transparent $color-white;
  }

  > li {
    list-style: none;

    > button {
      display: block;
      min-width: 150px;
      cursor: pointer;
      line-height: 1;
      text-decoration: none;
      outline: none;
      color: $toolbar-sub-menu-item-color;
      padding: 14px 15px;

      &:hover {
        background: $toolbar-sub-menu-item-hover-color;
      }
    }
  }
}
