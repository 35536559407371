@import "../theme/theme";
@import "../mixins/token-pill";

.nx-async-select.nx-token-search {
    .nx-select {
        min-height: 40px;

        .nx-select__with-left-icon {
            .nx-select__left-icon {
                margin: 2px 0px;
            }
        }
    }

    .nx-select__value-container {
        padding-left: 24px;
    }

    .nx-async-select__custom-indicators-container {
        .btn {
            border: 0;
        }
    }
}

.nx-async-select__token-option__pill {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;

    .nx-token-pill {
        max-width: 100%;
    }
}

.nx-async-select__token-option {
    display: flex;
    justify-content: space-between;

}

.nx-select__token-search-option__label__subtext {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}

.nx-token-pill-image-wrapper {
    @include nx-token-pill-icon(23px);
}

.nx-async-select-bottom-menu-status-message {
    font-size: 12px;
    color: $color-gray-dark;
    background-color: $color-silverstone;
    display: flex;
    padding: 0 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 26px;
    border-top: 1px solid $color-gray-light;
    align-items: center;
}
