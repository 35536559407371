.nx-progress-flat {
  border-radius: 0;
}


.nx-progress-thin, .nx-progress-thin .progress-bar {
  height: 0.3rem;
}

.nx-progress-ultra-thin, .nx-progress-ultra-thin .progress-bar {
  height: 0.2rem;
}
