.nx-ListTooltip {
  .btn{
    padding: 0;
    &:hover{
      background-color: transparent;
    }
  }
  .nx-tooltip-container {
    width: max-content;
  }

}
