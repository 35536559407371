/// @group Layout
///
/// Modifiers to attach elements to the edges of the viewport.

/// Ensure the element has a specified height.
.nx-fixed-top {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

/// Ensure the element has a specified height.
.nx-fixed-bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

/// Ensure the element has a specified width.
.nx-fixed-left {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

/// Ensure the element has a specified width.
.nx-fixed-right {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
}
