.nx-config-panel-card-container {
  padding: 0;
  margin: 0;
  list-style: none; }
  .nx-config-panel-card-container.nx-grouped .nx-config-panel-card {
    margin-bottom: 0;
    border-top: 0;
    border-radius: 0; }
  .nx-config-panel-card-container.nx-grouped .nx-config-panel-card:first-child {
    border-top: 1px solid #CBCBCB;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .nx-config-panel-card-container.nx-grouped .nx-config-panel-card:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }

.nx-config-panel-action {
  position: absolute;
  top: 10px;
  right: 10px; }
  .nx-config-panel-action .btn {
    width: 30px;
    height: 25px;
    padding: 0 2px;
    color: #5B5B5B;
    background: transparent; }
    .nx-config-panel-action .btn .nx-icon {
      position: static;
      margin: -18px; }
  .nx-config-panel-action ul.action-menu > li > a {
    padding: 8px 13px; }
  .nx-config-panel-action ul.action-menu.align-right::before, .nx-config-panel-action ul.action-menu.align-right::after {
    display: block;
    right: 10px; }
  .nx-config-panel-action .action-menu {
    top: 30px; }

.nx-editing > .nx-config-panel-action .btn {
  color: #FFF; }

.nx-config-panel-card {
  border: 1px solid #CBCBCB;
  color: #232323;
  position: relative;
  transition: background 0.4s ease-in-out;
  margin-bottom: 10px;
  border-radius: 4px; }
  .nx-config-panel-card.nx-editing {
    background-color: #232323; }
  .nx-config-panel .nx-config-panel-card {
    border: none; }
  .nx-config-panel-card:not(.nx-dark) {
    background-color: #FFF; }
  .nx-config-panel-card .nx-config-panel-card-header {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
    border-radius: 4px; }
    .nx-config-panel-card .nx-config-panel-card-header.open {
      transition: opacity 0.1s ease-in-out; }
    .nx-config-panel-card .nx-config-panel-card-header:hover {
      background-color: #FFF2E6; }
  .nx-config-panel-card .nx-config-panel-card-header-content {
    padding: 20px 50px 20px 15px;
    display: inline-flex;
    align-items: center;
    cursor: pointer; }
  .nx-config-panel-card.nx-transparent {
    border-color: transparent;
    background-color: transparent; }
    .nx-config-panel-card.nx-transparent .nx-config-panel-action {
      display: none; }
  .nx-config-panel-card.nx-size-medium .nx-config-panel-card-header-content {
    padding: 10px;
    padding-right: 50px;
    min-height: 45px; }
  .nx-config-panel-card.nx-size-small .nx-config-panel-card-header-content {
    padding: 5px 50px 5px 20px;
    min-height: 35px; }
  .nx-config-panel-card.nx-size-small .nx-config-panel-action {
    top: 5px; }

.nx-config-panel-card-body {
  padding: 10px 15px 15px; }
  .nx-config-panel-card-body .nx-config-panel-card-body-header {
    padding-right: 48px;
    color: #FFF;
    margin-bottom: 10px;
    cursor: pointer; }
  .nx-config-panel-card-body p:last-child, .nx-config-panel-card-body button:last-child, .nx-config-panel-card-body .form-group:last-child {
    margin-bottom: 0; }

.nx-config-panel-card-footer-actions {
  text-align: right; }
  .nx-config-panel-card-footer-actions button {
    margin-left: 10px; }

.nx-config-panel-card-flag {
  position: absolute;
  top: 0;
  left: -2px;
  opacity: 1;
  transition: opacity 250ms ease-in-out; }

.nx-editing .nx-config-panel-card-flag {
  opacity: 0; }
