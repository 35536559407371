/**
 * Snippets from react-step-wizard
 * Credit goes to https://github.com/jcmcneal/react-step-wizard
*/
.animated {
  animation-duration: 0.6s;
  animation-fill-mode: backwards; }

@keyframes enterFromRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1; } }

.enterFromRight {
  animation-name: enterFromRight; }

@keyframes enterFromLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1; } }

.enterFromLeft {
  animation-name: enterFromLeft; }

@keyframes exitToRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0); } }

.exitToRight {
  animation-name: exitToRight; }

@keyframes exitToLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); } }

.exitToLeft {
  animation-name: exitToLeft; }

.nx-wizard .nx-step-wrapper {
  position: relative; }

.nx-wizard .nx-step {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.nx-wizard .active {
  opacity: 1;
  pointer-events: inherit;
  position: relative;
  z-index: 1; }
