/// Creates a box shadow according to UX specifications
///
/// @param {Number} $index - The category of box shadow. If not specified, a default is used.
@mixin nx-box-shadow($index: 1) {
  @if $index == 2 {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.11);
  } @else if $index == 3 {
    box-shadow: 0 21px 21px 0 rgba(0, 0, 0, 0.12);
  } @else if $index == 4 {
    box-shadow: 2px 3px 8px 0 rgba(0, 0, 0, 0.12);
  } @else {
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
  }
}

@mixin svg-icon-size($size: 48px) {
  width: $size;
  height: $size;
  background-size: $size;
}
