@mixin tooltip-color($tooltip-background-color, $tooltip-text-color, $tooltip-border-color) {
  $tooltip-bg: $tooltip-background-color;
  $tooltip-arrow-color: $tooltip-background-color;
  .nx-tooltip-arrow {
    &.top, &.top_start, &.top_end {
      &::before {
        border-top-color: $tooltip-border-color;
      }
      &::after {
        border-top-color: $tooltip-background-color;
      }
    }

    &.bottom, &.bottom_start, &.bottom_end {
      &::before {
        border-bottom-color: $tooltip-border-color;
      }
      &::after {
        border-bottom-color: $tooltip-background-color;
      }
    }

    &.left, &.left_start, &.left_end {
      &::before {
        border-left-color: $tooltip-border-color;
      }
      &::after {
        border-left-color: $tooltip-background-color;
      }
    }

    &.right, &.right_start, &.right_end {
      &::before {
        border-right-color: $tooltip-border-color;
      }
      &::after {
        border-right-color: $tooltip-background-color;
      }
    }

  }

  &.tooltip-top, &.tooltip-top_start, &.tooltip-top_end {
    .tooltip-inner{
      border-color: $tooltip-border-color;
    }
  }

  &.tooltip-bottom, &.tooltip-bottom_start, &.tooltip-bottom_end {
    .tooltip-inner {
      border-color: $tooltip-border-color;
      &::before {
        border-bottom-color: $tooltip-border-color;
      }

      &::after {
        border-bottom-color: $tooltip-background-color;
      }
    }
  }


  &.tooltip-left, &.tooltip-left_start, &.tooltip-left_end {
    .tooltip-inner {
      border-color: $tooltip-border-color;
      &::before {
        border-left-color: $tooltip-border-color;
      }
      &::after {
        border-left-color: $tooltip-background-color;
      }
    }
  }

  &.tooltip-right, &.tooltip-right_start, &.tooltip-right_end {
    .tooltip-inner {
      border-color: $tooltip-border-color;
      &::before {
        border-left-color: $tooltip-border-color;
      }
      &::after {
        border-left-color: $tooltip-background-color;
      }
    }
  }

  .tooltip-inner {
    background-color: $tooltip-background-color;
    color: $tooltip-text-color;

    a,
    a:visited,
    .tooltip-link {
      color: $tooltip-text-color;
      text-decoration: underline;
    }
    .nx-btn-close {
      .nx-icon {
        color: $tooltip-text-color;
      }
    }
  }

  &.nx-tooltip-full-width {
    &.tooltip-bottom {
      .nx-tooltip-arrow {
        &::before {
          border-bottom-color: $tooltip-border-color;
        }
        &::after {
          border-bottom-color: $tooltip-background-color;
        }
      }
    }

    &.tooltip-top {
      .nx-tooltip-arrow {
        &::before {
          border-top-color: $tooltip-border-color;
        }
        &::after {
          border-top-color: $tooltip-background-color;
        }
      }
    }
  }
}
