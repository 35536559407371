@import "./datetimepicker/_date-time";
@import "./datetimepicker/_calendar";
@import "./datetimepicker/_time";

.nx-datetimepicker {
  position: relative;
  border-radius: 4px;

  button {
    outline: 0;
  }

  label {
    margin-bottom: 0;
  }

  > input {
    display: block;
    color: $input-color;
    background-color: $input-bg;
    border: 1px solid $input-border-color;
    padding: 7px 48px 7px $input-padding-x;
    font-size: $font-size-base;
    line-height: 1;
    border-radius: $border-radius-base;
    width: 100%;

    &:focus {
      border-color: $input-focus-border-color;
      outline: 0;
    }

    &[disabled] {
      cursor: $cursor-disabled;
      background-color: $input-disabled-bg;
    }
  }

  .nx-btn-clear {
    position: absolute;
    top: 0;
    width: 24px;
    right: 28px;
    z-index: 1;
  }

  .nx-btn-toggle {
    position: absolute;
    top: 0;
    width: 24px;
    right: 4px;
    z-index: 2;
  }

  .nx-btn-icon {
    outline: 0;
    height: 32px;
    overflow: hidden;
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }
}

/* rtl:ignore-start */
.nx-datetimepicker {
  .nx-btn-icon {
    svg.nx-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px;
    }
  }
}
/* rtl:ignore-end */

.nx-has-error {
  .nx-datetimepicker {
    input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:focus,
      &.focus {
        border: 1px solid $error-control-border-color;

        + .error-message {
          background-color: $error-message-focus-bg-color;
          color: $error-message-focus-text-color;
        }
      }
    }
  }
}
