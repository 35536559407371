@mixin svg-dropdown-caret {
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCI+PHBhdGggZmlsbD0iIzViNWI1YiIgZD0iTTIzLjI2NyAyNy42NzJsLTUuOTI4LTUuNzZjLS43MjYtLjcwNi0uMjEyLTEuOTEyLjgxNC0xLjkxMmgxMS42OTJjMS4wMTggMCAxLjUzNiAxLjE5LjgyNiAxLjlsLTUuNzY0IDUuNzZhMS4xNzUgMS4xNzUgMCAwIDEtMS42NC4wMTJ6Ii8+PC9zdmc+) no-repeat;
}

@mixin svg-drop-down {
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmZ9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMTguMyAyMGExLjEgMS4xIDAgMCAwLS44IDEuOWw1LjggNS44YTEuMSAxLjEgMCAwIDAgMS41IDBsNS44LTUuOGExLjEgMS4xIDAgMCAwLS44LTEuOUgxOC4zeiIvPjwvc3ZnPg==) no-repeat;
}

@mixin svg-dropdown-clear {
  background-color: transparent;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtzdHJva2U6IzMyMzIzMn08L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIHN0cm9rZS13aWR0aD0iOCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBkPSJNOCA4bDMyIDMybTAtMzJMOCA0MCIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
}