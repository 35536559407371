$tile-half-gutter: round($tile-gutter / 2);
$tile-width-and-gutter: calc(33% - #{$tile-gutter});

.nx-tile-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;

  // This ensures there is consistent spacing on the left and right edges of the container.
  // The flex layout
  padding-left: $tile-half-gutter;
}

.nx-tile {
  margin-left: $tile-half-gutter;
  margin-right: $tile-half-gutter;
  margin-bottom: $tile-gutter;
  padding: 10px 15px;
  background-color: $tile-bg-color;
  border: 1px solid $tile-border-color;
  border-radius: $tile-border-radius;

  flex: 0 0 $tile-width-and-gutter;
  max-width: $tile-width-and-gutter;
}

.nx-tile-interactive {
  transition: transform 0.2s ease;

  &:hover {
    background-color: $color-peach-sorbet;
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 0 10px $color-gray-light;
  }
}
