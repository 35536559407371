$nx-date-time-calendar-date-size: 32px;

@mixin calendar-date-disc($bg-color) {
  content: ' ';
  position: absolute;
  left: 0;
  top: -1px;
  width: $nx-date-time-calendar-date-size;
  height: $nx-date-time-calendar-date-size;
  z-index: -1;
  display: block;
  border-radius: 50%;
  background-color: $bg-color;
}

.nx-date-time-calendar-palette {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 28px;
  color: $color-gray-medium;

  > button,
  > span {
    flex: 1 1 auto;
  }

  .nx-btn-icon {
    position: relative;
    transition: color ease 0.3s;
    color: $color-taupe-gray;
    width: 28px;
    height: 28px;

    svg.nx-icon {
      fill: currentColor;
      pointer-events: none;
    }

    &[data-action="prev-year"] {
      margin-left: 10px;
    }

    &:hover {
      color: $color-nintex-orange;
    }
  }
}

.nx-date-time-current-date {
  display: inline-block;
  font-size: $font-size-lg;
  width: 100%;
  text-align: center;
  line-height: 1;
}

.nx-date-time-current-month {
  max-width: 14ex;
  user-select: none;
}

.nx-date-time-current-year {
  max-width: 6ex;
  user-select: none;
}

.nx-date-time-calendar {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  margin-left: 2px;

  th {
    color: $color-gray-dark;
    font-size: 12px;
    font-weight: normal;
    cursor: default;
    padding: 4px 0;
    text-align: center;
  }

  td {
    width: $nx-date-time-calendar-date-size;
    height: $nx-date-time-calendar-date-size;
    color: $color-gray-dark;
    cursor: pointer;
    position: relative;
    text-align: center;

    &.nx-date-time-calendar-selected-date {
      color: $color-white;
    }
  }
}

.nx-date-time-calendar-month {
  &:not(.nx-date-time-calendar-selected-date):hover {
    &::before {
      @include calendar-date-disc($color-peach-sorbet);
    }
  }
}

.nx-date-time-calendar-selected-date {
  font-weight: bold;

  &::before {
    @include calendar-date-disc($color-brand-orange);
  }
}

.nx-date-time-calendar-current-date {
  font-weight: bold;
}
