@mixin nx-token-pill-icon($w, $h: $w) {
  display: flex;
  position: absolute;
  width: $w;
  height: $h;
  top: 50%;
  left: 50%;
  margin-left: $w * -0.5;
  margin-top: $h * -0.5;
  align-items: center;
}

@mixin nx-token-pill-style($colour) {
  border-color: $colour;

  .nx-token-pill-icon-wrapper {
    background-color: $colour;
    fill: $color-white;
  }
  .nx-token-pill-parent-arrow {
    &::before {
      background-color: $colour;
    }
  }
  .nx-token-pill-child-arrow {
    &::before {
      background-color: $colour;
    }
  }
}