.nx-config-panel-navigator {
  display: block;
  width: 100%;
  border-radius: 4px;
  background-color: #FFF;
  border: 1px solid #E06E18;
  text-align: left;
  padding: 12px 0 12px 12px; }
  .nx-config-panel-navigator:hover {
    background-color: #FFF2E6;
    border-color: #E06E18; }
  .nx-config-panel-navigator:focus {
    outline: none; }
  .nx-config-panel-navigator div {
    float: left; }
    .nx-config-panel-navigator div h4 {
      margin-bottom: 6px;
      color: #E06E18; }
    .nx-config-panel-navigator div p {
      margin-bottom: 0;
      color: #5B5B5B; }
  .nx-config-panel-navigator .nx-config-panel-navigator-icon {
    float: right;
    color: #5B5B5B;
    margin: 5px -5px 0 0; }

.nx-config-panel-navigator-back {
  margin-bottom: 10px;
  padding-left: 0.525rem; }
