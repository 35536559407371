@import "../theme/theme";

$cpc-spacing: $padding-small;
$cpc-spacing-m: $padding-regular;
$cpc-spacing-l: $padding-small * 2;
$cpc-spacing-menu: 50px;

.nx-config-panel-card-container {
  padding: 0;
  margin: 0;
  list-style: none;

  &.nx-grouped {
    .nx-config-panel-card {
      margin-bottom: 0;
      border-top: 0;
      border-radius: 0;
    }
    .nx-config-panel-card:first-child {
      border-top: 1px solid $config-panel-card-border-color;
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
    }

    .nx-config-panel-card:last-child {
      border-bottom-left-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;
    }
  }
}

.nx-config-panel-action {
  position: absolute;
  top: $cpc-spacing;
  right: $cpc-spacing;

  .btn {
    width: 30px;
    height: 25px;
    padding: 0 2px;
    color: $color-taupe-gray;
    background: transparent;

    .nx-icon {
      // IE11 hack to center icon within button
      position: static;
      margin: -18px;
    }
  }

  ul.action-menu > li > a {
    padding: 8px 13px;
  }

  ul.action-menu.align-right::before, ul.action-menu.align-right::after {
    display: block;
    right: 10px;
  }

  .action-menu {
    top: 30px;
  }
}

.nx-editing > .nx-config-panel-action .btn {
  color: $config-panel-editing-action-btn-color;
}

.nx-config-panel-card {
  border: 1px solid $config-panel-card-border-color;
  color: $config-panel-card-color;
  position: relative;
  transition: background 0.4s ease-in-out;
  margin-bottom: $cpc-spacing;
  border-radius: $border-radius-base;
  
  &.nx-editing {
    background-color: $config-panel-card-editing-bg-color;
    color: $config-panel-card-editing-color;

    h1, h2, h3, h4, h5, h6, label, p {
      color: $config-panel-card-editing-headers-color;
    }
  }

  .nx-config-panel & {
    border: none;
  }

  &:not(.nx-dark) {
    background-color: $color-white;
  }

  .nx-config-panel-card-header {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
    border-radius: $border-radius-base;

    &.open {
      transition: opacity 0.1s ease-in-out;
    }
    &:hover {
      background-color: $config-panel-card-header-bg-color;
    }
  }

  .nx-config-panel-card-header-content {
    padding: $cpc-spacing-l $cpc-spacing-menu $cpc-spacing-l $cpc-spacing-m;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  &.nx-transparent {
    border-color: transparent;
    background-color: transparent;

    .nx-config-panel-action {
      display: none;
    }
  }
  
  &.nx-size-medium {
    .nx-config-panel-card-header-content {
      padding: $cpc-spacing;
      padding-right: $cpc-spacing-menu;
      min-height: 45px;
    }
  }
  
  &.nx-size-small {
    .nx-config-panel-card-header-content {
      padding: $cpc-spacing/2 $cpc-spacing-menu $cpc-spacing/2 $cpc-spacing-l;
      min-height: 35px;
    }

    .nx-config-panel-action {
      top: $cpc-spacing/2;
    }
  }
}

.nx-config-panel-card-body {
  padding: $cpc-spacing $cpc-spacing-m $cpc-spacing-m;

  .nx-config-panel-card-body-header {
    padding-right: 48px;
    color: $color-white;
    margin-bottom: $cpc-spacing;
    cursor: pointer;
  }

  p, button, .form-group {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.nx-config-panel-card-footer-actions {
  text-align: right;

  button {
    margin-left: $cpc-spacing;
  }
}

.nx-config-panel-card-flag {
  position: absolute;
  top: 0;
  left: -2px;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.nx-editing {
  .nx-config-panel-card-flag {
    opacity: 0;
  }
}