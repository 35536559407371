$date-time-view-width: 244px;

.nx-date-time {
  padding: 2px 8px 4px;
  display: block;
  border-radius: $border-radius-base;
  border: 1px solid $color-keyline;
  background: $color-white;
  z-index: 99999;
  position: absolute;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
  width: $date-time-view-width;

  &.nx-from-bottom {
    margin-top: 8px;
    right: 0;

    .nx-pointer {
      position: absolute;
      top: -16px;
      height: 8px;
      border: 8px solid;
      border-color: transparent transparent $color-white;
      right: 7px;

      &::before {
        content: '\0020';
        position: absolute;
        top: -10px;
        right: -9px;
        z-index: -1;
        height: 9px;
        border: 9px solid;
        border-color: transparent transparent $color-keyline;
      }
    }
  }

  &.nx-from-top {
    margin-bottom: 8px;
    right: 0;

    .nx-pointer {
      position: absolute;
      bottom: -16px;
      height: 8px;
      border: 8px solid;
      border-color: $color-white transparent transparent;
      right: 7px;

      &::before {
        content: '\0020';
        position: absolute;
        top: -8px;
        right: -9px;
        z-index: -1;
        height: 9px;
        border: 9px solid;
        border-color: $color-keyline transparent transparent;
      }
    }
  }

  .btn-primary {
    width: 100%;
    margin: 4px 0;
  }
}

.nx-date-time-switch {
  width: calc(100% + 16px);
  display: inline-flex;
  margin: 0 -8px;
  background-color: $color-white;
  border-bottom: 1px solid #cbcbcb;

  > button {
    color: $color-gray-dark;
    text-align: center;
    font-size: 14px;
    padding: 4px 0;
    border: 0 none;
    outline: 0 none;
    margin: 0;

    &:first-child {
      border-top-right-radius: 4px;
      flex: 1;
      border-right: 1px solid $color-keyline;
      margin-right: 2px;
    }

    &:last-child {
      flex: 0 1 40%;
      border-top-left-radius: 4px;
      border-left: 1px solid $color-keyline;
    }

    &:not(.nx-is-active) {
      border-top: 1px solid $color-keyline;
      background-color: $color-gray-lighter;
      cursor: pointer;
    }

    &.nx-is-active {
      color: $color-brand-primary;
      background-color: $color-white;
      border-top: 2px solid $color-brand-primary;
      border-bottom: 1px solid $color-white;
      margin-bottom: -1px;
    }
  }
}

.nx-date-time-view {
  display: none;
  width: 100%;
  margin-top: 4px;
  min-height: 212px;

  &.nx-is-active {
    display: block;
  }
}

.nx-datetimepicker-inline {
  .nx-date-time {
    position: relative;
    right: auto;
    margin-left: calc(100% - #{$date-time-view-width});
  }
}