.nx-text-lg {
  font-size: $font-size-lg;
}

.nx-text-sm {
  font-size: $font-size-sm;
}

.nx-description {
  color: $color-gray-light;
  font-style: italic;
}
